import React from 'react';
import {MENU, rolesAdmin} from "utils/config";
import {NavLink, useRouteMatch, useLocation} from "react-router-dom";
import CalendarContainer from "./CalendarContainer";
import {in_array} from "utils/func";

const SideMenu = (props) => {

	const {setScrollClassName = null, user} = props;

	// let location = useLocation();
	// console.log(user);

	const getSubmenu = (menu, i) => {
		return <ul className="menu-sub _z pup" key={"sm-sub-" + i}>
			{menu.map((item, j) => {

				if (item.url === '/user' && user.userRoles) {
					item.menu = [];
					for (const role in user.userRoles) {
						if (role !== 'admin' || in_array(user.role, rolesAdmin))
							item.menu.push({
								name: user.userRoles[role],
								url: '/user/role/' + role,
								roles: []
							});
					}
				}


				if (!item.roles.length || in_array(user.role, item.roles))
					return (
						<li key={"sm-sub-itm-" + j}>
							<NavLink
								to={item.url}
								exact
							>
								{item.ico && <i className={"fa " + item.ico}></i>}
								<span>
								{item.name}
								</span>
							</NavLink>
							{(item.menu && item.menu.length) ? getSubmenu(item.menu, j) : null}
						</li>
					);
			})}
		</ul>;
	};

	const menuItem = (item, i) => {


		// console.log(item);
		if (typeof item == 'string' && item === 'separator')
			return (
				<li key={"sm-" + i}>
					<hr />
				</li>
			);
		else {
			let onMouseEnterFunc = null;
			let onMouseLeaveFunc = null;
			let submenu = null;
			if (item.menu) {
				onMouseEnterFunc = () => {
					if (typeof setScrollClassName === 'function')
						setScrollClassName('menu-side-scroll')
				};
				onMouseLeaveFunc = () => {
					if (typeof setScrollClassName === 'function')
						setScrollClassName('')
				};
				if (item.menu && item.menu.length)
					submenu = getSubmenu(item.menu);
			}
			let navExact = false;
			if (item.menu == undefined)
				navExact = true;
			return (
				<li
					key={"sm-" + i}
					className=""
					onMouseEnter={onMouseEnterFunc}
					onMouseLeave={onMouseLeaveFunc}
					title={item.name}
				>
					<NavLink
						to={item.url}
						key={"sma-" + i}
						exact={navExact}
					>
						{item.ico && <i className={"fa " + item.ico}></i>}
						<span>
							{item.name}
						</span>
					</NavLink>
					{submenu}
				</li>
			);
		}
	};

	return (
		<nav className="menu">
			<ul className="_z">
				{/*<CalendarContainer />*/}
				{MENU.map((item,i) => {
					if (!item.roles.length || in_array(user.role, item.roles))
						if (!item.divisions || in_array(parseInt(user.divId), item.divisions))
							return menuItem(item, i);
				})}
			</ul>
		</nav>
	)
};
export default SideMenu;