import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import ReportContainer from "containers/report/ReportContainer";

const ReportRouter = props => {

	return(
		<Switch>
			<Route
				path="/report/:reportType?"
				exact={true}
			>
				<ReportContainer {...props} />
			</Route>

			<Route component={Page404} />
		</Switch>
	)

};

export default ReportRouter;