import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { connect } from 'react-redux';
import * as Containers from "containers";
import Storage from "utils/Storage";
import {bindActionCreators} from "redux";
import {SESSION_USER, userAuth, USER_LOGIN} from "actions/UserActions";
import {setSettings, sideBarToggle, setSocket, setSettingsOne} from "actions/GUIActions";
import {APP_TITLE, SOCKET_URL, SESSION_SETTINGS_NAME} from "utils/config";
import {toastr} from "react-redux-toastr";
import {io} from "socket.io-client";
import * as Routers from "routers/index";
import {Win} from "components/_common";



class Routing extends Component {

	constructor(props) {
		super(props);
		const sessionUser = Storage.get(SESSION_USER, null, true);

		if (sessionUser)
			this.props.actions.userAuth(USER_LOGIN, sessionUser);

		const sessionSettings = Storage.get(SESSION_SETTINGS_NAME, null, true);

		if (sessionSettings)
			this.props.actions.setSettings(sessionSettings);
		// this.setSettings();
	};
	//
	// setSettings() {
	//
	// }


	connectSocket(user) {

		const {actions} = this.props;


		const RECONNECTION_ATTEMPTS = 60;
		let attempts = 0;

		if (SOCKET_URL) {
			// console.log('connectSocket inside routing');

			const socket = io(SOCKET_URL, {
				reconnectionDelay: 10000,
				reconnectionDelayMax: 50000,
				reconnectionAttempts: RECONNECTION_ATTEMPTS,
				query: {
					userId: user ? user.id : 0,
					// userId: user ? (user.id > 5 ? 555: 1) : 0,
					url: window.location.pathname,
					browserWidth: window.outerWidth,
					browserHeight: window.outerHeight,
				},
				// secure: true,
				port: 3080
			});
			// console.log(socket.connected);
			socket.on('connect', () => {
				actions.setSocket(socket);
				console.log('Подключено к удаленному серверу', SOCKET_URL);
				// toastr.success('Подключено к удаленному серверу ' + SOCKET_URL);

			});
			socket.on("connect_error", () => {
				console.error('Невозможно подключиться к удаленному серверу', SOCKET_URL);
				attempts++;

				// if (user && user.role == 'admin' && attempts >= RECONNECTION_ATTEMPTS) {
				// 	toastr.error(`Невозможно подключиться к удаленному серверу ${SOCKET_URL}. Система работает без обновлений "на лету"`, '', {timeOut: 0});

					socket.disconnect();
				// }
			});

			socket.on('serverTrigger', res => {
				console.log('%cserverTrigger', 'background: #000; color: #fff');
				console.dir(res);
				if (res.action === 'showMessage' && res.msg && res.msg !== '') {
					switch (res.type) {
						case "error":
							toastr.error(res.msg);
							break;
						default:
							toastr.info(res.msg);
							break;
					}
				}
			});
			//обновление настроек
			socket.on('updateSettings', data => {
				console.log('%cSOCKET updateSettings', 'background: #333; color: #fff');
				console.dir(data);
				for (const key in data) {
					actions.setSettingsOne(key, data[key]);
				}

			});
		}
		else
			console.log('connectSocket inside routing DISABLED');
	}
	componentWillReceiveProps(nextProps) {
		// console.log('componentWillReceiveProps');

		if (this.props.user === null && nextProps.user !== null && this.props.GUI.socket === null) {

			this.connectSocket(nextProps.user);
		}

	}

	componentDidMount() {
		document.title = APP_TITLE;

		// this.unlisten = this.props.history.listen((location, action) => {
		// 	console.log("on route change");
		// });

		// const {actions} = this.props;
		// actions.sideBarToggle(false);
		// console.log(actions);
		// const sessionUser = Storage.session_get(SESSION_USER, null, true);
		// console.log(sessionUser);
		//
		// if (sessionUser)
		// 	this.props.actions.userAuth(sessionUser);
	}


	render() {
		const {user, actions, GUI} = this.props;

		// const loaderBar = <LoadingBar className={"LoadingBar"}/>;

		let content = null;
		// console.log(user);
		// const sessionUser = Storage.session_get('user', null, true);
		// console.log(sessionUser);

		if (!user) {

			content = (
				<Router>
					<Routers.LoginRouter />
				</Router>
			);
		}
		//проверка на сокет
		else if (!SOCKET_URL || GUI.socket) {
			content = (
				<Router>
					<Containers.ContentContainer/>
				</Router>
			);
		}
		else
			content = (
				<div className="win-wide">
					<Win
						header={"Подключение"}
						winClass={"login-win anim-win"}
					>
						Нет подключения к удаленному серверу по адресу {SOCKET_URL}
					</Win>
				</div>
			);


		return (
			<>{content}</>
		);
	}
}
// export default Routing;
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		userAuth, sideBarToggle, setSocket, setSettings, setSettingsOne
	}, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Routing);
