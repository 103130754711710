import React, {useState} from 'react';
// import {rolesManager} from "utils/config";
// import {in_array} from "utils/func";
import {FormItem, SmartSelect} from "components/_common";
import OrderEditManager from "./OrderEditManager";
import OrderPhone from "./OrderPhone";
import {NavLink} from "react-router-dom";
import {SELECT_SHOW_SEARCH_MIN_OPTION, orderPrintTime, orderFullTime} from "./utils";
// import date from "php-date";
import OrderTimes from "./OrderTimes";
import cn from "classnames";
import {format_phone} from "utils/func";


const OrderEdit = props => {
	const {
		user, settings,
		isEdit, isNew,
		data, setData,
		formErrors, setFormErrors,
		// managers,
		clients,
		statusList,
		orderPaymentList,
		// time_delivery_plus,

		isManager, isClient, isCourier
	} = props;

	// console.clear();
	let address = (data && data.address) ? data.address : {};

	for (const [key, value] of Object.entries(address)) {
		// console.log(`${key}: >${value}<`);
		if (typeof value == 'string')
			address[key] = value.trim();
	}
	let {
		city = '', street = '',
		house = '', podezd = '',
		domofon = '', floor = '', flat = ''
	} = address;//(data && data.address) ? data.address : {};


	let canEditAddressField = isEdit && parseInt(data.status) < 32;
	// console.log(canEditAddressField, parseInt(data.status), city);

	const [isAddressChanged, setAddressChanged] = useState(false);


	let paymentOptions = [];
	let paymentSelected = '';
	let showPaymentPrice = true;

	if (orderPaymentList) {

		orderPaymentList.map((pay) => {

			pay.id = parseInt(pay.id);
			// console.log(id, parseInt(data.payment) === id);
			let selected = false;

			if (parseInt(data.payment) === pay.id) {
				selected = true;
				paymentSelected = pay.name;
				showPaymentPrice = pay.showPrice;
				// console.log('selected', id);
			}

			paymentOptions.push({
				value: pay.id,
				name: pay.name,
				selected: selected
			});
		});
	}
	// console.dir(paymentOptions);
	let cityOptions = [];
	if (settings && settings.cities) {
		// let _cities = settings.cities;
		// _cities = _cities.slice(0, 2);
		// _cities.push('Зеленоград');
		// console.log(_cities);
		settings.cities.map(c => {
			c = c.trim();
			// console.log(`>${city}<`, `>${c}<`, city == c);
			cityOptions.push({
				value: c,
				name: c,
				selected: city == c
			});
		});
	}
	const cityOptionsHash = JSON.stringify(cityOptions);

	let isTimeEdit = isEdit;
	let timeCookedEdit = isEdit;
	if (isNew)
		timeCookedEdit = true;
	if (isManager) {
		timeCookedEdit = true;
		if (!isNew)
			isTimeEdit = false;
	}
	if (isClient && !isNew)
		timeCookedEdit = false;
	// console.log(isEdit, isTimeEdit);

	let info4courier = [];

	if (isCourier && clients[data.client_id]) {
		let client = clients[data.client_id];
		// console.dir(client);
		info4courier.push(
			<div className="row" key={'cl-nt'}>
				<div className="col-2">
					<FormItem
						key={'clientname'}
						edit={false}
						name={"clientname"}
						label={"Клиент"}
						defVal={client.name}
						ico={"user-circle-o"}

					/>
				</div>
				<div className="col-2">
					<FormItem
						key={'client.phone'}
						edit={false}
						name={"client.phone"}
						label={"Телефон клиента"}
						defVal={format_phone(client.phone)}
						ico={"phone"}

					/>
				</div>
			</div>
		);
		info4courier.push(
			<FormItem
				key={'client.address'}
				edit={false}
				name={"client.address"}
				label={"Адрес забора заказа"}
				defVal={client.address}
				ico={"map-marker"}

			/>
		);
	}
	let statusSelect = null;
	if (user.role === 'admin') {
		// console.dir(statusList);
		let statusSelectOptions = {};
		for (let sid in statusList) {
			statusSelectOptions[sid + ''] = {value: statusList[sid].name}
		}

		statusSelect = <FormItem
			edit={true}
			name={"status"}
			label={"Изменить статус заказа (только админ)"}
			defVal={data.status ? parseInt(data.status) : 0}
			fieldType={"select"}
			options={statusSelectOptions}
			changeAction={(e, val) => {
				setData(prev => ({
					...prev,
					status: val,
					changed: true
				}));
			}}
		/>
	}

	// console.clear();
	// console.dir(data);


	return (
		<>
			{/*<FormItem*/}
			{/*	edit={isEdit}*/}
			{/*	name={"phone"}*/}
			{/*	label={"Телефон конечного клиента"}*/}
			{/*	ico={"mobile"}*/}
			{/*	isRequire={true}*/}
			{/*	maxLength={100}*/}
			{/*	defVal={data.phone ? data.phone : ''}*/}
			{/*	blurAction={(e, val) => {*/}
			{/*		setData(prev => ({*/}
			{/*			...prev,*/}
			{/*			phone: val,*/}
			{/*			changed: true*/}
			{/*		}));*/}
			{/*	}}*/}
			{/*	errorMsg={ (formErrors && formErrors.phone) ? formErrors.phone : null }*/}
			{/*	setErrorMsg={setFormErrors}*/}

			{/*	mask={isEdit ? "+7 (999) 999-99-99" : null}*/}
			{/*	// mask={"+7 (999) 999-99-99"}*/}
			{/*	descr={"79175795515"}*/}

			{/*	fieldType={*/}
			{/*		isCourier*/}
			{/*			? <div className="flx -alc" style={{height: '2.25rem'}}>*/}
			{/*				<a href={"tel:+" + data.phone}>*/}
			{/*					{format_phone(data.phone)}*/}
			{/*				</a>*/}
			{/*			</div>*/}
			{/*			: 'text'*/}
			{/*	}*/}

			{/*/>*/}
			<OrderPhone {...props} setAddressChanged={setAddressChanged}/>

			{/*  АДРЕС */}
			<div className="row">
				<div className="col-2">
					<FormItem
						edit={canEditAddressField}
						name={"city"}
						label={"Населенный пункт"}
						ico={"map-signs"}
						isRequire={true}
						maxLength={100}
						defVal={city}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									city: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.city) ? formErrors.city : null}
						setErrorMsg={setFormErrors}

						descr={
							(canEditAddressField && isManager)
								? <NavLink to={"/settings/cities"}>добавить</NavLink>
								: null
						}
						fieldType1={"text"}
						fieldType={
							isEdit ? <SmartSelect
									edit={canEditAddressField}
									name={"city"}
									redraw={isAddressChanged}

									options={cityOptions}
									// optionsHash={cityOptionsHash}
									multi={false}
									arrow={true}
									withSearch={cityOptions.length > SELECT_SHOW_SEARCH_MIN_OPTION}
									onSelect={res => {
										setData(prev => ({
											...prev,
											changed: true,
											address: {
												...prev.address,
												city: res.value.trim()
											},
										}));
									}}
								/>
								: 'text'
						}
					/>
				</div>
				<div className="col-2">
					<FormItem
						edit={canEditAddressField}
						name={"street"}
						label={"Улица"}
						ico={"road"}
						// isRequire={true}
						maxLength={100}
						defVal={street}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									street: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.street) ? formErrors.street : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-5">
					<FormItem
						edit={canEditAddressField}
						name={"house"}
						label={"Дом (корпус)"}
						ico={"home"}
						isRequire={true}
						maxLength={100}
						defVal={house}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									house: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.house) ? formErrors.house : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
				<div className="col-5">
					<FormItem
						edit={isEdit}
						name={"podezd"}
						label={"Подъезд"}
						ico={"building-o"}
						isRequire={true}
						maxLength={100}
						defVal={podezd}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									podezd: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.podezd) ? formErrors.podezd : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
				<div className="col-5">
					<FormItem
						edit={isEdit}
						name={"domofon"}
						label={"Домофон"}
						ico={"fax"}
						isRequire={true}
						maxLength={100}
						defVal={domofon}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									domofon: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.domofon) ? formErrors.domofon : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
				<div className="col-5">
					<FormItem
						edit={isEdit}
						name={"floor"}
						label={"Этаж"}
						ico={"sort"}
						isRequire={true}
						maxLength={100}
						defVal={floor}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									floor: val.trim()
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.floor) ? formErrors.floor : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
				<div className="col-5">
					<FormItem
						edit={isEdit}
						name={"flat"}
						label={"Квартира"}
						ico={"bath"}
						isRequire={true}
						maxLength={100}
						defVal={flat}
						blurAction={(e, val) => {
							setData(prev => ({
								...prev,
								address: {
									...prev.address,
									flat: val
								},
								changed: true
							}));
						}}
						errorMsg={(formErrors && formErrors.flat) ? formErrors.flat : null}
						setErrorMsg={setFormErrors}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-2 fi-mb">
					{
						isEdit ? <SmartSelect
								edit={isEdit}
								name={"payment"}
								// placeholder={"Все"}
								label={"Способ оплаты"}
								options={paymentOptions}
								optionsHash={paymentOptions.length}
								multi={false}
								arrow={true}
								onSelect={res => {
									setData(prev => ({
										...prev,
										changed: true,
										payment: parseInt(res.value)
									}));
								}}
							/>
							: <FormItem
								edit={isEdit}
								name={"payment"}
								label={"Способ оплаты"}
								defVal={paymentSelected}
							/>
					}
				</div>
				<div className="col-2">
					{
						showPaymentPrice
							? <FormItem
								edit={isEdit}
								name={"price_order"}
								label={"Сумма к оплате"}
								ico={"money"}
								isRequire={true}
								maxLength={100}
								numericRound={2}
								defVal={data ? data.price_order : 0}
								blurAction={(e, val) => {
									setData(prev => ({
										...prev,
										price_order: val,
										changed: true
									}));
								}}
								errorMsg={(formErrors && formErrors.price_order) ? formErrors.price_order : null}
								setErrorMsg={setFormErrors}
							/>
							: null
					}
				</div>
			</div>

			<div className={cn({"os-man-info fi-mb": (isCourier && data && data.commentc && data.commentc != '')})}>
				<FormItem
					edit={isEdit}
					fieldType={"txt"}
					name={"commentc"}
					label={"Комментарий к заказу" + (isCourier ? ' от контрагента' : '')}
					ico={"comment-o"}

					maxLength={500}
					defVal={data ? data.commentc : ''}
					blurAction={(e, val) => {
						setData(prev => ({
							...prev,
							commentc: val,
							changed: true
						}));
					}}
					errorMsg={(formErrors && formErrors.commentc) ? formErrors.commentc : null}
					setErrorMsg={setFormErrors}
				/>
			</div>
			{
				(!isEdit && isCourier)
					? <div className={cn({"os-man-info fi-mb": (isCourier && data && data.commentc && data.commentc != '')})}>
						<FormItem
							edit={false}
							fieldType={"txt"}
							name={"commentm"}
							label={"Комментарий к заказу от менеджера"}
							ico={"comment-o"}
							defVal={data ? data.commentm : ''}
						/>
					</div>
					: null

			}

			<OrderTimes
				{...props}
				isTimeEdit={isTimeEdit}
				isCourier={isCourier}
				timeCookedEdit={timeCookedEdit}
			/>


			{
				isManager
					? <OrderEditManager {...props} />
					: null
			}
			{
				info4courier.length
					? <div className={"os-man-info "}>{info4courier}</div>
					: null
			}

			{statusSelect}
		</>
	)

};

export default OrderEdit;
