import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
// import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {apiSend, in_array} from "utils/func";
import {rolesModerator} from "utils/config";
import {UsersList} from "components/user";

const API_URL = 'users.php';
const UserListContainer = props => {

	const {user, actions} = props;
	const {roleName = ''} = useParams();


	const [users, setUsers] = useState(null);


	const getUsers = async () => {

		apiSend(
			API_URL,
			{
				action: 'getUsers',
				userId: user.id,
				onlyRole: roleName
			},
			(res) => {
				setUsers(res.users);
			},
			actions
		)

	};
	useEffect(() => {

			let isSubscribe = true;

			if (isSubscribe)
				getUsers().then(r => {});

			return () => isSubscribe = false

		},
		[roleName]
	);

	const deleteAction = async (id) => {

		apiSend(
			API_URL,
			{
				action: 'delete',
				target: 'user',
				userId: user.id,
				id: id
			},
			(res) => {
				if (res.deleted) {
					let tmpUsers = [];
					users.map((user) => {
						if (user.id !== id)
							tmpUsers.push(user);

						return null;
					});
					setUsers(tmpUsers);
				}
			}
		)
	};
	const onDelete = (e, _user) => {
		e.preventDefault();
		toastr.confirm(
			'Вы точно хотите удалить пользователя: ' + _user.name + '?',
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => deleteAction(_user.id),

			}
		);
	};

	let panel = null;
	if (users && users.length)
		panel = (
			<div className="flx -sb -alc">
				<div className="">
					Всего: {users.length}
				</div>
				{
					in_array(user.role, rolesModerator) ?
						(
							<Button
								type={"save"}
								title={"Создать пользователя"}
								href={"/user/edit/0" + (roleName ? '/' + roleName : '')}
								// href={"/user/edit/0"}
								ico={"user-plus"}
							/>

						): ''
				}
			</div>
		);
	let contentHeaderParent = [{url: '/user', name: 'Пользователи'}];
	let contentHeaderTitle = 'Пользователи';
	if (roleName !== '') {
		if (user.userRoles)
			contentHeaderTitle = user.userRoles[roleName];
			// contentHeaderParent.push({
			// 	url: '',
			// 	name: user.userRoles[roleName]
			// });
	}
	else {
		contentHeaderTitle = 'Все';
		// contentHeaderParent.push({
		// 	url: '',
		// 	name: 'Все'
		// });
	}


	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
			/>
			{panel}

			{
				(users)
					? <UsersList
						users={users}
						user={user}
						onDelete={onDelete}
						roleInit={roleName}
					/>
				: null
			}
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);

// const mapStateToProps = store => ({
// 	user: store.user
// });
//
// const mapDispatchToProps = dispatch => ({
// 	actions: bindActionCreators({
// 		showLoading, hideLoading
// 	}, dispatch)
// });
// export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);