import Storage from "utils/Storage";
import {STORAGE_TASKS_FILTER, SESSION_SETTINGS_NAME} from "utils/config";;

export const NOW_DATE = 'NOW_DATE';
export const GUI_MENU_TOGGLE = 'GUI_MENU_TOGGLE';
export const GUI_SIDE_MENU_OPEN_STORAGE_KEY = 'sideBarOpen';

export const GUI_SET_DATES = 'GUI_SET_DATES';
export const GUI_SET_DATES_ACTION = 'GUI_SET_DATES_ACTION';
export const GUI_SET_IS_PRINT = 'GUI_SET_IS_PRINT';
export const GUI_SET_SETTINGS = 'GUI_SET_SETTINGS';
export const GUI_SET_SETTINGS_ONE = 'GUI_SET_SETTINGS_ONE';

export const GUI_SET_CALENDAR_SELECT_DATE_ACTION = 'GUI_SET_CALENDAR_SELECT_DATE_ACTION';
export const GUI_SET_SEARCH_ACTION = 'GUI_SET_SEARCH_ACTION';
export const GUI_SET_SOCKET = 'GUI_SET_SOCKET';
export const GUI_TOGGLE_START_DAY_WIN = 'GUI_TOGGLE_START_DAY_WIN';


export const sideBarToggle = (isOpen) => {

	Storage.set(GUI_SIDE_MENU_OPEN_STORAGE_KEY, isOpen);
	return {
		type: GUI_MENU_TOGGLE,
		payload: {
			sideBarOpen: isOpen
		}
	}
};

export const setCalendarDates = (dates) => {
	console.log('-----setCalendarDates-----');
	console.log(dates);

	Storage.session_set(NOW_DATE, new Date(dates.start).getTime());

	return {
		type: GUI_SET_DATES,
		payload: dates
	}
};
export const setIsPrint = (isPrint) => {
	return {
		type: GUI_SET_IS_PRINT,
		payload: isPrint
	}
};
export const setSettings = (val) => {
	// console.log('setSettings', val);
	// const sessionSettings = Storage.get(SESSION_SETTINGS_NAME, null, true);
	// sessionSettings[ key ] = val;
	Storage.set(SESSION_SETTINGS_NAME, val);

	return {
		type: GUI_SET_SETTINGS,
		payload: val
	}
};
export const setSettingsOne = (key, val) => {
	const sessionSettings = Storage.get(SESSION_SETTINGS_NAME, {}, true);
	sessionSettings[ key ] = val;
	Storage.set(SESSION_SETTINGS_NAME, sessionSettings);
	// console.dir(sessionSettings);
	return {
		type: GUI_SET_SETTINGS_ONE,
		payload: {
			key: key,
			value: val
		}
	}
};
export const setCalendarSelectDateAction = (func) => {
	return {
		type: GUI_SET_CALENDAR_SELECT_DATE_ACTION,
		payload: func
	}
};
export const setSearchAction = (func) => {
	return {
		type: GUI_SET_SEARCH_ACTION,
		payload: func
	}
};
export const setSocket = (val) => {
	return {
		type: GUI_SET_SOCKET,
		payload: val
	}
};
export const showStartDay = (val) => {
	// console.log('showStartDay', val);
	return {
		type: GUI_TOGGLE_START_DAY_WIN,
		payload: val
	}
};
// export const setTasksFilter = (val) => {
//
// 	Storage.set(STORAGE_TASKS_FILTER, val);
//
// 	return {
// 		type: TASKS_ACTION_SET_FILTER_PARAMS,
// 		payload: val
// 	}
// };
