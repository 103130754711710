import React, {useEffect, useState} from 'react';
import date from "php-date";
import {toastr} from "react-redux-toastr";
import {rolesAdmin} from "utils/config";
import {
	isRole, apiSend,
	// in_array
} from "utils/func";
import {Win, FormItem, Button} from "components/_common";
// import {USER_LOGOUT} from "actions/UserActions";
// import {showStartDay} from "actions/GUIActions";
import Storage from "utils/Storage";


/**
 * TODO для других уведомлений можно ввести типы и соотв.  разные виды юзеров
 *
 */
const API_URL = 'users.php';
const NotifyUsersWin = ({GUI, user, actions, show}) => {

	const [couriersInWork, setCouriersInWork] = useState(new Set());
	const [couriers, setCouriers] = useState([]);


	// const isAdmin = isRole(user, rolesAdmin);
	// const isManager = isRole(user, ['manager']);

	const setDayIsStarted = () => {
		Storage.set(`startDayShowToday_${user.id}`, true, 3600 * 15);
	}
	const getCouriers = async () => {
		await apiSend(
			API_URL,
			{
				action: 'getUsers',
				userId: user.id,
				onlyRole: 'courier',
				confirmed: true
			},
			(res) => {
				// console.log(res);
				if (res.users && res.users.length) {
					setCouriers(res.users);
					setCouriersInWork(new Set());
				}
			},
			actions
		);
	};

	useEffect(() => {
		let isSubscribe = true;

		if (isSubscribe) {
			getCouriers().then(null);
		}

		return () => {
			isSubscribe = false;
		}
	}, [GUI.showStartDayWin]);


	const doStart = async () => {

		await apiSend(
			API_URL,
			{
				action: 'usersNotify',
				userId: user.id,
				couriers: Array.from(couriersInWork)
			},
			(res) => {
				actions.showStartDay(false);
				// console.dir(res);
				// if (res.startDay !== undefined && res.startDay.length) {
				//
				// 	actions.setSettingsOne('startDay', res.startDay);
				// 	actions.showStartDay(false);
				// 	// setDayStartUsers(res.startDay);
				//
				// }
			},
			actions
		);
	};


	if (!GUI.showStartDayWin && !show) {
		return null;
	}

	let footer = null;
	// if (isAdmin)
		footer = <div className={"win-ftr-btns flx -c"}>
			<Button type="save"
					ico={"play-circle"}

					title={"Отправить"}
					onClick={async () => {

						// console.log(couriersInWork.size);
						// console.dir(couriersInWork);
						if (couriersInWork.size === 0)
							toastr.error('Ошибка', 'Не выбраны курьеры');
						else {
							await doStart();
							setDayIsStarted();
							actions.showStartDay(false);
						}
					}}
			/>
		</div>


	return (
		<div className={"start-day popup-win"}>
			<Win
				onClose={() => {
					setDayIsStarted();
					// if (!doShow && !dayIsStarted)
					// 	actions.userAuth(USER_LOGOUT, null);
					// else
					actions.showStartDay(false);
				}}
				header={"Уведомление о передаче геолокации курьерам:"}
				noOverflowContent={false}
				footer={footer}
			>
				{
					(couriers.length)
						? <AdminMessage
							user={user}
							couriersInWork={couriersInWork}
							setCouriersInWork={setCouriersInWork}
							couriers={couriers}
							// dayStartUsers={dayStartUsers}
							doShow={GUI.showStartDayWin}
						/>
						: null
						// : (
						// 	isManager
						// 		? <ManagerMessage/>
						// 		: <div className="">
						// 			undefined
						// 		</div>
						// )
				}
			</Win>
		</div>
	)

};

export default NotifyUsersWin;


const AdminMessage = ({user, couriersInWork, setCouriersInWork, couriers = []}) => {


	if (couriers.length === 0)
		return 'Нет курьеров';

	return (
		<div className="start-day-admin">
					{
				couriers.map(cur => {

					let name = cur.name;
					let edit = !cur.isActive;
					if (cur.isActive)
						name += ' (активен)';
					if (cur.telegram_id == '') {
						name += ' (нет telegram_id)';
						edit = false;
					}

					return <FormItem
						key={cur.id}
						name={"sdCur"}
						edit={edit}
						fieldType={"bool"}
						defVal={cur.id}
						label={name}

						changeAction={(e, checked) => {

							let tmp = new Set(couriersInWork);
							if (checked)
								tmp.add(cur);
							else
								tmp.delete(cur);

							setCouriersInWork(tmp);
						}}
					/>
				})
			}
		</div>
	);
};
const ManagerMessage = (props) => {

	return (
		<div>
			Администратор не начал день.
		</div>
	);
};

