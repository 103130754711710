import React, {useEffect, useState, createRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {toastr} from "react-redux-toastr";
import {useParams} from "react-router-dom";
import {ContentHeader, Win, Button} from "components/_common";
import {in_array} from "utils/func";
import {rolesModerator, rolesAdmin} from "utils/config";
import {UserEdit} from "components/user";

const UserEditContainer = props => {

	const {user, actions, edit = true, editSelf = false} = props;

	let { id = -1, roleName = 'client' } = useParams();
	const userId = (editSelf && user) ? parseInt(user.id) : parseInt(id);

	const formRef = createRef();
	const avatarFileRef = createRef();

	const [userData, setUserData] = useState(null);

	let apiActionProcess = false;
	const onLoad = async (isSubscribe) => {
		if (!isSubscribe)
			return;
		// console.log('getUser: userId=' + userId + '/ id=' + id + ' / roleName=' + roleName);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('users.php',  {
				action: 'getUsers',
				userId: user.id,
				id: userId
			});

			apiAction(res, (data) => {
				// console.log(data);
				if (data.users) {
					setUserData(
						userId ? data.users : null
					);
				}

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	};
	useEffect(() => {
		let isSubscribe = true;

		onLoad(isSubscribe).then(r => {});
		return () => {
			isSubscribe = false;
			// setUserData(null);
		}
	}, [userId]);


	const saveUser = async (isReturn = false) => {

		const form = formRef.current;
		const avatarFile = avatarFileRef.current;

		let err = [];

		if (form.login.value == '')
			err.push('Логин не задан');

		if (!userId && form.password.value == '')
			err.push('Пароль не задан');

		if (form.name.value == '')
			err.push('Имя не задано');

		// if (form.email.value == '')
		// 	err.push('Email не задан');

		if (err.length) {
			{err.map((txt, i) => {
				toastr.error('Ошибка заполнения', txt);
			})}
		}
		else {

			actions.showLoading();

			const formData = new FormData();

			formData.append('action', 'saveUser');
			formData.append('userId', user.id);

			const data = {
				id: userData ? userData.id : 0,
				login: form.login.value,
				password: form.password.value,
				role: form.role ? form.role.value : userData.role,
				name: form.name.value,
				email: form.email.value,
				phone: form.phone.value,
				telegram_id: form.telegram_id.value,
				num: form.num != undefined ? form.num.value : 0,
				address: form.address ? form.address.value : '',
				confirmed: form.confirmed && form.confirmed.checked,
				google_spreadsheets_url: form.google_spreadsheets_url ? form.google_spreadsheets_url.value : '',
				rest_api_url: form.rest_api_url ? form.rest_api_url.value : ''
			};
			// console.dir(avatarFile.files);

			formData.append('data', JSON.stringify(data));
			if (avatarFile.files.length)
				formData.append('avatar', avatarFile.files[0]);

			const res = await Api.post('users.php', formData);
			apiAction(res, (result) => {

				if (isReturn)
					props.history.push(`/user/${data.role}`);

			});
			actions.hideLoading();
		}

	};

	const generateToken = async () => {

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('users.php',  {
				action: 'generateToken',
				userId: user.id
			});

			apiAction(res, (data) => {
				console.log(data);
				if (data.newToken !== undefined) {
					setUserData((prev) => ({
						...prev,
						token: data.newToken
					}));
				}

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	};

	let contentHeaderParent = [];
	if (in_array(user.role, rolesModerator))
		contentHeaderParent = [
			{url: '/user', name: "Пользователи"},
		];

	let onClose = null;
	let onCloseUrl = `/user`;
	if (userData && userData.role)
		onCloseUrl = `/user/${userData.role}`;

	if (!editSelf)
		onClose = () => props.history.push(onCloseUrl);


	let title = '';
	if (edit)
		title = id ? 'Редактирование' + (editSelf ? ' профиля' : '') : 'Создание пользователя';
	else
		title = 'Информация о пользователе';

	let editButton = null;
	if (in_array(user.role, rolesAdmin))
		editButton = <Button
			type="save"
			title={"Редактировать"}
			onClick={() => props.history.push('/user/edit/' + userData.id)}
		/>;

	return (
		<>
			<ContentHeader
				parent={contentHeaderParent}
				title={title}
			/>
			<Win
				onClose={onClose}
				footer={
					edit ?
						(
							<div className={"win-ftr-btns"}>
								<Button type="apply" onClick={() => saveUser(0)}/>
								{!editSelf ? (
									<>
										<Button type="save" onClick={() => saveUser(1)}/>
										<Button type="close" onClick={onClose} />
									</>
								) : null
								}
							</div>
						)
						: editButton
				}
			>
				{
					(userData !== null || userId === 0)
						? <UserEdit
							userId={userId}
							user={user}
							formRef={formRef}
							userData={userData}
							edit={edit}
							editSelf={editSelf}
							rolesList={user.userRoles}
							avatarFileRef={avatarFileRef}
							roleNameDefault={roleName}
							generateToken={generateToken}
						/>
						: null
				}
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);
