import React from 'react';
import cn from "classnames";
import OrdersListItem from "./OrdersListItem";
import {rolesManager} from "utils/config";
import {in_array, tblSortByArrow} from "utils/func";



const OrdersList = props => {
	const {
		user, settings,
		statusList,
		couriersList,
		clientsList,
		managersList,
		orders,

		sortField, sortAsc, onSort,

	} = props;


	const isManager = in_array(user.role, rolesManager);
	const isClient = in_array(user.role, ['client']);
	const isCourier = in_array(user.role, ['courier']);

	const columnTitle = {
		id: '№',
		created: 'Создан',
		status: 'Статус',
		client: isCourier ? 'Контрагент' : 'Клиент',
		time_cooked: isCourier
			? <>Время забора заказа</>
			: <>Время <br/>при&shy;го&shy;тов&shy;ления</>,
		time_delivery: <>Время доставки</>,
		manager: 'Менеджер',
		phone: 'Телефон',
		address: 'Адрес доставки',
		courier: 'Курьер',
		courierPhone: 'Телефон курьера',
	};

	let ol4Role = user.role;
	if (isManager)
		ol4Role = 'manager';
	// console.log(sortField, sortAsc);

	// console.dir(clientsList);

	return (
		<div className={"orders-list tbl  -bordered mt20 -win -radiused X-zebra Xdark ol-4-" + ol4Role}>
			<div className="thead">
				<div className="tr">
					<div
						className="td col-n"
						onClick={() => onSort('id')}
					>
						{columnTitle.id}
						{/*{tblSortArrow(sortField === 'id', sortAsc)}*/}
						{/*{tblSortByArrow('id', sortField, sortAsc)}*/}
					</div>
					<div
						className="td col-created sortable"
						onClick={() => onSort('createdTime')}
					>
						{columnTitle.created}
						{/*{tblSortArrow(sortField === 'created', sortAsc)}*/}
						{/*{tblSortByArrow('createdTime', sortField, sortAsc)}*/}
					</div>
					<div className="td col-status">
						{columnTitle.status}
					</div>
					{
						(isManager || isCourier) ? <div className="td col-client">
							{columnTitle.client}
						</div> : null
					}

					<div className="td col-prepare"
						 onClick={() => onSort('time_cooked')}
					>
						{columnTitle.time_cooked}

						{/*{tblSortByArrow('time_cooked', sortField, sortAsc)}*/}
					</div>
					<div className="td col-delivery"
						 onClick={() => onSort('time_delivery_max')}
					>
						{columnTitle.time_delivery}

						{/*{tblSortByArrow('time_delivery_max', sortField, sortAsc)}*/}
					</div>
					<div className="td col-manager">
						{columnTitle.manager}
					</div>
					{
						isCourier ? <div className="td col-tel">
							{columnTitle.phone}
						</div> : null
					}
					<div className="td col-address">
						{columnTitle.address}
					</div>
					{
						!isCourier ? <div className="td col-cur">
							{columnTitle.courier}
						</div> : null
					}

					{
						isClient ? <div className="td col-last">
							{columnTitle.courierPhone}
						</div> : null
					}
				</div>
			</div>
			<div className="tbody">
				{
					orders.map(order =>
						<OrdersListItem
							key={order.id}
							user={user}
							order={order}
							settings={settings}
							statusList={statusList}
							couriersList={couriersList}
							clientsList={clientsList}
							managersList={managersList}

							isManager={isManager}
							isClient={isClient}
							isCourier={isCourier}

							columnTitle={columnTitle}
						/>
					)
				}
			</div>
		</div>
	)

};

export default OrdersList;
