import React, {useState} from 'react';
import {FormItem, Button} from "components/_common";
import {in_array} from "utils/func";
import {rolesAdmin, rolesModerator, NBSP} from "utils/config";
import {toastr} from "react-redux-toastr";


const UserEdit = props => {

	const {
		edit, editSelf,
		formRef, avatarFileRef,
		userId, userData,
		// divisions,
		rolesList, user,
		roleNameDefault,
		generateToken
	} = props;

	let roleSelect = null;
	// let divisionSelect = null;

	const [selectedRole, setSelectedRole] = useState(
		userData ? userData.role : roleNameDefault
	);


	let isModerator = in_array(user.role, rolesModerator);


	let canEdit = edit;
	if (edit) {

		if (isModerator) {

			//роли может править только модератор/админ, и НЕ у главного юзера
			if (userId !== 1) {
				let rolesOptions = {};
				for (const role in rolesList) {
					if (role !== 'admin' || in_array(user.role, rolesAdmin))
						rolesOptions[role] = {
							value: rolesList[role]
						}
				}

				roleSelect = <FormItem
					edit={true}
					name={"role"}
					fieldType={"select"}
					options={rolesOptions}
					label={"Роль"}
					defVal={selectedRole}
					changeAction={(e) => setSelectedRole(e.target.value)}
					isRequire={true}
				/>;
			}

		} else
			canEdit = false;

	}


	return (
		<form ref={formRef} encType={"multipart/form-data"}>
			<FormItem
				// edit={edit && !editSelf}
				edit={canEdit}
				name={"login"}
				label={"Логин (для входа в систему)"}
				fieldType={"text"}
				isRequire={true}
				defVal={userData ? userData.login : ''}
				ico={"user-o"}
				descr={
					(edit && !editSelf)
						? "Допустимые знаки: a-z0-9_"
						: null
				}
				changeAction={(e, val) => {
					return val.replace(/\W/i, '');
				}}
			/>
			<FormItem
				edit={edit}
				name={"password"}
				label={"Пароль"}
				fieldType={"password"}
				isRequire={!userId}
				defVal={""}
				ico={"key"}
				descr={userId ? "задайте новый пароль" : ''}
			/>

			{roleSelect}

			<FormItem
				edit={canEdit}
				name={"name"}
				label={"Ф.И.О"}
				isRequire={true}
				defVal={userData ? userData.name : ''}
				ico={"user"}
			/>

			<FormItem
				edit={canEdit}
				name={"email"}
				label={"Email"}
				isRequire={false}
				defVal={userData ? userData.email : ''}
				ico={"envelope"}
			/>
			<FormItem
				edit={canEdit}
				name={"phone"}
				label={"Телефон"}
				defVal={userData ? userData.phone : ''}
				ico={"phone"}
				mask={edit ? "+7 (999) 999-99-99" : null}
			/>

			<FormItem
				edit={canEdit}
				name={"telegram_id"}
				label={"Telegram ID"}
				defVal={userData ? userData.telegram_id : ''}
				ico={"telegram"}
			/>

			{
				selectedRole == 'client'
					? <FormItem
						edit={canEdit}
						name={"address"}
						label={"Адрес"}
						defVal={userData ? userData.address : ''}
						ico={"map-marker"}
					/>
					: null
			}
			{
				selectedRole == 'client'
					? <FormItem
						edit={canEdit}
						name={"google_spreadsheets_url"}
						label={"URL отчет контрагента в Google таблицах"}
						defVal={userData ? userData.google_spreadsheets_url : ''}
						ico={"table"}
					/>
					: null
			}
			{
				isModerator
					? <FormItem
						fieldType={"bool"}
						edit={edit}
						name={"confirmed"}
						label={"Подтвержден/Активен"}
						defVal={1}
						// ico={"toggle-on"}
						isChecked={userData && userData.confirmed}
					/>
					: null
			}

			{/*<FormItem*/}
			{/*	edit={edit}*/}
			{/*	name={"info"}*/}
			{/*	label={"Дополнительные данные"}*/}
			{/*	defVal={userData ? userData.info : ''}*/}
			{/*	ico={"info"}*/}
			{/*	fieldType={"txt"}*/}
			{/*/>*/}
			{/*<FormItem*/}
			{/*	edit={edit}*/}
			{/*	name={"num"}*/}
			{/*	label={"Порядок"}*/}
			{/*	defVal={userData ? userData.num : ''}*/}
			{/*	ico={"sort-numeric-asc"}*/}
			{/*	descr={"порядок сортировки, может исп. в разных частях, целое число 0 и больше"}*/}
			{/*	numericRound={0}*/}
			{/*/>*/}
			<div className="user-edit-avatar">
				<h3>Изображение профиля</h3>
				<div className="flx">
					<div className="-av">
						{
							(userData && userData.avatar) ? < img src={userData.avatar}/> : null
						}
					</div>
					<FormItem
						edit={edit}
						name={"avatar"}
						label={null}
						fieldType={"file"}
						isRequire={false}
						reff={avatarFileRef}
						// defVal={userData && userData.login}
					/>
				</div>

			</div>

			<br/><br/>
			<h3>API</h3>
			<div className="row mt20">
				<div className="col-2-3">
					<FormItem
						edit={false}
						name={"token"}
						label={"Token"}
						defVal={(userData && userData.token != undefined) ? userData.token : '' }
						ico={"key"}
						fieldType={"text"}
						descr={"чтобы увидеть/скопировать, сгенерируйте новый"}
						icoRight={{
							ico: 'copy',
							onClick: async (e, input) => {
								let value = '';
								if ((value = input.value) !== '') {
									await navigator.clipboard.writeText(value);
									toastr.info('Сообщение', 'Скопировано в буфер обмена');
								}
							}
						}}
					/>
				</div>
				<div className="col-3">
					<FormItem
						edit={false}
						name={"tokenbtn"}
						label={NBSP}

						fieldType={
							<Button
								title={"Сгенерировать Token"}
								onClick={e => generateToken()}
							/>
						}


					/>

				</div>
			</div>

			<FormItem
				edit={edit}
				name={"rest_api_url"}
				label={"URL куда посылаются данные с сервиса, например изменения статусов заказа"}
				defVal={userData && userData.rest_api_url}
				ico={"globe"}
			/>
		</form>
	)

};

export default UserEdit;
