import React, {useState} from 'react';
import cn from "classnames";

const Tabs = props => {


	const {data = []} = props;


	const [activeTab, setActiveTab] = useState(0);

	const tabLen = data.length;

	return (
		<div className="tabs">
			<ul className="tabs-list flx _z">
				{
					data.map((tab, i) => {
						// console.log(tab.title, (i - tabLen) * -1);
						let title  = tab.title ? tab.title : 'undefined';
						return (
							<li
								key={"tabs-list-item-" + title + i}
								className={cn({active: activeTab == i})}
								onClick={() => setActiveTab(i)}
								style={{
									zIndex: ((i - tabLen) * -1) + (activeTab == i ? 1 : 0)
								}}
							>
								{title}
							</li>
						)
					})
				}
			</ul>
			<div className="tabs-panels">
				{
					data.map((tab, i) => {
						return <div
							key={"tabs-panel-" + tab.title + i}
							className={cn({active: activeTab == i})}>
							{
								tab.content
								? tab.content
								: null
							}
						</div>
					})
				}
			</div>
		</div>
	)

};

export default Tabs;
