import React, {createRef, useState} from 'react';
import {Win, FormItem} from "components/_common";
import {Button} from "components/_common";
import {in_array, array_unset} from "utils/func";


const City = ({name, setCities, setSavedSettings}) => {
	return (
		<div className="orders-sets-city">
			<div className="-n">
				{name}
			</div>
			<i className={"fa fa-close"}
			   onClick={e => {
					setCities(prev => {
						let n = array_unset([...prev], name);

						setSavedSettings(prev => ({
							...prev,
							cities: n
						}));
						return n;
					});

			   }}
			></i>
		</div>
	);
}
const Cities = props => {

	const {
		setSavedSettings,
		settings
	} = props;

	const inputRef = createRef();


	// const {cities = []} = settings.cities;

	const [cities, setCities] = useState(
		(settings && settings.cities) ? settings.cities : []
	);
	const [cityField, setCityField] = useState('');

	console.log('Cities RENDER');

	return (
		<Win
			header={"Города"}
		>
			{
				cities.map((name, i) =>
					<City
						key={'cl-' + i}
						name={name}
						setCities={setCities}
						setSavedSettings={setSavedSettings}
					/>
				)
			}
			<div className="row mt20">
				<div className="col-2-3">
					<FormItem
						name={"city"}
						placeholder={'Впишите название населенного пункта'}
						defVal={cityField}
						reff={inputRef}
						changeAction={(e, v) => setCityField(v)}
					/>
				</div>
				<div className="col-3">
					<Button
					title={"Добавить"}
					onClick={e => {
						// let name = inputRef.current.value;

						if (inputRef.current.value !== '' && !in_array(inputRef.current.value, cities)) {
							let _cities = [...cities];
							_cities.push(inputRef.current.value.trim());
							_cities = _cities.sort();

							setCities(_cities);
							setCityField('');

							setSavedSettings(prev => ({
								...prev,
								cities: _cities
							}));
						}

					}}
					/>
				</div>
			</div>
		</Win>
	)

};

export default Cities;
