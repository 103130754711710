import React, {createRef, useEffect, useState} from 'react';
import {Win, ColorPicker, FormItem, Tabs} from "components/_common";
import {ORDER_STATUS_COLORS_DEFAULT, rolesAdmin, rolesManager, DEBUG, DEBUG_TEST} from "utils/config";
import {in_array} from "utils/func";

// import cn from "classnames";
// import date from "php-date";
// import * as strtotime from "strtotime";
// import {toastr} from "react-redux-toastr";
// import {FormItem} from "components/_common";
// import {in_array, array_unset} from "utils/func";


const General = props => {

	const {
		user,
		savedSettings, setSavedSettings,
		settings,
		orderStatusList,
	} = props;

	const formRef = createRef();

	const setOrderStatusColorsInit = () => {
		let orderStatusColorsInit = {};
		for (let k in orderStatusList) {
			// console.log(k);

			orderStatusColorsInit[k] = {
				bgr: (settings.orderStatusColors && settings.orderStatusColors[k]) ? settings.orderStatusColors[k].bgr : ORDER_STATUS_COLORS_DEFAULT.bgr,
				txt: (settings.orderStatusColors && settings.orderStatusColors[k]) ? settings.orderStatusColors[k].txt : ORDER_STATUS_COLORS_DEFAULT.txt,

			}
		}
		// console.log(orderStatusColorsInit);
		setOrderStatusColors(orderStatusColorsInit);
	}


	const [orderStatusColors, setOrderStatusColors] = useState(null);
	useEffect(() => {
		setOrderStatusColorsInit();
	}, []);

	const tabsData = [];

	const orderStatusItems = [];

	if (orderStatusColors) {
		for (let k in orderStatusList) {
			orderStatusItems.push(
				<div className="tr" key={"s-osi" + k}>
					<div className="td col-3">
						{orderStatusList[k].name}
					</div>
					<div className="td col-3">
						<ColorPicker
							color={orderStatusColors[k].bgr}
							getColor={color => {
								let osc = orderStatusColors;
								osc[k].bgr = color;
								setOrderStatusColors(osc);
								setSavedSettings(prev => (
									{
										...prev,
										orderStatusColors: osc
									}
								));
							}}
						/>
					</div>
					<div className="td col-3 flex-1">
						<ColorPicker
							color={orderStatusColors[k].txt}
							getColor={color => {
								let osc = orderStatusColors;
								osc[k].txt = color;
								setOrderStatusColors(osc);
								setSavedSettings(prev => (
									{
										...prev,
										orderStatusColors: osc
									}
								));
							}}
						/>
					</div>
				</div>
			);
		}
	}

	let blocks = [];
	if (in_array(user.role, rolesManager)) {
		const ordersItems = (
			<>
				{
					(DEBUG || DEBUG_TEST)
						? <FormItem
							fieldType={"text"}
							name={"debug_server_time"}
							label={"Серверное время"}
							defVal={settings.debug_server_time ? settings.debug_server_time : '00:00'}
							mask={"99:99"}
							changeAction={(e, val) => {

								setSavedSettings({
									...savedSettings,
									debug_server_time: val.trim()
								});
							}}
							descr={"Используется для проверки, если установлено в 00:00, то сервеное время равно реальному"}
						/>
						: null
				}
				<div className="row">
					<div className="col-3">
						<FormItem
							fieldType={"text"}
							name={"time_cooked_minimal"}
							label={"Минимальное время приготовления заказа, часы:минуты"}
							defVal={settings.time_cooked_minimal ? settings.time_cooked_minimal : '00:00'}
							mask={"99:99"}
							changeAction={(e, val) => {
								setSavedSettings({
									...savedSettings,
									time_cooked_minimal: val.trim()
								});
							}}
						/>

					</div>
					<div className="col-3">
						<FormItem
							fieldType={"text"}
							name={"time_delivery_minimal"}
							label={"Минимальное время доставки, часы:минуты"}
							defVal={settings.time_delivery_minimal ? settings.time_delivery_minimal : '00:00'}
							mask={"99:99"}
							changeAction={(e, val) => {
								setSavedSettings({
									...savedSettings,
									time_delivery_minimal: val.trim()
								});
							}}
						/>
					</div>
					<div className="col-3">
						<FormItem
							fieldType={"text"}
							name={"time_closed"}
							label={"Время закрытия, часы:минуты"}
							defVal={settings.time_closed ? settings.time_closed : '23:00'}
							mask={"99:99"}
							changeAction={(e, val) => {
								setSavedSettings({
									...savedSettings,
									time_closed: val.trim()
								});
							}}
							descr={"После этого времения и до 5 утра следующего дня, невозможно создавать заказы"}
						/>
					</div>
				</div>
				<FormItem
					fieldType={"text"}
					name={"time_cooked_plus"}
					label={"Время приготовления (базовое), мин"}
					numericRound={0}
					defVal={settings.time_cooked_plus ? settings.time_cooked_plus : 0}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							time_cooked_plus: parseInt(e.target.value.trim())
						});
					}}
					descr={"добавляется ко времени создания заказа"}
				/>
				<FormItem
					fieldType={"text"}
					name={"time_delivery_plus"}
					label={"Время доставки (базовое), мин"}
					numericRound={0}
					defVal={settings.time_delivery_plus ? settings.time_delivery_plus : 0}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							time_delivery_plus: parseInt(e.target.value.trim())
						});
					}}
					descr={"добавляется ко времени создания заказа"}
				/>
				<FormItem
					fieldType={"text"}
					name={"time_between_cooked_delivery"}
					label={"Разница между приготовлением и доставкой (минимальная), мин"}
					numericRound={0}
					defVal={settings.time_between_cooked_delivery ? settings.time_between_cooked_delivery : 20}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							time_between_cooked_delivery: parseInt(e.target.value.trim())
						});
					}}
					descr={"добавляется ко времени создания заказа"}
				/>

				<FormItem
					fieldType={"text"}
					name={"order_cost_predefined"}
					label={"Стоимость заказа (руб.), предустановленные значения"}
					defVal={settings.order_cost_predefined ? settings.order_cost_predefined : ''}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							order_cost_predefined: val.trim()
						});
					}}
					descr={"через запятую"}
				/>
				<FormItem
					fieldType={"text"}
					name={"price_courier_predefined"}
					label={"Премия водителя (руб.), предустановленные значения"}
					defVal={settings.price_courier_predefined ? settings.price_courier_predefined : ''}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							price_courier_predefined: val.trim()
						});
					}}
					descr={"через запятую"}
				/>


			</>
		);
		tabsData.push({
			title: 'Заказы',
			content: ordersItems
		});
	}
	if (in_array(user.role, rolesAdmin)) {
		const reportsItems = (
			<>
				<FormItem
					fieldType={"text"}
					name={"google_spreadsheets_id"}
					label={"Google таблицы, ИД документа - отчет администратора"}
					defVal={settings.google_spreadsheets_id ? settings.google_spreadsheets_id : ''}
					changeAction={(e, val) => {
						setSavedSettings({
							...savedSettings,
							google_spreadsheets_id: val.trim()
						});
					}}
				/>
				{/*<FormItem*/}
				{/*	fieldType={"text"}*/}
				{/*	numericRound={0}*/}
				{/*	name={"time_delivery_full_average"}*/}
				{/*	label={"Срднее общее время доставки (с момента поступления заказа)"}*/}
				{/*	defVal={settings.time_delivery_full_average ? settings.time_delivery_full_average : ''}*/}
				{/*	changeAction={(e, val) => {*/}
				{/*		setSavedSettings({*/}
				{/*			...savedSettings,*/}
				{/*			time_delivery_full_average: val.trim()*/}
				{/*		});*/}
				{/*	}}*/}
				{/*	descr={"для доставок ко времени и с/по"}*/}
				{/*/>*/}
			</>
		);
		tabsData.push({
			title: 'Отчеты',
			content: reportsItems
		});
		const statusColors = (
			<>
				<div className="tbl dark -bordered col-collapsed">
					<div className="thead">
						<div className="tr">
							<div className="td col-3">
								Статус
							</div>
							<div className="td col-3">
								Цвет фона
							</div>
							<div className="td col-3 flex-1">
								Цвет текста
							</div>
						</div>
					</div>
					<div className="tbody">
						{orderStatusItems}
					</div>
				</div>

			</>
		);
		tabsData.push({
			title: 'Статусы заказов - цвета',
			content: statusColors
		});
	}


	return (
		<form encType={"multipart/form-data"} ref={formRef}>
			<Tabs
				data={tabsData}
			/>
		</form>
	)

};

export default General;
