import React, {useState} from 'react';
import cn from "classnames";
import { NavLink } from "react-router-dom";

const BUTTONS = {
	apply: {
		cls: '',
		title: 'Сохранить'
	},
	applyIco: {
		cls: '',
		title: 'Сохранить',
		ico: 'floppy-o'
	},
	save: {
		cls: ' -green',
		title: 'Сохранить и вернуться'
	},
	close: {
		cls: ' -red',
		title: 'Закрыть'
	},
	delete: {
		cls: ' -grey',
		title: 'Удалить',
		ico: 'trash-o'
	}
};

const Button = (props) => {

	const {type = 'apply', cls = '',
		onClick = () => {}, title = null, tip = null,
		doClick = () => {},
		tipTimeout = 500, ico = null, icoOnly=false,
		disabled = false,
		href = null, hrefBlank = false, buttonType = 'div'
	} = props;
	const [tipShowed, setTipShowed] = useState(false);

	let _ico = ico;
	if (BUTTONS[type] && BUTTONS[type].ico)
		_ico = BUTTONS[type].ico;




	const inner = (
		<>
			<div className={cn("tip", {active: tipShowed})}>
				{tip}
			</div>
			<div className={cn("-in", {emptyTitle: (typeof title === "string" && title === '')})}>
				{_ico && <i className={cn("fa", "fa-"+_ico)}></i>}
				{
					!icoOnly ?
						(title ? title : (BUTTONS[type] ? BUTTONS[type].title: null))
						: null
				}
			</div>
		</>
	);
	const classNames = cn("btn",
		BUTTONS[type] ? BUTTONS[type].cls : '',
		cls,
		{
			disabled: disabled,
			"-icon-only": icoOnly && ico
		});

	const handleClick = e => {
		if (!disabled) {
			onClick(e);
			// doClick(e);

			if (tip && tipTimeout) {
				setTipShowed(true);
				// setTimeout(() => setTipShowed(false), tipTimeout)
			}
		}
	};
	const handleMouseEnter = () => {
		if (tip && tipTimeout) {
			setTimeout(() => setTipShowed(true), tipTimeout)
		}
	};
	const handleMouseLeave =() => {
		setTipShowed(false);
		setTimeout(() => setTipShowed(false), tipTimeout + 100)
	};

	if (href || buttonType === 'a')
		return (
			<NavLink
				to={href}
				className={classNames}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				target={hrefBlank ? '_blank' : null}
			>
				{inner}
			</NavLink>
		);
	else if (buttonType === 'button')
		return (
			<button
				className={classNames}
				// onClick={handleClick}
				onMouseUp={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{inner}
			</button>
		);
	else
		return (
			<div
				className={classNames}
				onMouseUp={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{inner}
			</div>
		);
};

export default Button;