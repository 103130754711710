import React, {useState} from 'react';
// import {rolesManager} from "utils/config";
// import {in_array} from "utils/func";
import {FormItem} from "components/_common";
import {orderPrintTime, orderFullTime, orderDateTime, ORDER_TIME_LABEL_EXACT, ORDER_DIFF_START_AND_SAVE_TIME} from "./utils";
import cn from "classnames";
import date from "php-date";
import * as strtotime from "strtotime";
import {logger} from "redux-logger/src";



/**
 * Поля  времени, в зависимости от выбора пользователя
 *
 */
const OrderTimes = props => {

	const {
		isEdit, //заказ редактируется
		isNew,//заказ новый
		isCourier,
		//isManager,

		data, setData,
		formErrors, setFormErrors,
		timeCookedEdit,//можно ли править время приготовления, для манагера
		time_delivery_plus, time_cooked_plus,
		time_between_cooked_delivery,
		settings,
		//isTimeEdit
	} = props;

	const [showTimeDeliveryMax, setShowTimeDeliveryMax] = useState((
		(data
			&& data.time_delivery_max
			&& data.time_delivery_max !== data.time_delivery
			&& data.time_delivery_exact == 0
		) ? true: false
	));

	let start_edited_msec = data.startEdited * 1000;
	if (start_edited_msec < (Date.now() - ORDER_DIFF_START_AND_SAVE_TIME))
		start_edited_msec =  (Date.now() - ORDER_DIFF_START_AND_SAVE_TIME);

	const [isEditTimeDelivery, setEditTimeDelivery] = useState(false);
	const [timeCooked, setTimeCooked] = useState(
		(data && data.time_cooked) ? data.time_cooked : orderDateTime(start_edited_msec + time_cooked_plus)
	);

	const {is_time_from_to = false} = data;

	// console.clear();
	// console.log('OrderTimes ReNDER', start_edited_msec);
	// console.log('time_delivery_about', data.time_delivery_about, 'time_delivery',data.time_delivery);
	// console.log('timeCooked', timeCooked, 'time_delivery_max', data.time_delivery_max, data.time_delivery_exact);
	// console.log((data && data.time_delivery_max && data.time_delivery_max !== '' && data.time_delivery_max !== undefined) ? true: false);
	// console.dir(props);


	let TimeCookedEdit = timeCookedEdit && !showTimeDeliveryMax;

	const content = [];
	let subContent = [];

	if (isNew) {

		let timeEditDescr = (
				<div className="flx">
					<div className="">
						<FormItem
							fieldType={"bool"}
							name={"time-from-to"}
							label={"Время в промежутке с-по"}
							notFocusOnTab={true}
							edit={!isEditTimeDelivery}
							changeAction={(e, checked) => {
								console.clear();
								setShowTimeDeliveryMax(checked);
								// setEditTimeDelivery(!checked);

								// console.log(Date.now());
								// console.log(date('H:i', new Date()));
								console.log(data.time_cooked_minimal_hs);

								let time_cooked_minimal = orderFullTime(data.time_cooked_minimal_hs);
								let time_cooked_minimal_msec = strtotime(time_cooked_minimal) * 1000;
								// console.log('tcook min:', time_cooked_minimal, time_cooked_minimal_msec);

								// let time_cooked_msec = strtotime(data.time_cooked) * 1000;
								// console.log(data.time_cooked, time_cooked_msec);

								let dnow_msec = start_edited_msec;
								//
								// console.log(orderDateTime(dnow_msec), time_between_cooked_delivery);
								// console.log('==', orderDateTime(dnow_msec + time_between_cooked_delivery));

								//если вермя готовки меньшем чем "сейчас" и плюс время На готовку
								// if (time_cooked_msec < start_edited_msec + time_cooked_plus) {
								// 	console.log('------ 1');
								// 	dnow_msec = start_edited_msec + time_cooked_plus;
								// }

								//если время меньше чем установленное в настройках, т.е. не раньше чем начнут работать
								if (dnow_msec < time_cooked_minimal_msec)
									dnow_msec = time_cooked_minimal_msec;

								console.log('--', orderDateTime(dnow_msec));


								let time_delivery_new = strtotime(data.time_delivery_about) * 1000;
								let tmax_msec = strtotime(data.time_delivery) * 1000;
								if (checked) {
									//Дмитрий Кучер, [05.12.2021 14:52]
									//С – время реальное + 20 мин минимум, в большую сторону сколько угодно
									time_delivery_new = dnow_msec;
									// console.log('-- 1', orderDateTime(time_delivery_new));
									if (dnow_msec > time_cooked_minimal_msec)
										time_delivery_new += time_between_cooked_delivery;

									// console.log('-- 2', orderDateTime(time_delivery_new));

									//ПО – не менее базового времени доставки, в большую сторону сколько угодно
									tmax_msec = dnow_msec + time_delivery_plus
								}

								console.log('Время в промежутке с-по checked:', checked);
								console.log('dnow_msec', orderDateTime(dnow_msec));

								let time_cooked_new = checked
									? orderDateTime(dnow_msec)
									: data.time_cooked_default;
								// console.log('time_cooked_new', time_cooked_new);

								setData(prev => ({
									...prev,
									changed: true,
									time_cooked: time_cooked_new,
									// time_delivery_about: dnow,
									time_delivery: orderDateTime(time_delivery_new),
									time_delivery_max: orderDateTime(tmax_msec),
									check_time_cooked: !checked,
									check_time_delivery: (!prev.check_time_delivery && checked),
									check_time_delivery_max: checked,
									is_time_from_to: checked
								}));
								setTimeCooked(time_cooked_new);
							}}
						/>
					</div>
					<div className="" style={{marginLeft: '20px'}}>
						<FormItem
							// edit={isEdit}
							fieldType={"bool"}
							name={"time_delivery_exact"}
							label={"Заказ ко времени"}
							edit={!showTimeDeliveryMax}
							defVal={(data && data.time_delivery_exact) ? data.time_delivery_exact : 0}
							changeAction={(e, checked) => {
								setEditTimeDelivery(checked);
								// console.log('time_delivery_plus', data.startEdited, time_delivery_plus);

								setData(prev => ({
									...prev,
									changed: true,
									time_delivery_exact: checked,
									check_time_delivery: (!prev.check_time_delivery && checked),
									check_time_delivery_max: false,
									is_time_from_to: false,
									check_time_cooked: true
								}))
								// let tmax_msec = data.startEdited * 1000 + time_delivery_plus;
								// if (tmax_msec < data.time_delivery_min_milliseconds)
								// 	tmax_msec = data.time_delivery_min_milliseconds;
								//
								// let tmax = date('Y-m-d H:i:00', new Date(tmax_msec));
								//
								// setData(prev => ({
								// 	...prev,
								// 	changed: true,
								// 	time_delivery: tmax,
								// 	time_delivery_max: '',
								// 	time_delivery_exact: checked,
								// 	check_time_delivery: (!prev.check_time_delivery && checked),
								// 	check_time_delivery_max: false
								// }));
							}}
						/>
					</div>
				</div>
			);
		// console.clear();
		// console.dir(data);

		/*
		 * Время С по умолчанию
		 */
		let time_delivery_default = data.time_delivery
			? orderPrintTime(data.time_delivery)
			: orderPrintTime(data.time_delivery_about);
		// console.log('time_delivery_default', data.time_delivery, data.time_delivery_about, time_delivery_default);




		subContent.push(
			<div
				className={showTimeDeliveryMax ? 'col-2' : null}
				key={"td-sub-1-1"}
			>
				<FormItem

					edit={isEditTimeDelivery || showTimeDeliveryMax}
					name={"time_delivery"}
					label={
						(isEditTimeDelivery || showTimeDeliveryMax)
							?
							"Время доставки" + (showTimeDeliveryMax ? ' с ' : '')
							: "Предположительное время доставки"
					}
					ico={"clock-o"}
					isRequire={true}
					defVal={time_delivery_default}
					blurAction={(e, val) => {

						// console.log('showTimeDeliveryMax', showTimeDeliveryMax);
						setData(prev => ({
							...prev,
							changed: true,
							time_delivery: orderFullTime(val),
							time_cooked: showTimeDeliveryMax ? orderFullTime(val) : data.time_cooked
						}));
					}}
					errorMsg={ (formErrors && formErrors.time_delivery) ? formErrors.time_delivery : null }
					setErrorMsg={setFormErrors}
					mask={"99:99"}
					alwaysShowMask={true}
					descr={timeEditDescr}
				/>
			</div>

		);

		if (showTimeDeliveryMax) {
			subContent.push(
				<div
					className={'col-2'}
					key={"td-sub-1-2"}
				>
					<FormItem

						edit={isEditTimeDelivery || showTimeDeliveryMax}
						name={"time_delivery_max"}
						label={"по"}
						ico={"clock-o"}
						isRequire={true}
						defVal={(data && data.time_delivery_max) ? orderPrintTime(data.time_delivery_max) : ''}
						blurAction={(e, val) => {

							setData(prev => ({
								...prev,
								changed: true,
								time_delivery_max: orderFullTime(val)
							}));
						}}
						errorMsg={(formErrors && formErrors.time_delivery_max) ? formErrors.time_delivery_max : null }
						setErrorMsg={setFormErrors}
						mask={"99:99"}
						alwaysShowMask={true}
					/>
				</div>

			);
		}
		content.push(
			<div
				key={"td-about"}
				className={showTimeDeliveryMax ? "row" : null}
			>
				{subContent}
			</div>);

	}
	else {
		// console.dir(data);
		// console.log(showTimeDeliveryMax);
		let timeDeliveryLabel = 'Время доставки';
		if (data.time_delivery_exact)
			timeDeliveryLabel = ORDER_TIME_LABEL_EXACT;
		if (showTimeDeliveryMax)
			timeDeliveryLabel = 'Время доставки с';

		// if (data.time_delivery_exact)
		if (1)
		{
			subContent.push(
				<div
					className={showTimeDeliveryMax ? 'col-2' : null}
					 key={"td-1"}
				>
					<FormItem
						edit={false}
						name={"time_delivery"}
						label={timeDeliveryLabel}
						ico={"clock-o"}
						isRequire={true}
						defVal={orderPrintTime(data.time_delivery)}
					/>
				</div>
			);
			if (showTimeDeliveryMax)
				subContent.push(
					<div className="col-2"
						 key={"td-1-2"}
					>
						<FormItem

							edit={false}
							name={"time_delivery_max"}
							label={"по"}
							ico={"clock-o"}
							isRequire={true}
							defVal={orderPrintTime(data.time_delivery_max)}
						/>
					</div>
				);

		}

		content.push(
			<div
				key={"td-about"}
				className={ !data.time_delivery_exact ? "row" : null}
			>
				{subContent}
			</div>);
	}


	const onChangeTimeCooked = (tCookedHS) => {
		console.clear();
		console.log('onChangeTimeCooked', tCookedHS);
		const time_between_cooked_and_delivery = parseInt(settings.time_between_cooked_delivery ? settings.time_between_cooked_delivery : 20) * 60;
		// const time_between_cooked_and_delivery_msec = time_between_cooked_and_delivery * 1000;

		//время когда можно приготовить + к началу редактирования startEdited
		const time_cooked_plus = parseInt(settings.time_cooked_plus);
		//время когда можно доставить + к startEdited
		const time_delivery_plus = parseInt(settings.time_delivery_plus);
		const time_cooked_plus_unix = time_cooked_plus * 60;
		const time_delivery_plus_unix = time_delivery_plus * 60;
		const tCooked = orderFullTime(tCookedHS);
		let tCookedUnix = strtotime(tCooked);
		const tDeliveryUnix = strtotime(data.time_delivery);
		const time_delivery_min_unix = data.time_delivery_min_milliseconds / 1000;

		console.log('onChangeTimeCooked',
			`tCooked=${tCooked}`, tCookedUnix,
			`time_delivery_plus=${time_delivery_plus}`, `time_between_cooked_and_delivery=${time_between_cooked_and_delivery}`,
			`time_delivery_min_unix=${time_delivery_min_unix}`, orderDateTime(time_delivery_min_unix));

		const {is_time_from_to = false} = data;
		// console.dir('is_time_from_to=' + is_time_from_to);

		//если время меньше тем создание заказа
		// if (tCookedUnix < data.startEdited + time_cooked_plus_unix) {
		// 	// console.log('tCookedUnix < then now + PLUS');
		// 	tCookedUnix = data.startEdited + time_cooked_plus_unix;
		// }
		let time_delivery_new = tDeliveryUnix;
		// console.log('time_delivery_new 1', time_delivery_new, orderDateTime(time_delivery_new));
		if (isEdit) {

			let saveTimeDef = Date.now() / 1000 - 3 * 60;
			let startEdited = data.startEdited;
			if (!is_time_from_to && startEdited < saveTimeDef)
				startEdited = saveTimeDef;

			time_delivery_new = startEdited + time_delivery_plus_unix;

			// console.log('time_delivery_new 2', time_delivery_new, orderDateTime(time_delivery_new), time_between_cooked_and_delivery);





			if ((time_delivery_new - tCookedUnix) < time_between_cooked_and_delivery) {

			 	time_delivery_new = tCookedUnix + time_between_cooked_and_delivery;
			}

			if (time_delivery_new < time_delivery_min_unix)
				time_delivery_new = time_delivery_min_unix;

		}

		console.log('td new', time_delivery_new, orderDateTime(time_delivery_new));

		setTimeCooked(orderDateTime(tCookedUnix));
		setData(prev => ({
			...prev,
			changed: true,
			check_time_cooked: true,
			time_cooked: orderDateTime(tCookedUnix),
			time_delivery: orderDateTime(time_delivery_new),
			time_delivery_max: orderDateTime(time_delivery_new)
		}));

	};


	return (
		<div className={
			cn("row",
				{
					"mt20": isCourier
				})
		}>

			<div className={"col-3"}
				 style={{opacity: (is_time_from_to ? 0 : 1)}}
			>
				<FormItem
					edit={TimeCookedEdit}
					name={"time_cooked"}
					label={isCourier ? "Время забора заказа" : "Время приготовления"}
					ico={"clock-o"}
					isRequire={true}
					// maxLength={100}
					// defVal={orderPrintTime(data.time_cooked)}
					defVal={orderPrintTime(timeCooked)}
					blurAction={(e, val) => {
						onChangeTimeCooked(val);

					}}
					errorMsg={ (formErrors && formErrors.time_cooked) ? formErrors.time_cooked : null }
					setErrorMsg={setFormErrors}

					// mask={isEdit ? timeMask : null}
					mask={"99:99"}
					alwaysShowMask={true}


				/>
			</div>
			<div className={cn("mt20", {"mt20": isCourier})}>

			</div>

			<div className={"col-2-3"}>
				{content}
			</div>


		</div>

	);


};



export default OrderTimes;
