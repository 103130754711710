import React from 'react';
import {Switch, Route} from "react-router-dom";
import {
	LoginContainer, ForgetPassword
} from "containers/user";
import MapContainer from "containers/map/MapContainer";

const LoginRouter = props => {

	return (
		<Switch>
			{/*регистрации нет, пользователи создаются администратором*/}
			<Route path="/forgetpassword" exact>
				<ForgetPassword {...props} />
			</Route>

			<Route path="/map" component={MapContainer} />

			<Route>
				<LoginContainer {...props} />
			</Route>
		</Switch>
	)

};

export default LoginRouter;
