import React, {useState, useEffect, useCallback} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Win, Button} from "components/_common";
import {MENU_URLS, MENU_TITLE, MESSAGES, rolesManager, DEBUG, DEBUG_TEST} from "utils/config";
import {apiSend, in_array} from "utils/func";
import {
	canOrderEdit, ORDER_STATUS_IN_WORK, ORDER_STATUS_OUT_OF_ZONE, ORDER_STATUS_CANCELLED,
	TIME_2_COOK, TIME_2_DELIVERY, TIME_BETWEEN_COOKED_DELIVERY, checkOrderTime, orderDateTime,
	ORDER_DIFF_START_AND_SAVE_TIME
} from "components/orders/utils";
import OrderEdit from "components/orders/OrderEdit";
import {useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {Redirect} from 'react-router'
import date from "php-date";
import * as strtotime from "strtotime";
import * as uniqid from "uniqid";
// import {setSettingsOne} from "actions/GUIActions";

const API_URL = 'orders.php';
const getOrder = async (id = null, props) => {

	const {
		GUI, actions, user,
		setOrderPaymentList,
		setOrderStatusList,
		setCouriers,
		setClients,
		setManagers,
		setOrder,

		time_cooked_minimal_hs,
		time_delivery_minimal_hs,
		// time_cooked_plus: time_cooked_plus_from_settings,
		// time_delivery_plus//: time_delivery_plus_from_settings
	} = props;


	// console.log('getOrder from api');
	await apiSend(
		API_URL,
		{
			action: 'getOrders',
			userId: user.id,
			id: id
		},
		(res) => {
			// console.dir(res);
			if (res.orderPaymentList)
				setOrderPaymentList(res.orderPaymentList);
			if (res.orderStatusList)
				setOrderStatusList(res.orderStatusList);
			if (res.couriers)
				setCouriers(res.couriers);
			if (res.clients)
				setClients(res.clients);
			if (res.managers)
				setManagers(res.managers);


			let NOW = Date.now();
			// - 5* 60 *1000;
			//ТОЛЬКО в секундах
			let startEdited = parseInt((NOW - (new Date().getSeconds() * 1000)) / 1000);//strtotime(date('Y-m-d H:i:00', new Date(NOW)));

			// console.log('NOW', orderDateTime(NOW));

			const time_cooked_plus = res.time_cooked_plus;
			const time_delivery_plus = res.time_delivery_plus;

			let start_edited_msec = startEdited * 1000;
			// console.log('startEdited', startEdited, orderDateTime(startEdited));
			let time_cooked_default_milliseconds = start_edited_msec + time_cooked_plus;
			let time_delivery_about_milliseconds = start_edited_msec + time_delivery_plus;


			let time_cooked_min = date('Y-m-d ' + time_cooked_minimal_hs + ':00');
			let time_delivery_min = date('Y-m-d ' + time_delivery_minimal_hs + ':00');


			let time_cooked_min_milliseconds = strtotime(time_cooked_min) * 1000;
			if (time_cooked_default_milliseconds < time_cooked_min_milliseconds)
				time_cooked_default_milliseconds = time_cooked_min_milliseconds;

			let time_delivery_min_milliseconds = strtotime(time_delivery_min) * 1000;
			if (time_delivery_about_milliseconds < time_delivery_min_milliseconds)
				time_delivery_about_milliseconds = time_delivery_min_milliseconds;


			// console.log(time_cooked_minimal_hs, time_cooked_min, time_cooked_min_milliseconds, time_cooked_default_milliseconds);


			let time_cooked_default = orderDateTime(time_cooked_default_milliseconds);//date("Y-m-d H:i:00", new Date(time_cooked_default_milliseconds));

			// console.log('time_cooked_default', time_cooked_default);


			if (id) {

				res.data.startEdited = startEdited;
				res.data.time_cooked_default = time_cooked_default;
				res.data.time_cooked_minimal_hs = time_cooked_minimal_hs;
				res.data.time_cooked_min_milliseconds = time_cooked_min_milliseconds;
				res.data.time_delivery_min_milliseconds = time_delivery_min_milliseconds;

				setOrder(res.data);

				// console.log(res.updateOrdersList);
				if (GUI.socket && res.updateOrdersList) {
					console.log('emit updateOrdersList');
					GUI.socket.emit('updateOrdersList', {userId: user.id, orderId: res.data.id});
					GUI.socket.emit('orderUpdated', {
						userId: user.id,
						orderId: res.data.id,
						userRole: user.role,
						userName: user.name
					});
				}

			} else {

				let newOrder = {
					uniqId: uniqid(),
					time_cooked: time_cooked_default,
					time_cooked_default: time_cooked_default,
					time_cooked_minimal_hs: time_cooked_minimal_hs,
					time_cooked_min_milliseconds: time_cooked_min_milliseconds,
					time_delivery_minimal_hs: time_delivery_minimal_hs,
					time_delivery_min_milliseconds: time_delivery_min_milliseconds,
					time_delivery_about: date("Y-m-d H:i:00", new Date(time_delivery_about_milliseconds)),
					time_cooked_plus: time_cooked_plus,
					time_delivery_plus: time_delivery_plus,
					time_delivery: "",
					payment: 0,
					startEdited: startEdited,//время когда начал править заказ, все считаем от него
					status: 0,
					order_cost: 0,
					price_courier: 0,
					courier_id: 0,

				};
				newOrder.time_delivery = newOrder.time_delivery_about;
				// console.log('newOrder.time_delivery = time_delivery_about', newOrder.time_delivery);

				if (DEBUG) {
					newOrder['phone'] = '7917579551'
					newOrder['address'] = {
						// city: 'СНТ Родник',
						// city: 'Зеленоград',
						city: '',
						street: 'ул Свободы Слова',
						house: '153',
						podezd: 2,
						domofon: '-',
						floor: 4,
						flat: 87
					};
				}

				const uaData = {
					appVersion: navigator.userAgent ?? '',
					os: navigator.oscpu ?? '',
					localTime: new Date().toString()
				}
				// console.dir(newOrder);

				apiSend(
					API_URL,
					{
						action: 'userCreateOrder',
						userId: user.id,
						data: {
							...newOrder,
							ua: uaData
						}
					},
					(res) => {
						// console.log(res);
						if (!res.isset)
							newOrder = null;

						setOrder(newOrder);
					}
				);
				// console.dir(newOrder);


			}
		},
		actions
	);
};
const OrdersEditContainer = props => {

	const {user, actions, GUI} = props;

	let {id = 0} = useParams();
	id = parseInt(id);

	const [order, setOrder] = useState(null);
	const [orderPaymentList, setOrderPaymentList] = useState([]);
	const [orderStatusList, setOrderStatusList] = useState(null);
	const [clients, setClients] = useState(null);
	const [couriers, setCouriers] = useState(null);
	const [managers, setManagers] = useState(null);

	const [formErrors, setFormErrors] = useState({});

	const orderStatus = order ? parseInt(order.status) : 0;

	const isManager = in_array(user.role, rolesManager);
	const isClient = in_array(user.role, ['client']);
	const isCourier = in_array(user.role, ['courier']);


	let time_cooked_minimal_hs = (GUI.settings && GUI.settings.time_cooked_minimal)
		? GUI.settings.time_cooked_minimal : '00:00';
	let time_delivery_minimal_hs = (GUI.settings && GUI.settings.time_delivery_minimal)
		? GUI.settings.time_delivery_minimal : '00:00';
	let time_cooked_plus = (GUI.settings && GUI.settings.time_cooked_plus)
		? parseInt(GUI.settings.time_cooked_plus) * 60 * 1000
		: TIME_2_COOK;
	let time_delivery_plus = (GUI.settings && GUI.settings.time_delivery_plus)
		? parseInt(GUI.settings.time_delivery_plus) * 60 * 1000
		: TIME_2_DELIVERY;
	let time_between_cooked_delivery = (GUI.settings && GUI.settings.time_between_cooked_delivery)
		? parseInt(GUI.settings.time_between_cooked_delivery) * 60 * 1000
		: TIME_BETWEEN_COOKED_DELIVERY;


	const getOrdersParams = {
		user: user,
		GUI: GUI,
		actions: actions,
		setOrderPaymentList: setOrderPaymentList,
		setOrderStatusList: setOrderStatusList,
		setCouriers: setCouriers,
		setClients: setClients,
		setManagers: setManagers,
		setOrder: setOrder,
		time_cooked_minimal_hs: time_cooked_minimal_hs,
		time_delivery_minimal_hs: time_delivery_minimal_hs,
		time_cooked_plus: time_cooked_plus,
		time_delivery_plus: time_delivery_plus
	};


	// const updateListener = res => {
	// 	console.log('Socket updateListener', res);
	//
	// 	if (id && res.orderId && parseInt(id) === parseInt(res.orderId)) {
	// 		if (parseInt(user.id) !== parseInt(res.userId)) {
	// 			// console.log();
	// 			getOrder().then(null);
	// 			toastr.info('Внимание', 'Данный заказ был обновлен пользователем ' + res.userName)
	// 		}
	// 	}
	// }
	const updateListener = useCallback(res => {
		console.log('Socket updateListener', res);

		if (id && res.orderId && parseInt(id) === parseInt(res.orderId)) {
			if (parseInt(user.id) !== parseInt(res.userId)) {
				console.log('go refresh order');
				getOrder(res.orderId, getOrdersParams).then(null);
				toastr.info('Внимание', 'Данный заказ был обновлен пользователем ' + res.userName)
			}
		}
	}, [id, getOrdersParams]);


	useEffect(() => {

			let isSubscribe = true;

			if (isSubscribe) {
				getOrder(id, getOrdersParams).then(r => {
				});
			}

			if (GUI.socket) {

				// if (id)
				// 	GUI.socket.emit('orderOpen', {orderId: id});

				if (in_array(user.role, rolesManager)) {
					GUI.socket.on('orderOpenManager', res => {
						console.log('updateOrdersList', res);
					});
				}
				GUI.socket.on('orderUpdated', updateListener);
			}

			return () => {
				isSubscribe = false
				if (GUI.socket)
					GUI.socket.off('orderUpdated', updateListener);
			}

		},
		[id, GUI.socket]
	);

	const doClose = () => {
		// props.history.push(MENU_URLS.orders);

		if (order && order.lock_id === user.id) {
			apiSend(
				API_URL,
				{
					action: 'unlockOrder',
					userId: user.id,
					id: order.id
				},
				(res) => {
					props.history.push(MENU_URLS.orders);
				},
				actions
			);
		}

		props.history.push(MENU_URLS.orders);
	}

	const onClose = () => {
		if (order && order.changed) {
			toastr.confirm(
				'Внимание! Введеные вами данные не сохранятся. Прекратить редактирование заказа?',
				{
					okText: 'Да',
					cancelText: 'Нет',
					onOk: async () => {
						doClose();
					}
				}
			);
		} else
			doClose();
	}
	// console.log('OrdersEditContainer RENDER');

	let saveOrderStart = false;
	const saveOrder = (isRedirect, checkFields = true, _order = null) => {
		if (saveOrderStart)
			return;

		// console.clear();

		const CHECK_TIMES = false;

		// const saveData = _order !== null ? _order : {...order};

		// _order = _order || {...order};

		// console.dir(order);
		let saveData = {};

		let _isClient = isClient;

		const _isNew = id === 0;
		// console.log('_isNew', _isNew, 'isManager', isManager);

		//когда новый заказ создает менеджер, то надо учитывать все данные заказа
		if (id === 0 && isManager)
			_isClient = true;


		if (_isClient) {
			saveData = _order !== null ? _order : {...order};

		} else if (isManager) {

			saveData = {
				id: id,
				time_cooked: order.time_cooked,
				time_delivery: order.time_delivery,
				courier_id: order.courier_id,
				client_id: order.client_id,
				order_cost: order.order_cost,
				price_courier: order.price_courier,
				commentm: order.commentm
			};

			if (_order && typeof _order.status !== 'undefined')
				saveData.status = _order.status;
			else if (user.role === 'admin')
				saveData.status = order.status;

		} else if (isCourier) {
			saveData = {
				id: id,
				status: _order.status
			}
		}

		if (saveData.startEdited)
			saveData.start_edited_dt = orderDateTime(saveData.startEdited)
		// console.log('isClient: ' + isClient, '/ isManager: ' + isManager);
		// console.dir(saveData);

		const errors = {};

		// checkFields = false;
		// console.log(isManager, saveData.courier_id, saveData.order_cost);
		console.dir(saveData);

		if (!saveData)
			toastr.error('Ошибка', 'Нет данных о заказе');
		else if (checkFields) {

			const {
				//время С/ПО
				is_time_from_to = false
			} = saveData;

			console.log('time_delivery', saveData.time_delivery);
			// let start_edited_msec = Date.now() - new Date().getSeconds() * 1000;//saveData.startEdited * 1000;
			let start_edited_msec = saveData.startEdited * 1000;
			if (start_edited_msec < (Date.now() - ORDER_DIFF_START_AND_SAVE_TIME))
				start_edited_msec = (Date.now() - ORDER_DIFF_START_AND_SAVE_TIME);
			console.log('start time', start_edited_msec, orderDateTime(start_edited_msec));
			let time_cooked_unix = strtotime(saveData.time_cooked);
			let time_cooked_msec = time_cooked_unix * 1000;
			let time_delivery_unix = strtotime(saveData.time_delivery);
			let time_delivery_msec = time_delivery_unix * 1000;

			let time_delivery_max_msec = strtotime(saveData.time_delivery_max) * 1000;

			if (_isClient) {
				if (!saveData.phone)
					errors.phone = MESSAGES.fieldEmpty;
				//поля адреса
				if (!saveData.address || !saveData.address.city)
					errors.city = MESSAGES.fieldEmpty;
				if (!saveData.address || !saveData.address.house)
					errors.house = MESSAGES.fieldEmpty;
				if (!saveData.address || !saveData.address.podezd)
					errors.podezd = MESSAGES.fieldEmpty;
				if (!saveData.address || !saveData.address.domofon)
					errors.domofon = MESSAGES.fieldEmpty;
				if (!saveData.address || !saveData.address.floor)
					errors.floor = MESSAGES.fieldEmpty;
				if (!saveData.address || !saveData.address.flat)
					errors.flat = MESSAGES.fieldEmpty;


				// console.log(saveData);
				var priceOrderRequired = true;
				orderPaymentList.map(pay => {
					if (parseInt(saveData.payment) == parseInt(pay.id) && !pay.showPrice)
						priceOrderRequired = false;

					return null;
				});
				if (priceOrderRequired && saveData.price_order === undefined || saveData.price_order === '')
					errors.price_order = MESSAGES.fieldEmpty;

				//проверка времени
				let tMin = '';

				if (CHECK_TIMES && saveData.check_time_delivery) {
					if (!saveData.time_delivery || saveData.time_delivery == '__:__' || saveData.time_delivery === '')
						errors.time_delivery = MESSAGES.fieldEmpty;


					if (saveData.time_delivery) {


						/*
						 * доставка с/по
						 */
						if (is_time_from_to === true) {
							// console.log(time_delivery_max_msec);
							let tdErrorTimeMsec = 0;
							let tdMaxErrorTimeMsec = 0;

							if (time_delivery_msec < saveData.time_delivery_min_milliseconds)
								tdErrorTimeMsec = saveData.time_delivery_min_milliseconds;

							//если время доставки меньше чем текущее + разница готовка/доставка
							if (time_delivery_msec < (start_edited_msec + time_between_cooked_delivery))
								tdErrorTimeMsec = start_edited_msec + time_between_cooked_delivery;

							//если макс. вермя доставки меньше чем щас + время на доставка
							if (time_delivery_max_msec < (start_edited_msec + time_delivery_plus))
								tdMaxErrorTimeMsec = start_edited_msec + time_delivery_plus;

							//если макс. время меньше чем мин. + разница
							if (time_delivery_max_msec < (time_delivery_msec + time_between_cooked_delivery))
								tdMaxErrorTimeMsec = time_delivery_msec + time_between_cooked_delivery;

							//если макс время меньше чем мин.
							if (time_delivery_max_msec <= time_delivery_msec)
								tdMaxErrorTimeMsec = time_delivery_msec + time_cooked_plus;


							if (tdErrorTimeMsec) {
								errors.time_delivery = 'Время не может быть меньше '
									+ date('H:i', new Date(tdErrorTimeMsec));
								if (DEBUG || DEBUG_TEST)
									errors.time_delivery += '(01)';
							}
							if (tdMaxErrorTimeMsec) {
								errors.time_delivery_max = 'Время не может быть меньше '
									+ date('H:i', new Date(tdMaxErrorTimeMsec));

								if (DEBUG || DEBUG_TEST)
									errors.time_delivery_max += '(02)';
							}

						}
						/*
						 другое время
						 */
						else {
							// console.log('time_delivery_exact', saveData.time_delivery_exact);
							tMin = checkOrderTime(
								saveData.time_delivery,
								saveData.time_delivery_exact ? time_delivery_plus : 0,
								start_edited_msec,
								saveData.time_delivery_min_milliseconds
							);

							// console.log('tMin', tMin);
							if (tMin !== '') {
								errors.time_delivery = 'Время не может быть меньше ' + tMin;
								if (DEBUG || DEBUG_TEST)
									errors.time_delivery += '(11)';
								tMin = '';
							} else if (tMin == '' && isClient
								&& time_delivery_msec < (time_cooked_msec + time_between_cooked_delivery)) {

								errors.time_delivery = '(Время не может быть меньше '
									+ date('H:i', new Date((time_cooked_msec + time_between_cooked_delivery)));

								if (DEBUG || DEBUG_TEST)
									errors.time_delivery += '(12)';
							}

						}

					}
				}

				if (CHECK_TIMES && saveData.check_time_delivery_max) {
					if (saveData.time_delivery_max == '__:__' || saveData.time_delivery_max === '')
						errors.time_delivery_max = MESSAGES.fieldEmpty;
					// else if ((tMin = checkOrderTime(saveData.time_delivery_max, time_delivery_plus, order.startEdited * 1000, saveData.time_delivery_min_milliseconds)) !== '') {
					// 	errors.time_delivery_max = '(31) Время не может быть меньше ' + tMin;
					// 	tMin = '';
					// }
				}


				if (CHECK_TIMES && !is_time_from_to && saveData.check_time_cooked) {
					let time_cooked_msec = strtotime(saveData.time_cooked) * 1000;

					// console.log('pre check_time_cooked', saveData.time_cooked_min_milliseconds, saveData.time_cooked);
					if (time_cooked_msec < start_edited_msec + time_cooked_plus) {

						errors.time_cooked = 'Время не может быть меньше '
							+ date('H:i', new Date(start_edited_msec + time_cooked_plus));
						if (DEBUG || DEBUG_TEST)
							errors.time_cooked += '(33)';
					} else if (time_cooked_msec < saveData.time_cooked_min_milliseconds) {
						errors.time_cooked = 'Время не может быть раньше чем: ' + saveData.time_cooked_minimal_hs;
						if (DEBUG || DEBUG_TEST)
							errors.time_cooked += '(32)';
					}
				}
			}


			if (isManager) {

				if (saveData.order_cost === undefined || saveData.order_cost === '')
					errors.order_cost = MESSAGES.fieldEmpty;
				if (saveData.price_courier === undefined || saveData.price_courier === '')
					errors.price_courier = MESSAGES.fieldEmpty;

				if (!parseInt(saveData.client_id))
					errors.client_id = 'Клиент не выбран';

				if (parseInt(saveData.courier_id) > 0) {
					if (!parseInt(saveData.order_cost))
						errors.order_cost = MESSAGES.fieldEmpty;
					if (!parseInt(saveData.price_courier))
						errors.price_courier = MESSAGES.fieldEmpty;
				}


				if (!_isNew) {
					// console.log('XXXXXXX', 'time_cooked_unix', time_cooked_unix, 'time_delivery_unix', time_delivery_unix);
					if (time_delivery_unix < time_cooked_unix)
						errors.time_cooked = '(41) Время не может быть меньше ' + (
							date('H:i', new Date(time_cooked_unix * 1000))
						);
					if (time_cooked_unix > time_delivery_unix)
						errors.time_cooked = '(42) Время не может быть больше ' + (
							date('H:i', new Date(time_delivery_unix * 1000))
						);
				}
			}
		}
		// console.log(saveData.order_cost);
		// console.dir(errors);
		// console.dir(saveData);

		// return;


		if (Object.values(errors).length) {
			console.log('order save has errors');
			// console.error(errors);
			setFormErrors(errors);
		} else {
			// return;
			console.log('order save before send save');

			saveOrderStart = true;

			apiSend(
				API_URL,
				{
					action: 'saveOrder',
					userId: user.id,
					id: id,
					order: saveData
				},
				(res) => {

					saveOrderStart = false;

					if (res.formErrors) {
						setFormErrors(res.formErrors);
					} else {

						if (GUI.socket) {
							let socketData = {
								orderId: res.orderId,
								userId: user.id,
								userRole: user.role,
								userName: user.name,
							};
							GUI.socket.emit('updateOrdersList', socketData);
							GUI.socket.emit('orderUpdated', socketData);
							// if (!id)
							// 	GUI.socket.emit('createOrder', socketData);
							// else
							// 	GUI.socket.emit('editOrder', socketData);
						}

						if (isRedirect) {
							doClose();
						} else {
							toastr.info('',
								'Заказ сохранен '
								+ (!id ? 'под номером №' + res.orderId : '')
								+ (res.msg ? res.msg : '')
							);
							setOrder(prev => ({
								...prev,
								id: res.orderId
							}));
							// props.history.push('/orders/edit/' + res.orderId);
						}
					}
				},
				actions
			);
		}
	}

	let isEdit = true,
		isLocked = false,
		winHeader = null;

	if (id && order && user && clients) {
		isEdit = canOrderEdit(order, user);

		// isLocked = orderIsLocked(order, user);

		if (isManager && clients[order.client_id]) {
			winHeader = clients[order.client_id].name;
		}
	}

	if (!id && order && (DEBUG || DEBUG_TEST))
		winHeader = orderDateTime(order.startEdited);


	let actionButtons = null;


	if (isManager || isClient) {
		actionButtons = (
			<>
				<div className="col-2-3">
					{
						(order && !isLocked) ?
							<>
								<Button
									type="apply"
									onClick={() => saveOrder(isClient)}
									ico={"save"}
								/>
								{
									isManager
										? <Button
											type="save"
											onClick={() => saveOrder(true)} ico={"save"}
										/> : null
								}

							</>
							: null
					}

					<Button type="close"
							onClick={onClose}
							ico={"close"}
					/>
				</div>
				<div className="col-3 _tar">
					{
						(isManager && order && parseInt(order.status) === 10)
							? <Button
								cls="-grey"
								// type={"delete"}
								title={'Вне зоны доставки'}
								ico={"ban"}
								onClick={e => {
									saveOrder(
										true,
										false,
										{
											...order,
											status: ORDER_STATUS_OUT_OF_ZONE
										});
								}}
							/>
							: null
					}
					{
						//кнопка отмены клиентом ДО статуса "забран курьером"
						(isClient && id && order && parseInt(order.status) < 32)
							? <Button
								// cls="-grey"
								type={"delete"}
								title={'Отменить заказ'}
								// ico={"trash"}
								onClick={e => {
									saveOrder(
										true,
										false,
										{
											...order,
											status: ORDER_STATUS_CANCELLED
										});
								}}
							/>
							: null
					}

				</div>
			</>
		);
	} else if (isCourier) {

		let btnData;
		switch (orderStatus) {
			case ORDER_STATUS_IN_WORK:
				btnData = {
					title: 'Принять заказ',
					newStatus: 30
				};
				break;
			case 30:
				btnData = {
					title: 'Прибыл за заказом',
					newStatus: 31
				};
				break;
			case 31:
				btnData = {
					title: 'Забрал заказ',
					newStatus: 32
				};
				break;
			case 32:
				btnData = {
					title: 'Заказ доставлен',
					newStatus: 33
				};
				break;
			default:
				btnData = null;
				// btnData = {
				// 	title: 'Действие не определено',
				// 	newStatus: null
				// };
				break;
		}


		if (btnData)
			actionButtons = (
				<>
					<Button type="save"
							ico={"check-square-o"}
							title={btnData.title}
							onClick={() => {

								if (btnData.newStatus) {
									setOrder(prev => ({
										...prev,
										status: btnData.newStatus
									}))
									saveOrder(false, false, {...order, status: btnData.newStatus});
								}
							}}
					/>


				</>
			);
	}

	//курьер не может создавать заказы
	if (id === 0 && isCourier)
		return <Redirect to={"/"}/>

	if (id === 0 && order && order.id)
		return <Redirect to={"/orders/edit/" + order.id}/>


	return (
		<>

			<ContentHeader
				title={id ? "Заказ №"
					+ id
					+ ((isManager && order) ? ` (${order.created})` : '')
					: "Новый заказ"
				}
				showDates={false}
				parent={[{
					url: '/',
					name: MENU_TITLE.orders.many
				}]}

			/>
			<Win
				onClose={onClose}
				header={winHeader}
				footer={
					<div className={"win-ftr-btns row"}>
						{actionButtons}
					</div>
				}

			>
				{
					(order && !isLocked) ? <OrderEdit
						user={user}
						settings={GUI.settings}
						isEdit={isEdit}
						isNew={id === 0}
						data={order}
						setData={setOrder}

						orderPaymentList={orderPaymentList}
						statusList={orderStatusList}
						couriers={couriers}
						clients={clients}
						managers={managers}

						formErrors={formErrors}
						setFormErrors={setFormErrors}

						time_cooked_plus={time_cooked_plus}
						time_delivery_plus={time_delivery_plus}
						time_between_cooked_delivery={time_between_cooked_delivery}

						isManager={isManager}
						isClient={isClient}
						isCourier={isCourier}
					/> : null
				}

				{
					isLocked ? <div className="">
						<h1>Заказ заблокирован пользователем {order.lockName}</h1>
					</div> : null
				}

			</Win>
			<div style={{color: '#ccc'}}>
			{
				order ? (order.uniqId ?? '-') : null
			}
			</div>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		hideLoading, showLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersEditContainer);
