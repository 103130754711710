import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import date from "php-date";
import {bindActionCreators} from "redux";
import {setCalendarDates} from "actions/GUIActions";
import * as strtotime from "strtotime";
import cn from "classnames";
import {pdate} from "utils/func";
import {toastr} from "react-redux-toastr";


const formatInputDate = idate => {
	if (Number.isInteger(idate))
		return pdate('Y-m-d', idate);
	else
		return date('Y-m-d', new Date(idate));
}

const ContentHeader = (props) => {

	const {title = '', parent = [], titleEnds = '',
		showDates = false,
		GUI, actions,
		datesAction,
		panelContent = null
	} = props;
	// console.log(GUI.calendarDates.start);

	const [dateStart, setDateStart] = useState(
		strtotime(pdate('Y-m-d 00:00:00', GUI.calendarDates.start)) * 1000);
	const [dateEnd, setDateEnd] = useState(
		strtotime(pdate('Y-m-d 23:59:59', GUI.calendarDates.end)) * 1000);


	const dateDiff = () => {
		// console.log(dateStart, dateEnd, dateStart > dateEnd);
		// console.log(pdate('d.m.Y H:i:s', dateStart), pdate('d.m.Y H:i:s', dateEnd));
		if (dateStart > dateEnd) {
			// alert('Выбран неверный промежуток времени');
			toastr.error('Ошибка', 'Выбран неверный промежуток времени', {timeOut: 0});
			return false;
		}

		return true;
	};
	// console.dir(navigator.appVersion);
	// const isSafari = navigator.appVersion.indexOf('AppleWebKit') !== -1;
	// console.log('safari', isSafari);


	//даты для печати
	let titlePrintDates = '';
	// if (dateStart !== dateEnd )
	// 	titlePrintDates = 'с '
	// 		+ date('d.m.Y', GUI.calendarDates.start)
	// 		+ ' по ' + date('d.m.Y', GUI.calendarDates.end);
	// else
	// 	titlePrintDates = `за ` + date('d.m.Y', GUI.calendarDates.start);


	let panel = [];//panelContent;
	panel.push(panelContent);
	let datesSelect = null;
	if (showDates) {
		datesSelect = (
			<div className={"panel-top-dates-wrap flx -alc -sb"}>
				<div className="flx -alc -dates">
					<span className={"-viv"}>Вывести</span>
					&nbsp; c &nbsp;
					<div className="panel-top-dates-item">
						<input
							type="date"
							name="start"
							value={formatInputDate(dateStart)}
							onChange={(e) => {
								console.log(e.target.value, strtotime(e.target.value));
								let v = strtotime(e.target.value + ' 00:00:00') * 1000;
								// console.log(v);
								setDateStart(v);
								actions.setCalendarDates({
									...GUI.calendarDates,
									start: new Date(v)
								});

							}}
						/>
					</div>
					&nbsp; по  &nbsp;
					<div className="panel-top-dates-item">
						<input
							type="date"
							name="end"
							value={formatInputDate(dateEnd)}
							onChange={(e) => {
								let v = strtotime(e.target.value + ' 23:59:59') * 1000;
								setDateEnd(v);
								actions.setCalendarDates({
									...GUI.calendarDates,
									end: new Date(v)
								});
							}}
						/>
					</div>
				</div>
				<div className="btn-wrap">
					<div className={cn("btn", {disabled: false})} onClick={() => {
						if (dateDiff() && typeof datesAction == 'function') {
							// console.log(dateStart, dateEnd);
							// let newDates = {
							// 	...GUI.calendarDates,
							// 	start: new Date(dateStart),
							// 	end: new Date(dateEnd)
							// };
							// actions.setCalendarDates(newDates);

							datesAction();

						}
					}}>
						Показать
					</div>
				</div>
			</div>
		);
		panel.push(
			<Fragment key={"panel-top-dates-wrap"}>
				{datesSelect}
			</Fragment>
		)
	}



	return (
		<div className="content-header  flx -sb -alc">
			<div className="content-title">
				{parent.map((item, i) => {
					return <NavLink to={item.url} key={i}><span>{item.name}</span></NavLink>
				})}
				<h1>{title} <span className={"print-only"}>{titlePrintDates}</span>{titleEnds}</h1>
			</div>

			{
				panel.length ? (
					<div className="panel-top flx -e -alc no-print">{panel}</div>
				) : null
			}
		</div>
	);
};

ContentHeader.propTypes = {
	title: PropTypes.oneOfType([PropTypes.element,PropTypes.string]).isRequired,
	parent: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	showDates: PropTypes.bool
	// children: PropTypes.node.isRequired,
	// footer: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
	// winClass: PropTypes.string,
	// onClose: PropTypes.func,
};

const mapStateToProps = store => ({
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarDates
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
