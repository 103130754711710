import React, {useState, useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {rolesModerator, MENU, MENU_TITLE, MESSAGES} from "utils/config";
import {toastr} from "react-redux-toastr";
import {ContentHeader, Button, FormItem} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import date from "php-date";
import * as strtotime from "strtotime";




const LogsContainer = props => {

	const {user, GUI, actions} = props;

	const logTypeSelectedOption = useRef('all');
	const subTypeSelectedOption = useRef('all');

	const [logs, setLogs] = useState([]);
	const [logSubTypes, setLogSubTypes] = useState([]);

	let apiActionProcess = false;

	const getLogs = async (isSubscribe = true, calDates = null, search = '') => {

		if (!isSubscribe)
			return;



		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('logs.php', {
				action: 'get',
				user: user,
				dates: calDates ? calDates : GUI.calendarDates,
				search: search,
				logType: logTypeSelectedOption.current,
				subType: subTypeSelectedOption.current
			});

			apiAction(res, (data) => {
				setLogs(data.logs);
				setLogSubTypes(data.subTypes);
			}, () => apiActionProcess = false);

			actions.hideLoading();
		}
	};

	useEffect(() => {
			let isSubscribe = true;
			getLogs(isSubscribe).then(r => {});
			// actions.setCalendarSelectDateAction(onCalendarSelectDate);

			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				// actions.setCalendarSelectDateAction(null);

			}
		},
		[]
	);

	const columnsTitle = {
		date: 'Дата',
		txt: 'Текст',
		ltype: 'Тип',
		stype: 'Подтип'
	};

	const logTypes = {
		0: 'INFO',
		5: 'WARNING',
		10: 'ERROR',
		15: 'EXCEPTION'
	};
	let panel;

	if (logSubTypes.length) {

		const stOptions = {
			all: {
				value: 'Все подтипы'
			}
		};
		logSubTypes.map(s => {

			stOptions[s] = {
				value: s === '' ? 'не задан подтип' : s
			}

			return null;
		});
		const logTypesOptions = {
			all: {
				value: 'Все типы'
			}
		};
		for (const i in logTypes) {
			logTypesOptions[i] = {
				value: logTypes[i]
			};
		}

		panel = <div className="panel-top flx -e mt20m no-print">
			<div className="flx -e mt20">
				<FormItem
					label={"Тип записи"}
					name={"logType"}
					fieldType={"select"}
					options={logTypesOptions}
					changeAction={(e) => {
						logTypeSelectedOption.current = e.target.value;
						getLogs();
					}}
					defVal={logTypeSelectedOption.current}
				/>
				&nbsp;&nbsp;&nbsp;
				<FormItem
					label={"Подтип"}
					name={"subType"}
					fieldType={"select"}
					options={stOptions}
					changeAction={(e) => {
						subTypeSelectedOption.current = e.target.value;
						getLogs();
						// console.log(e.target.value);
					}}
					defVal={subTypeSelectedOption.current}
				/>
			</div>
		</div>;
	}


	return(
		<>
			<ContentHeader
				title={MENU_TITLE.logs.many}
				showDates={false}
				// datesAction={getLogs}
			/>
			{panel}
			{
				logs.length ? (
					<div className="logs-tbl tbl dark hdr-cntr mt20 -bordered">
						<div className="thead">
							<ul className="tr">
								<li className="td col-date">
									{columnsTitle.date}
								</li>
								<li className="td col-txt">
									{columnsTitle.txt}
								</li>
								<li className="td col-ltype">
									{columnsTitle.ltype}
								</li>
								<li className="td col-stype">
									{columnsTitle.stype}
								</li>
							</ul>
						</div>
						<div className="tbody">
							{logs.map((item, i) => {
								return (
									<ul className={"tr log-" + item.logType} key={"loi-itm-" + i}>
										<li className="td col-date">
											{date('d.m.Y H:i:s', new Date(strtotime(item.ttime) * 1000))}
										</li>
										<li className={"td col-txt" + ((item.txt && item.txt.length > 100) ? ' -long' : '')}>
											{item.txt ? item.txt : ''}
											<p className={"path"}>{item.path}</p>
										</li>
										<li className="td col-ltype">
											{
												logTypes[item.logType] ? logTypes[item.logType] : 'undefined'
											}
										</li>
										<li className="td col-stype">
											{item.subType}
										</li>
									</ul>
								)
							})}
						</div>
					</div>
				) : null
			}

		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading,
		// setCalendarSelectDateAction, setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsContainer);