import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import date from "php-date";
import * as strtotime from "strtotime";
import {canOrderEdit, canOrderOpen} from "./utils";
import {orderPrintTime, ORDER_TIME_LABEL_EXACT} from "./utils";
import cn from "classnames";
import {format_phone, pdate} from "utils/func";


const OrdersListItem = props => {

	const {
		user,
		order,
		settings,
		statusList, couriersList, clientsList, managersList,
		isManager, isClient, isCourier,
		columnTitle
	} = props;

	const {status} = order;

	let trStyle = null;


	if (settings.orderStatusColors && settings.orderStatusColors[order.status])
		trStyle = {
			backgroundColor: settings.orderStatusColors[order.status].bgr,
			color: settings.orderStatusColors[order.status].txt
		}

	const [showManagerInfo, setShowManagerInfo] = useState(false);
	const [showClientInfo, setShowClientInfo] = useState(false);
	// const canEdit = canOrderEdit(order, user);

	const canOpen = canOrderOpen(order, user);
	// if (order.id == 24)
	// 	console.dir(order.time_delivery_exact);
	// console.log(order.id, canOpen);

	let timeDeliveryPrint = orderPrintTime(order.time_delivery);
	if (parseInt(order.time_delivery_exact))
		timeDeliveryPrint = ORDER_TIME_LABEL_EXACT + ' ' + timeDeliveryPrint;
	else if (order.time_delivery !== order.time_delivery_max)
		timeDeliveryPrint = 'с '
		+ orderPrintTime(order.time_delivery)
		+ ' по '
		+ orderPrintTime(order.time_delivery_max)


	let address = [];

	if (order.address) {
		if (order.address.city)
			address.push(<p key={"ol-adr-city-" + order.id}>Нас. пункт: {order.address.city}</p>);
		if (order.address.street)
			address.push(<p key={"ol-adr-street-" + order.id}>Улица: {order.address.street}</p>);

		if (order.address.house)
			address.push(<p key={"ol-adr-house-" + order.id}>Дом {order.address.house}, подъезд {order.address.podezd}</p>);
		if (order.address.domofon)
			address.push(<p key={"ol-adr-domofon-" + order.id}>Домофон: {order.address.domofon}, этаж {order.address.floor}, кв. {order.address.flat}</p>);
	}

	return (
		<div
			className={"tr status-" + status}
			style={trStyle}
		>
			<div
				className="td col-n"
				// onClick={e => onOpen(order.id)}
			>
				<div className="td-title">
					{columnTitle.id}
				</div>
				{
					canOpen
						? <NavLink to={"/orders/edit/" + order.id}>{order.id}</NavLink>
						: <div>{order.id}</div>
				}


			</div>
			<div className="td col-created">
				<div className="td-title">
					{columnTitle.created}
				</div>
				{date('d.m.Y H:i', new Date(strtotime(order.created) * 1000))}
			</div>
			<div className="td col-status">
				<div className="td-title">
					{columnTitle.status}
				</div>
				{
					statusList[status] ? statusList[status].name : 'UNDEFINED'
				}
				{
					order.statusDate
						? <p>{pdate('d.m.Y H:i', order.statusDate * 1000)}</p>
						: null
				}

				{/*({status})*/}
				{/*{settings.orderStatusColors[order.status].txt}*/}
			</div>
			{
				(isManager || isCourier) ?
					<div
						className="td col-client"
						onClick={e => setShowClientInfo(!showClientInfo)}
					>
						<div className="td-title">
							{columnTitle.client}
						</div>
					{
						clientsList[order.client_id]
							? (
								<>
								<p>{clientsList[order.client_id].name}</p>
								<p>{clientsList[order.client_id].address}</p>
								</>
							)
							: null
					}
						<div className={cn("mt10", {hidden: !showClientInfo})}>
							{
								clientsList[order.client_id]
									? <a href={"tel:+" + clientsList[order.client_id].phone}>
										{format_phone(clientsList[order.client_id].phone)}
									</a>
									: null
							}
						</div>

				</div>: null
			}
			<div className="td col-prepare">
				<div className="td-title">
					{columnTitle.time_cooked}
				</div>
				{orderPrintTime(order.time_cooked)}
			</div>
			<div className="td col-delivery">
				<div className="td-title">
					{columnTitle.time_delivery}
				</div>
				{timeDeliveryPrint}
			</div>

			<div
				className="td col-manager"
				onClick={e => setShowManagerInfo(!showManagerInfo)}
			>
				<div className="td-title">
					{columnTitle.manager}
				</div>

				{order.managerName}

				<div className={cn("mt10", {hidden: !showManagerInfo})}>
					{
						managersList[order.manager_id]
							? <a href={"tel:+" + managersList[order.manager_id].phone}>
								{format_phone(managersList[order.manager_id].phone)}
							</a>
							: null
					}
				</div>
				{
					order.commentm ? <div className="col-in-comment">
						Комментарий:<br /> {order.commentm}
					</div> : null
				}
			</div>
			{
				isCourier ? <div className="td col-tel">
					<div className="td-title">
						{columnTitle.phone}
					</div>
					<a href={"tel:+" + order.phone}>
						{format_phone(order.phone)}
					</a>
				</div> : null
			}
			<div className="td col-address" style={{whiteSpace: "pre-wrap"}}>
				<div className="td-title">
					{columnTitle.address}
				</div>
				{/*{order.address}*/}
				{/*{Object.values(order.address).join(', ')}*/}
				{address}
				{
					order.commentc ? <div className="col-in-comment">
						Комментарий:<br /> {order.commentc}
					</div> : null
				}
			</div>

			{
				!isCourier
					? <div className="td col-cur">
						<div className="td-title">
							{columnTitle.courier}
						</div>
						{
							(order.courier_id && couriersList[order.courier_id])
								? couriersList[order.courier_id].name
								: null
						}
					</div>
					: null
			}
			{
				isClient
					? <div className="td">
						<div className="td-title">
							{columnTitle.courierPhone}
						</div>
						{
							(order.courier_id && couriersList[order.courier_id])
								? <a href={"tel:+" + couriersList[order.courier_id].phone}>
									{format_phone(couriersList[order.courier_id].phone)}
								</a>
								: null
						}
					</div>
					: null
			}
		</div>
	)

};

export default OrdersListItem;
