import React, {useState, useEffect} from 'react';
// import {bindActionCreators} from "redux";
// import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";

import {apiSend} from "utils/func";
import MapContent from "components/map/MapContent";


const API_URL = 'orders.php';

const getCouriers = (userId, isSubscribe, setData) => {
	if (!isSubscribe)
		return;

	apiSend(
		API_URL,
		{
			action: 'getMapInfo',
			userId: userId,
		},
		(res) => {

			console.dir(res.couriers[7]);

			let couriers = [];

			if (res.couriers) {
				Object.values(res.couriers).map(user => {

					let nameArr = user.name.trim().split(' ');
					// console.log(nameArr, nameArr[0][0]);
					// console.log(user.id, user.lastseen,  user.lat);
					// if (user.id == 7) {
					// 	console.dir(user);
					// }

					var cur = {
						id: user.id,
						name: user.name,
						icon: nameArr[0] + ' ' + nameArr[1][0].toUpperCase(),
						lat: user.lat ?? 0,
						lng: user.lng ?? 0,
						lastseen: user.lastseen ? Math.round(user.lastseen) : 0,
						orders: user.orders ? user.orders : []
					}
					// console.dir(cur);
					couriers.push(cur);

					return null;
				});

				// console.dir(couriers[0]);
			}

			setData({
				couriers: couriers,
				clients: res.clients,
				orderStatusList: res.orderStatusList
			});
		}).then(null);
}
const MapContainer = props => {

	const {user, GUI} = props;

	const [data, setData] = useState(null);

	// console.log('map container');

	useEffect(() => {

			let isSubscribe = true;

			getCouriers(user.id, isSubscribe, (res) => {
				if (isSubscribe)
					setData(res);
			});


			return () => {
				isSubscribe = false;
			}

		},
		[GUI.socket]
	);


	// setCouriers={setCouriers}
	if (data)
		return <MapContent
			data={data}
			GUI={GUI}
		/>;
	else
		return null;


};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

// const mapDispatchToProps = dispatch => ({
// 	actions: bindActionCreators({
// 		setCalendarSelectDateAction, sideBarToggle
// 	}, dispatch)
// });
export default connect(mapStateToProps, null)(MapContainer);
// export default MapContainer;
