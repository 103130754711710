import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import {
	UserListContainer, UserEditContainer
} from "containers/user";

const UserRouter = props => {

	return (
		<Switch>

			{/*<Route path="/user/role/:roleName" key={"users-by-role"}>*/}
			{/*	<UserListContainer {...props} />*/}
			{/*</Route>*/}

			<Route path="/user/edit/:id" exact>
				<UserEditContainer {...props} />
			</Route>

			<Route path="/user/profile_edit" exact>
				<UserEditContainer {...props} editSelf={true} />
			</Route>

			<Route path="/user/profile/:id" exact>
				<UserEditContainer {...props} edit={false} />
			</Route>
			<Route path="/user/:roleName?" exact key={"users-all"}>
				<UserListContainer {...props} />
			</Route>

			<Route component={Page404} />
		</Switch>
	)

};

export default UserRouter;
