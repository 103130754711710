import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
// import Api, {apiAction} from "utils/Api";
// import {rolesModerator} from "utils/config";
import {apiSend, in_array} from "utils/func";

import {ContentHeader, Button, Win} from "components/_common";
import * as Settings from "components/settings";
import {setSettingsOne} from "actions/GUIActions";
import {MENU_TITLE} from "utils/config";


const API_URL = 'settings.php';

const SettingsContainer = props => {

	const {user, GUI, actions} = props;


	const {settingsType = 'general'} = useParams();
	let settingsTypeResult = settingsType;

	// console.log(settingsType);

	const [settings, setSettings] = useState(null);
	const [savedSettings, setSavedSettings] = useState({});
	const [deleteSettings, setDeleteSettings] = useState([]);

	const [orderStatusList, setOrderStatusList] = useState(null);

	let apiActionProcess = false;
	const getSettings = async (isSubscribe = true, stype = '') => {

		if (!isSubscribe)
			return;

		apiSend(
			API_URL,
			{
				action: 'getSettings',
				target: 'settings',
				userId: user.id,
				stype: stype
			},
			(res) => {

				setOrderStatusList(res.orderStatusList || {});
				setSettings(res.settings);


			}
		)
	};
	useEffect(() => {
			let isSubscribe = true;
			getSettings(isSubscribe, settingsTypeResult).then(null);

			return () => {
				isSubscribe = false;
				setSettings(null);
			}
		},
		[]
	);

	const saveSettings = async (data = null) => {
		// console.clear();
		console.log('saveSettings', savedSettings);
		console.dir(data);
		let saveData = data ? data: savedSettings;
		apiSend(
			API_URL,
			{
				action: 'setSettings',
				userId: user.id,
				data: saveData,
				deleteSettings: deleteSettings
			},
			(res) => {

				if (GUI.socket) {

					GUI.socket.emit('updateSettings', saveData);
				}
				// else {
				//
				// }
				for (const key in savedSettings) {
					actions.setSettingsOne(key, savedSettings[key]);
				}
			}
		)

		// console.log(savedSettings);
		// console.log(data);
		// return;
		// if (!apiActionProcess) {
		// 	actions.showLoading();
		// 	apiActionProcess = true;
		//
		// 	const res = await Api.post('settings.php', {
		// 		action: 'setSettings',
		// 		user: user,
		// 		data: data ? data: savedSettings,
		// 		deleteSettings: deleteSettings
		// 	});
		//
		// 	apiAction(res, (data) => {
		// 		for (const key in savedSettings) {
		// 			actions.userSetSettings(key, savedSettings[key]);
		// 		}
		// 	}, () => {
		// 		apiActionProcess = false;
		// 		actions.hideLoading();
		//
		// 	});
		// }
	};

	if (!settings || typeof settings !== 'object')
		return null;

	let contentHeaderTitle = '',
		contentHeaderParent = [{url: '/settings', name: 'Настройки'}],
		content = '',
		fullContent = null;

	let winFooter = (
		<div className="win mt20">
			<div className="f _tac">
				<Button
					type="apply"
					onClick={() => saveSettings()}
					disabled={!Object.keys(savedSettings).length}
					// disabled={true}
				/>
			</div>
		</div>
	);


	switch(settingsTypeResult) {
		// case "calendar":
		// 	contentHeaderTitle = 'Календарь';
		// 	content=<Settings.Calendar
		// 		{...props}
		// 		// edit={in_array(user.role, rolesModerator)}
		// 		//
		// 		// settings={settings}
		// 		// doSaveSettings={saveSettings}
		// 		// savedSettings={savedSettings}
		// 		// setSavedSettings={setSavedSettings}
		// 		//
		// 		// setDeleteSettings={setDeleteSettings}
		// 	/>;
		// 	break;

		case "personal":
			contentHeaderTitle = 'Персональные';

			let initOrderStatusListBgr = {};
			// if (todoList)
			// 	for (let todo in todoList) {
			// 		let clr;
			//
			// 		if (todoList[todo].hideInTodo === undefined || !todoList[todo].hideInTodo) {
			// 			if (settings.task_list_bgr && settings.task_list_bgr[todo])
			// 				clr = settings.task_list_bgr[todo];
			// 			else
			// 				clr = '#ffffff';
			// 			// clr = '#FD7E14';
			//
			// 			initTodoListBgr[todo] = clr;
			// 		}
			// 	}
			// console.log(initTodoSelectColors);

			content = <Settings.Personal
				user={user}
				settings={settings}
				savedSettings={savedSettings}
				setSavedSettings={setSavedSettings}
			/>
			break;
		case "cities":
			contentHeaderTitle = MENU_TITLE.cities.many;
			if (settings)
				content = <Settings.Cities
					settings={settings}
					savedSettings={savedSettings}
					setSavedSettings={setSavedSettings}
				/>;
			break;
		default:
			contentHeaderTitle = 'Основные';
			if (settings)
				content = <Settings.General
					user={user}
					// setSettings={setSettings}
					settings={settings}
					// initTodoSelectColors={initTodoSelectColors}
					savedSettings={savedSettings}
					setSavedSettings={setSavedSettings}
					orderStatusList={orderStatusList}
				/>;
			break;
	}
	if (fullContent)
		return fullContent;

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
				showDates={false}
			/>
			{/*<Win*/}
			{/*	header={contentHeaderTitle}*/}
			{/*	footer={winFooter}*/}
			{/*>*/}
			{/*	{content}*/}
			{/*</Win>*/}
			{content}

			{winFooter}
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setSettingsOne
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);