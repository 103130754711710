import React, {useState} from 'react';
import {connect} from 'react-redux';
import TopbarUserinfo from "components/user/TopbarUserinfo";
import {NavLink} from "react-router-dom";
import {bindActionCreators} from "redux";
import {sideBarToggle, showStartDay} from "actions/GUIActions";
import {toastr} from "react-redux-toastr";
import {in_array} from "utils/func";
import {rolesManager} from "utils/config";


const TopbarContainer = (props) => {
	const {GUI, user, actions} = props;

	const [searchString, setSearchString] = useState('');
	const [notifications, setNotifications] = useState({
		newOrders: 0
	});

	const isManager = in_array(user.role, rolesManager);
	const onSearch = () => {

		if (searchString.trim() == '') {
			toastr.error('Ошибка', 'Введен пустой поисковый запрос');
			return;
		}
		if (GUI.searchAction)
			GUI.searchAction(searchString.trim());
	};
	const onEnterPressedCatcher = e => {
		if (e.key.toLowerCase() === 'enter')
			onSearch();
	};

	// const notifyListener = res => {
	// 	console.log('TOPBAR notify');
	// 	console.dir(res);
	// 	let ntf = {...notifications};
	// 	for (let k in res) {
	// 		ntf[ k ] = res[k];
	// 	}
	// 	setNotifications(ntf);
	// }

	// useEffect(() => {
		// if (GUI.socket)
		// 	GUI.socket.on('onNotify', notifyListener);
		//
		//
		// return () => {
		// 	if (GUI.socket)
		// 		GUI.socket.off('onNotify', notifyListener);
		// }
	// });


	let notificationsList = [];

	if (notifications.newOrders)
		notificationsList.push(
			<NavLink
				key={"not-orders"}
				to="/orders"
				title={"Есть новые заказы"}>
				<i className="fa fa-car"></i>
				<div className="count">
					{notifications.newOrders}
				</div>
			</NavLink>
		)


	return(
		<div className={"top-bar flx -alc no-print " + (!GUI.sideBarOpen ? 'full' : '')}>
			<div className="tb-left flx -alc">
				<div
					className="menu-bars"
					title={ (GUI.sideBarOpen  ? 'Уменьшить' : 'Увеличить') + ' главное меню'}
					onClick={() => actions.sideBarToggle(!GUI.sideBarOpen)}
				>
					<i className="fa fa-bars"></i>
				</div>
				<ul className="tb-menu flx -alc _z">
					{
						isManager
							? <li>
								<NavLink to="/settings#orders"
										 title={"Править базовое время приготовления и доставки"}
										 style={{fontSize: '24px'}}
								>
									<i className={"fa fa-clock-o"}></i>
								</NavLink>
							</li>
							: null
					}
				</ul>
				{
					(GUI.settings.debug_server_time != undefined && GUI.settings.debug_server_time !== '00:00')
					? <div className="clr-red">
						ВНИМАНИЕ!!! Серверное время изменено на {GUI.settings.debug_server_time}
					</div> : null
				}
				{/*showStartDay*/}
				{
					isManager
					? <i className="fa fa-paper-plane-o"
						 title={"Показать окно отправки уведомлений курьерам"}
						 onClick={() => {
							 actions.showStartDay(true);
						 }}
						></i>
						: null
				}

			</div>
			<div className={"tb-srch flx" + (!GUI.searchAction ? ' hidden' : '')}>
				<input
					type="text"
					name="srch"
					placeholder="Поиск"
					value={searchString}
					onChange={e => setSearchString(e.target.value)}
					onKeyDown={e => onEnterPressedCatcher(e)}
					onFocus={e => {
						setSearchString('');
					}}
				/>
				<button
					onClick={e => onSearch()}
				>
					<i className="fa fa-search"></i>
				</button>
				<i
					className="fa fa-close"
					onClick={e => setSearchString('')}
				></i>
			</div>
			<div className="tb-right flx -e -alc">
				<div className="tb-notifs flx">
					{notificationsList}
				</div>
				<TopbarUserinfo />
			</div>
		</div>
	)
};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		sideBarToggle, showStartDay
	}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);
