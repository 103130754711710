import React from "react";
import {toastr} from "react-redux-toastr";
import roundTo from "round-to";
import Api, {apiAction} from "utils/Api";
import {saveSync} from "save-file";
// import {NavLink} from "react-router-dom";
import date from "php-date";
import cn from "classnames";


export const in_array = (val, arr) => {

	return arr.indexOf(val) !== -1;
};
export const array_unset = (arr, val) => {
	const i = (typeof val === 'number') ? val : arr.indexOf(val);

	if(i > -1)
		arr.splice(i, 1);

	return arr;
};
export const is_empty = arr => {
	if (Array.isArray(arr))
		return !arr.length;
	if (typeof arr === 'object')
		return !Object.keys(arr).length;

	return true;
};
export const unixTime = () => {
	return Math.round((new Date()).getTime() / 1000);
};
export const formatDate = (dateSrc, toUnixtime = false, withHours = false) => {
	const date = new Date(dateSrc);

	const getFullDayMoth = (val) => {
		return val > 9 ? val : '0' + val;
	};

	if (toUnixtime)
		return Math.round(date.getTime() / 1000);

	let formatted = getFullDayMoth(date.getDate()) + '.' +getFullDayMoth((date.getMonth() + 1)) + '.' +date.getFullYear();

	if (withHours)
		formatted += ' ' + date.getHours() + ':' + date.getMinutes();

	return formatted;
};
export const getFullDayMoth = (val) => {
	return val > 9 ? val : '0' + val;
};
export const get_noun = (_num, form_for_1, form_for_2, form_for_5) => {
	const num = Math.abs(_num) % 100; // берем число по модулю и сбрасываем сотни (делим на 100, а остаток присваиваем переменной num)
	const num_x = num % 10; // сбрасываем десятки и записываем в новую переменную
	if (num > 10 && num < 20) // если число принадлежит отрезку [11;19]
		return form_for_5;
	if (num_x > 1 && num_x < 5) // иначе если число оканчивается на 2,3,4
		return form_for_2;
	if (num_x == 1) // иначе если оканчивается на 1
		return form_for_1;

	return form_for_5;
};

export const string2num = (value, roundDigits = 0) => {

	if (value === NaN)
		return 0;

	let newValue = value.replace(/,/g, '.');
	newValue.trim();

	newValue = !roundDigits ? parseInt(newValue) : parseFloat(newValue);
	newValue = newValue || 0;

	return roundTo(newValue, roundDigits);
};
export const getFileSize = (fsizeBytes, withPostfix = true) => {
	let unit = '', size = 0;
	if (fsizeBytes > 1024*1024) {
		size = Math.round(fsizeBytes/1024/1024);
		unit = 'Мб';
	}
	else if(fsizeBytes > 1024) {
		size = Math.round(fsizeBytes/1024);
		unit = 'Кб';
	}
	else {
		size = fsizeBytes;
		unit = 'байт';
	}

	return size + (withPostfix ? ' ' + unit : '');
};

export const getFileIcon = (extension) => {
	let fileIco = null;
	switch (extension) {
		case "pdf":
			fileIco = <i className={"fico fa fa-file-pdf-o"}></i>;
			break;
		case "doc":case "docx":
			fileIco = <i className={"fico fa fa-file-word-o"}></i>;
			break;
		case "xls":case "xlsx":
			fileIco = <i className={"fico fa fa-file-excel-o"}></i>;
			break;
		case "txt":
			fileIco = <i className={"fico fa fa-file-text-o"}></i>;
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
		case "bmp":
		case "tiff":
			fileIco = <i className={"fico fa fa-file-image-o"}></i>;
			break;
		case "zip":
		case "rar":
		case "7z":
		case "tar":
		case "gz":
			fileIco = <i className={"fico fa fa-file-archive-o"}></i>;
			break;
		default:
			fileIco = <i className={"fico fa fa-file-o"}></i>;
			break;
	}
	return fileIco;
};

export const number_format = (number, decimals = 2, dec_point = ".", thousands_sep = " ") => {	// Format a number with grouped thousands
	let i, kw, kd;

	i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
	kw = i.split( /(?=(?:\d{3})+$)/ ).join( thousands_sep );
	kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

	return kw + kd;
};


export const downloadFile = async (e, user, id) => {
	e.preventDefault();
	e.stopPropagation();
	const res = await Api.post('docs.php', {
		action: 'downloadFile',
		user: user,
		id: id
	});

	apiAction(res, (data) => {
		// console.log(data);
		if (data.fileData && data.fileName) {
			saveSync(
				data.fileData,
				data.fileName
			);
		}
		else
			toastr.error('Ошибка получения файла');
	}, () => {

	});
};
export const actionMenuButton = (buttons) => {
	return (
		<div className="actions -left">
			<i className="fa fa-bars"></i>
			<div>
				{buttons}
			</div>
		</div>
	);
}
export const isRole = (user, roles) => {
	if (!user || !user.role)
		return false;
	return in_array(user.role, roles);
}


export const rand = (x, y) => {
	return parseInt(Math.random() * (y + 1 - x) + x, 10);
}
export const arrayRemove = (k, arr) => {
	const i = arr.indexOf(k);
	return i > -1 ? arr.splice(i, 1) : [];
}
export const apiSend = async (api_url, params, callback, actions) => {

	if (actions)
		actions.showLoading();

	const res = await Api.post(api_url, params);
	// console.log(params);

	apiAction(res, (data) => {
		console.log('server gen time:', res.data.time);
		callback(data);
	}, () => {
		if (actions)
			actions.hideLoading();
	});
	if (actions)
		actions.hideLoading();
}
export const bool_val = v => {

	if (v === true || v === false)
		return v;

	if(typeof v === 'string'){
		if( 'true' === v)
			return true;
		if( 'false' === v)
			return false;
		if( '1' === v)
			return true;
		if( '0' === v || v === '')
			return false;
		if( '' !== v )
			return true;
	}
	else if( typeof v === 'number' && v > 0 )
		return true;
	else if( typeof v === 'object' && Object.keys(v).length)
		return true;

	return false;
}
export const format_phone = str => {
	let ar = str.split('');

	return `+${ar[0]} (${ar[1]}${ar[2]}${ar[3]}) ${ar[4]}${ar[5]}${ar[6]}-${ar[7]}${ar[8]}-${ar[9]}${ar[10]}`
}

export const pdate = (format, dtime) => {
	return date(format, new Date(dtime));
}
export const tblSortArrow = (active, sortAsc) => {
	let icoArrow = "fa-caret-down";

	if (sortAsc !== null)
		icoArrow = {
			"fa-caret-down": !sortAsc,
			"fa-caret-up": sortAsc
		};
	return <div
		className={cn("tbl-sort", {active: active})}
		title={sortAsc ? 'по возрастанию' : 'по убыванию'}
	>
		<i className={cn("fa",icoArrow)}></i>
	</div>
};
export const sortByAction = (data, field, sortField, sortAsc) => {
	let newAsc = field == sortField ? !sortAsc : sortAsc;

	let sortedData = [...data];

	// console.log(field, sortField, sortAsc ? 'ASC' : 'DESC', 'newAsc:', newAsc ? 'ASC' : 'DESC');
	// console.dir(sortedData);
	sortedData.sort((a, b) => {

		let aFiled = a[field];
		let bField = b[field];

		// console.log(aFiled, bField, Number.isFinite(aFiled), aFiled > bField);

		if (aFiled > bField)
			return newAsc ? 1 : -1;
		if (aFiled < bField)
			return newAsc ? -1 : 1;

		return 0;
	});
	// console.dir(newOrders);

	// return sortedData;
	// console.log(field, sortAsc, newAsc);
	return {
		sortedData: sortedData,
		newAsc: newAsc
	}
	// setSortField(field);
	// setSortAsc(newAsc);
	// setOrders(newOrders);
}

/**
 * Вывод стрелки в таблицах для сортировки данных
 * @param field - наименование поля таблицы
 * @param stateField
 * @param stateAsc
 * @returns {JSX.Element}
 */
export const tblSortByArrow = (field, stateField, stateAsc) => {
	let icoArrow, thisAsc;

	if (field === stateField) {
		icoArrow = {
			"fa-caret-down": !stateAsc,
			"fa-caret-up": stateAsc
		};
		thisAsc = stateAsc;
	}
	else {
		icoArrow = "fa-caret-down";
		thisAsc = false;
	}

	return <div
		className={cn("tbl-sort", {active: field === stateField})}
		title={thisAsc ? 'по возрастанию' : 'по убыванию'}
	>
		<i className={cn("fa", icoArrow)}></i>
	</div>
};
