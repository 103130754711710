import React, {useState, useEffect, useRef} from 'react';
import {Button, Win, SmartSelect} from "components/_common";
import {in_array} from "utils/func";
import {rolesManager} from "utils/config";
import {SELECT_SHOW_SEARCH_MIN_OPTION} from "./utils";


const filterTitles = {
	status: 'Статус',
};


const FilterWin = props => {

	const {user, filterInit,
		statusList, couriersList, clientsList,
		applyFilterData} = props;

	const filterRef = useRef(filterInit);

	const filterHeaderPrefix = "Фильтр";
	const [filterHeader, setFilterHeader] = useState(filterHeaderPrefix);

	// console.dir('filterInit',  filterInit);

	const printFilterTitle = () => {
		let ftit = [];
		for (let p in filterRef.current) {
			if (filterRef.current[p].names && filterRef.current[p].names.length) {
				ftit.push(filterRef.current[p].names.join(', '));
			}
		}
		setFilterHeader(
			filterHeaderPrefix
			+ (ftit.length ? ': ': '')
			+ ftit.join('; ')
		);
	};

	const changeFilter = (selected, param) => {
		console.log('changeFilter param:', param);
		console.dir(selected);

		let values = [];
		let names = [];


		for (let [key, item] of selected) {
			// console.log(item);
			values.push(item.value);
			names.push(item.name);
		}

		filterRef.current[param] = {
			values: values,
			names: names
		}
		console.log(filterRef.current);

		printFilterTitle();
	};




	// const filterKeysArray = filterRef.current ? Object.keys(filterRef.current) : [];

	let filterFields = [];

	const {status = null,
		couriers = null,
		clients = null} = filterRef.current;
	// console.dir(status);
	// console.log(filterRef.current);

	const colCls = in_array(user.role, rolesManager) ? 'col-3' : 'col-2';


	//статусы
	if (statusList) {
		const statusOptions = [];
		for (const st in statusList) {

			const {name, roles} = statusList[st];
			// console.dir(st, filter.status.values, in_array(st, filter.status.values));
			if (!roles.length || in_array(user.role, roles))
				statusOptions.push({
					value: st,
					name: name,
					selected: status && in_array(st, status.values)
				})
		}
		if (statusOptions.length)
			filterFields.push(
				<div className={colCls} key={"col-status"}>
					<SmartSelect
						name={"status"}
						key={"filter-status"}
						placeholder={"Все"}
						label={"Статус"}
						options={statusOptions}
						optionsHash={statusOptions.length}
						multi={true}
						deleteOnReselect={true}
						onSelect={changeFilter}
					/>
				</div>
			);
	}

	/**/
	// console.dir(couriers);
	if (in_array(user.role, [...rolesManager, 'client']) && couriersList) {
		const curOptions = [];
		for (let k in couriersList) {
			let id = parseInt(k);
			curOptions.push({
				value: id,
				name: couriersList[k].name,
				selected: couriers && in_array(k, couriers.values)
			});
		}
		// console.dir(curOptions);
		if (curOptions.length)
			filterFields.push(
				<div className={colCls} key={"col-cur"}>
					<SmartSelect
						name={"couriers"}
						key={"filter-cur"}
						placeholder={"Все"}
						label={"Курьер"}
						options={curOptions}
						optionsHash={curOptions.length}
						multi={true}
						deleteOnReselect={true}
						onSelect={changeFilter}
						withSearch={curOptions.length > SELECT_SHOW_SEARCH_MIN_OPTION}
					/>
				</div>
			);
	}
	if (in_array(user.role, [...rolesManager, 'courier']) && clientsList ) {
		const clientsOptions = [];
		for (const k in clientsList) {
			clientsOptions.push({
				value: k,
				name: clientsList[k].name,
				selected: clients && in_array(k, clients.values)
			});
		}
		if (clientsOptions.length)
			filterFields.push(
				<div className={colCls} key={"col-client"}>
					<SmartSelect
						name={"clients"}
						key={"filter-cur"}
						placeholder={"Все"}
						label={"Клиент"}
						options={clientsOptions}
						optionsHash={clientsOptions.length}
						multi={true}
						deleteOnReselect={true}
						onSelect={changeFilter}
						withSearch={clientsOptions.length > 10}
					/>
				</div>
			);
	}

	// const colCls = in_array(user.role, rolesManager) ? 'col-3' : 'col-2';
	// const colCls = 'col-' + filterFields.length;

	/* */

	const filterWinFooter = <div className="win-ftr-btns _tac">
		<Button
			type={"save"}
			title={"Применить"}
			ico={"check"}
			onClick={() => {
				console.clear();
				applyFilterData(filterRef.current);
			}}
		/>
		<Button
			type={"save"}
			cls={"-grey"}
			title={"Очистить"}
			ico={"ban"}
			onClick={() => {
				applyFilterData({});

				window.location.reload();
			}}
		/>

	</div>;


	useEffect(() => {
			printFilterTitle();
			return () => {
				// setSelected(new Set());
			}
		},
		[]
		// []
	);



	return (
		<Win
			id={"tasks-filter-win"}
			noOverflowContent={true}
			header={filterHeader}
			minimized={true}
			footer={filterWinFooter}
		>
			<div className="orders-filter flx -sb">
				{filterFields}
			</div>

		</Win>
	)

};
export default FilterWin;
// export default memo(FilterWin);
