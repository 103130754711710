import {rolesManager} from "utils/config";
import {in_array} from "utils/func";
import date from "php-date";
import * as strtotime from "strtotime";

export const TIME_2_COOK = 30 * 60 * 1000;
export const TIME_2_DELIVERY = 60 * 60 * 1000;
export const TIME_BETWEEN_COOKED_DELIVERY = 20 * 60 * 1000;
export const SELECT_SHOW_SEARCH_MIN_OPTION = 10;

export const ORDER_STATUS_NEW		= 0;
export const ORDER_STATUS_CANCELLED = 8;
export const ORDER_STATUS_OUT_OF_ZONE	= 9;
export const ORDER_STATUS_IN_WORK		= 10;
export const ORDER_STATUS_COURIER_ACCEPT = 30;
export const ORDER_STATUS_COURIER_ARRIVE = 31;
export const ORDER_STATUS_COURIER_TAKE_ORDER = 32;
export const ORDER_STATUS_DELIVERED = 33;

export const ORDER_TIME_LABEL_EXACT		= 'Точное время доставки';

/*
кол-во миллисекунд кода заказ считается ближайшим к текущей дате
 */
export const ORDER_CLOSEST_TIME = 30 * 60 * 1000;
/*
Время, прошедшее с начала создания заказа,
если разница между временем сохранения и созданием больше чем задано, то
сравнение идет с текущим временем минус эта переменная
 */
export const ORDER_DIFF_START_AND_SAVE_TIME = 3 * 60 * 1000;

// export const sortOrders = (orders, field, sortAsc) => {
//
// 	// console.log(field, sortAsc);
// 	let newOrders = [...orders];
//
// 	newOrders.sort((a, b) => {
// 		if (a[field] > b[field])
// 			return !sortAsc ? 1 : -1;
// 		if (a[field] < b[field])
// 			return !sortAsc ? -1 : 1;
//
// 		return 0;
// 	});
//
// 	// console.log(filter);
//
// 	return newOrders;
// };
export const divideOrders = (orders) => {
	// console.log('divideOrders');
	let newy = [];//новые заказы
	let closest = [];
	let workOrders = [];//те что в работе
	let others = [];//все что выбыли

	if (!orders.length)
		return orders;

	// let dnow_msec = new Date('2021-11-01 20:00:00').getTime();
	let dnow_msec = Date.now();
	// console.log(dnow_msec, new Date());


	orders.map(order => {
		let time_cooked_msec = new Date(order.time_cooked).getTime();
		// console.log(order.id, order.time_cooked, time_cooked_msec>=dnow_msec, time_cooked_msec <= dnow_msec + ORDER_CLOSEST_TIME);
		if (order.status == ORDER_STATUS_NEW)
			newy.push(order);
		else if (time_cooked_msec >= dnow_msec && time_cooked_msec <= dnow_msec + ORDER_CLOSEST_TIME)
			closest.push(order);
		else if (in_array(order.status, [ORDER_STATUS_OUT_OF_ZONE, ORDER_STATUS_CANCELLED, ORDER_STATUS_DELIVERED]))
			others.push(order);
		else
			workOrders.push(order);
	});

	// console.dir(closest);

	return newy.concat(closest, workOrders, others);
}

export const canOrderEdit = (order, user) => {
	if (parseInt(order.status) >= 33)
		return false;
	// console.dir(order);

	if (!in_array(user.role, [...rolesManager, 'client']))
		return false;

	let userId = parseInt(user.id);

	//клиент
	if (user.role === 'client') {

		let clientId = parseInt(order.client_id);

		// if (order.client_id != user.id || order.manager_id != 0 ) //
		// 	return false;

	}
	//менеджер
	if (in_array(user.role, rolesManager)) {
		if (parseInt(order.uid) !== parseInt(user.id))
			return false;
	}



	return true;
};
export const canOrderOpen = (order, user) => {
	// if (!in_array(user.role, [...rolesManager, 'client']))
	// 	return false;

	if (user.role === 'admin') {
		return true;
	}
	//менеджер
	const oManagerId = parseInt(order.manager_id);

	if (in_array(user.role, rolesManager)) {
		if (oManagerId !== 0 && oManagerId !== parseInt(user.id)) {
			console.log('canOrderOpen 2');
			return false;
		}
	}

	// if (user.role === 'courier') {
	//
	// }

	return true;
}



export const orderIsLocked = (order, user) => {

	if (!order.lock_id)
		return false;

	return parseInt(order.lock_id) !== parseInt(user.id);

}

export const addressString = data => {
	let c = '';
	if (data.city)
		c += data.city.trim();
	if (data.street && data.street != '' && data.street != '-')
		c += ', ' + data.street.trim();
	if (data.house && data.house != '-')
		c += ', ' + data.house.trim();
	if (data.podezd && data.podezd != '-')
		c += ', подъезд ' + data.podezd.trim();
	if (data['floor'] && data['floor'] != '-')
		c += ', эт.' + data['floor'].trim();
	if (data.flat && data.flat != '-')
		c += ', кв. ' + data.flat.trim();
	if (data.domofon && data.domofon != '-')
		c += ', домофон ' + data.domofon.trim();
	return c;
}

export const checkOrderTime = (fulltime, plus, fromTime = Date.now(), minimal_time_milliseconds = 0) => {

	// console.log('checkOrderTime', fromTime, plus);
	// console.log(hourAndMinutes);
	// const [hour, mins] = hourAndMinutes.split(':', 2);
	// console.log(hour, mins);

	// let td = date('Y-m-d ' + hourAndMinutes + ':59');
	let t = strtotime(fulltime) * 1000;//59сек чтобы проверку прошло

	// console.log(date('Y-m-d H:i:s', new Date(t)));

	let min_msec = fromTime + plus;
	// console.log(min_msec, minimal_time_milliseconds, min_msec < minimal_time_milliseconds);
	if (min_msec < minimal_time_milliseconds)
		min_msec = minimal_time_milliseconds;
	// console.log('minimat ends', min_msec);
	// let minDate = date('Y-m-d H:i:00', new Date(fromTime + plus));
	let minTime = date('H:i', new Date(min_msec));


	if (t < min_msec)
		return minTime;

	return '';

}
export const orderPrintTime = dt => {
	return date('H:i', new Date(strtotime(dt) * 1000));
}
export const orderFullTime = hs => {
	let [hour, mins] = hs.split(':', 2);
	if (parseInt(hour) > 23)
		hour = '00';
	if (parseInt(mins) > 60)
		mins = 59;

	return date('Y-m-d '+hour + ':'+ mins+':00');
}
export const orderDateTime = dt => {

	let t = dt;

	if (typeof dt == 'string')
		t = strtotime(dt);
	else if (t < 9999999999)
	 	t = t * 1000;

	return date('Y-m-d H:i:00', new Date(t));
}
export const clearPhone = phone => {
	return phone.replace(new RegExp('[^0-9]', 'g'), "");
}
