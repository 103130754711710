import React from 'react';
// import {in_array} from "utils/func";
import {FormItem, SmartSelect} from "components/_common";
import {SELECT_SHOW_SEARCH_MIN_OPTION} from "./utils";

const OrderEditManager = props => {
	const {
		// user,
		isNew,
		data, setData,
		statusList, couriers, clients,
		settings,
		formErrors, setFormErrors
	} = props;

	if (!data.status)
		data.status = 0;

	let isEdit = true;

	// console.log('OrderEditManager courier_id:', data.courier_id);

	let statusOptions = [];
	for (const st in statusList) {
		const {name, roles} = statusList[st];
		// console.log(st, data.status, parseInt(data.status));
		// if (!roles.length || in_array(user.role, roles))
			statusOptions.push({
				value: st,
				name: name,
				selected: (parseInt(data.status) === parseInt(st))
			})
	}

	const curOptions = [];
	const curOptionsGroups = ['активные', 'не передают геолокацию'];
	for (let k in couriers) {
		let id = parseInt(k);
		let cur = couriers[k];

		// if (settings.startDay !== undefined && settings.startDay.length) {
		// 	settings.startDay.map(ds => {
		// 		if (ds.uid === id)
		// 			isStartDay = true;
		// 		return null;
		// 	});
		// }

		curOptions.push({
			group: cur.isActive ? curOptionsGroups[0] : curOptionsGroups[1],
			value: id,
			name: cur.name,
			selected: (data.courier_id && parseInt(data.courier_id) == id)
		});
	}

	const clientsOptions = [];
	for (const cid in clients) {
		clientsOptions.push({
			value: cid,
			name: clients[cid].name,
			selected: (data.client_id && parseInt(data.client_id) === parseInt(cid))
		});
	}
	// console.dir(statusOptions);

	let order_cost_predefined = [];
	if (settings.order_cost_predefined && settings.order_cost_predefined != '')
		order_cost_predefined = settings.order_cost_predefined.split(',').map(e => e.trim());
	let price_courier_predefined = [];
	if (settings.price_courier_predefined && settings.price_courier_predefined != '')
		price_courier_predefined = settings.price_courier_predefined.split(',').map(e => e.trim());


	let pricesRequired = (data.courier_id && data.courier_id > 0) ? true : false;

	return (
		<div className={"os-man-info "}>
			<FormItem
				edit={isEdit}
				name={"courier_id"}
				label={"Курьер"}
				isRequire={false}
				fieldType={
					<SmartSelect
						edit={isEdit}
						name={"courier_id"}
						maxHeight={'360px'}
						// label={"Курьер"}
						options={curOptions}
						optionsGroups={curOptionsGroups}
						optionsHash={curOptions.length}
						multi={false}
						arrow={true}
						withSearch={curOptions.length > SELECT_SHOW_SEARCH_MIN_OPTION}
						onSelect={res => {
							setData(prev => ({
								...prev,
								changed: true,
								courier_id: parseInt(res.value)
							}));
						}}
					/>
				}

				errorMsg={ (formErrors && formErrors.courier_id) ? formErrors.courier_id : null }
				setErrorMsg={setFormErrors}
			/>
			<div className="row">
				<div className="col-2">
					<FormItem
						edit={isEdit}
						name={"order_cost"}
						label={"Стоимость заказа"}
						ico={"money"}
						isRequire={pricesRequired}
						maxLength={20}
						numericRound={2}
						defVal={data.order_cost ? data.order_cost : 0}
						blurAction={(e, val) => {
								setData(prev => ({
									...prev,
									order_cost: val,
									changed: true
								}));
						}}
						errorMsg={ (formErrors && formErrors.order_cost) ? formErrors.order_cost : null }
						setErrorMsg={setFormErrors}
						predefined={order_cost_predefined}
					/>
				</div>
				<div className="col-2">
					<FormItem
						edit={isEdit}
						name={"price_courier"}
						label={"Премия водителя"}
						ico={"money"}
						isRequire={pricesRequired}
						maxLength={20}
						numericRound={2}
						defVal={data.price_courier ? data.price_courier : 0}
						blurAction={(e, val) => {
								setData(prev => ({
									...prev,
									price_courier: val,
									changed: true
								}));
						}}
						errorMsg={ (formErrors && formErrors.price_courier) ? formErrors.price_courier : null }
						setErrorMsg={setFormErrors}
						predefined={price_courier_predefined}
					/>
				</div>
			</div>
			<FormItem
				edit={isEdit}
				fieldType={"txt"}
				name={"commentm"}
				label={"Комментарий к заказу (менеджер)"}
				ico={"comment"}
				isRequire={false}
				maxLength={500}
				defVal={data ? data.commentm : ''}
				blurAction={(e, val) => {
					setData(prev => ({
						...prev,
						commentm: val,
						changed: true
					}));
				}}
				errorMsg={ (formErrors && formErrors.commentm) ? formErrors.commentm : null }
				setErrorMsg={setFormErrors}
			/>
			{
				isNew
					?
					<FormItem
						edit={isEdit}
						name={"client_id"}
						label={"Клиент"}
						isRequire={true}
						fieldType={
							<SmartSelect
								edit={isEdit}
								name={"client_id"}
								// label={"Клиент"}
								options={clientsOptions}
								optionsHash={clientsOptions.length}
								multi={false}
								arrow={true}
								onSelect={res => {
									setData(prev => ({
										...prev,
										changed: true,
										client_id: parseInt(res.value)
									}));
								}}
							/>
						}

						errorMsg={ (formErrors && formErrors.client_id) ? formErrors.client_id : null }
						setErrorMsg={setFormErrors}
					/>

					: null
			}
		</div>
	)

};

export default OrderEditManager;
