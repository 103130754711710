import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import SettingsContainer from "containers/settings/SettingsContainer";

const SettingsRouter = props => {

	return(
		<Switch>
			<Route
				path="/settings/:settingsType?"
				exact={true}
				key={"settings-personal"}
			>
				<SettingsContainer {...props} />
			</Route>
			{/*<Route path="/settings/:settingsType"*/}
			{/*	   exact={true}*/}
			{/*	   key={"settings-bytype"}*/}
			{/*>*/}
			{/*	<SettingsContainer {...props} />*/}
			{/*</Route>*/}

			<Route component={Page404} />
		</Switch>
	)

};

export default SettingsRouter;