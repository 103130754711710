import React, {useEffect, useState, useRef} from 'react';
import cn from "classnames";
import Scrollbars from "react-custom-scrollbars";
import sum from "hash-sum";


/*
options - массив объектов (ну да тут бы TS)
{
	group: string || null,//наименование группы , если есть
	name : 'string',
	value: 'mixed',
	selected: bool
}
 */


const SmartSelect = props => {

	const {
		name,
		options,
		optionsHash = '',
		optionsGroups = [],//группы по ним сортируются

		// selectedInit = new Set(),
		label = '',
		placeholder = 'Выберите значение',
		//стрелка справа
		arrow = false,
		//показывать поиск по значениям
		withSearch = false,
		//текст поиска
		searchPlaceholder = 'поиск',
		onSelect = (res, paramName) => {
			console.log(paramName, res)
		},
		//выдает результат отсортированный как в списке
		isSorted = true,
		//выбор неск. значений
		multi = false,
		//удаляет элемент при повторной выборе
		deleteOnReselect = false,
		maxHeight = null,
		// selectedOnInit = new Map()
		refresh = false,//обнуляет выбранное
		edit = true,//можно редактировать
		redraw = false,//перерисовать компонент насильно 19/11/2021
		cls = '',
	} = props;

	const thisRef = useRef(null);

	let selectedInit = new Map();

	// if (name=='city')
	// console.log('SmartSelect RENDER optsLen', name, options.length, 'isRefresh', refresh);


	options.map((v, k) => {
		if (v.selected) {
			if (multi || !selectedInit.size)
				selectedInit.set(v.value, v);
		}
		return null;
	});


	//раскидываем по группам
	const setOptionsByGroup = _options => {
		if (optionsGroups.length === 0)
			return _options;

		const optionsByGroup = {};
		let hasGroups = false;
		_options.map((v) => {

			if (v.group !== undefined) {
				if (!optionsByGroup.hasOwnProperty(v.group)) {
					optionsByGroup[v.group] = [];
					hasGroups = true;
				}

				optionsByGroup[v.group].push(v);
			}
		});


		if (!hasGroups)
			return _options;

		let resultOptions = [];
		let groupTitleSet = {};
		optionsGroups.map(groupName => {
			groupTitleSet[groupName] = false;
		});
		optionsGroups.map(groupName => {
			// console.log(groupName);
			if (optionsByGroup[groupName]) {
				if (!groupTitleSet[groupName]) {
					resultOptions.push({
						name: groupName,
						value: null,
						selected: false,
						isGroup: true
					});
					groupTitleSet[groupName] = true;
				}

				optionsByGroup[groupName].map(v => {
					resultOptions.push(v);
				});
			}
		});
		// console.log(optionsByGroup);

		// if (optionsGroups.length) {
		//
		// } else {
		// 	for (let group in optionsByGroup) {
		// 		resultOptions.push({
		// 			name: group,
		// 			value: null,
		// 			selected: false,
		// 			isGroup: true
		// 		});
		// 		optionsByGroup[group].map(v => {
		// 			resultOptions.push(v);
		// 		})
		// 	}
		// }

		return resultOptions;
	}


	// if (name == 'courier_id') {
	// 	console.log(hasGroups);
	// 	// console.dir(options);
	// 	console.log(optionsByGroup);
	// 	console.dir(initOptions);
	// }


	const [selected, setSelected] = useState(selectedInit);
	const [popupShow, setPopupShow] = useState(false);

	const [showOptions, setShowOptions] = useState(setOptionsByGroup(options));

	const [searchText, setSearchText] = useState('');

	// if (0 && name == 'payment') {
	// 	console.log('smart select RENDER: ',
	// 		name,
	// 		'selected: ' + selected.size,
	// 		'selInitSize:' + selectedInit.size,
	// 		'options Len:' + options.length
	// 	);
	// 	console.dir(options);
	// 	console.dir(showOptions);
	// 	console.log(selectedInit.size, selected.size);
	// 	console.dir(selected);
	// }

	const sortByOptions = data => {
		let sortedOptions;
		// console.log('sortByOptions', isSorted, data);
		if (isSorted) {
			sortedOptions = new Map();
			options.map(itm => {
				// console.log(itm, data.has(itm));
				if (data.has(itm.value))
					sortedOptions.set(itm.value, itm);
			});

		} else
			sortedOptions = new Map(data);

		setSelected(sortedOptions);

		if (typeof onSelect === 'function') {
			onSelect(sortedOptions, name);
		}

	}

	const selectOption = (item, remove = false) => {
		// console.log('selectOption', item);
		const key = item.value;
		if (multi) {
			let s = selected;
			let changed = false;

			if (remove) {
				s.delete(key);
				changed = true;
			} else {
				if (!s.has(key)) {
					s.set(key, item);
					changed = true;
				} else if (deleteOnReselect) {
					s.delete(key);
					changed = true;
				}
			}

			if (changed)
				sortByOptions(s);
		} else {
			const s = new Map();
			s.set(key, item);

			if (typeof onSelect === 'function') {

				if (!multi) {
					let vals = [...s.values()];
					onSelect(vals[0], name);
				} else
					onSelect(s, name);
			}

			setSelected(s);
			setPopupShow(false);
		}
	}


	const selectedItem = (itm) => {
		return <li key={itm.value}>
			{itm.name}
			{
				multi ?
					<i className="fa fa-times"
					   onClick={e => selectOption(itm, true)}
					></i>
					: null
			}
		</li>;
	}

	const onSearch = str => {
		const opts = [];
		options.map((itm, key) => {
			if (itm.name.toLowerCase().indexOf(str.toLowerCase().toLowerCase()) !== -1)
				opts.push(itm);
		});
		// console.log('on search', str);
		setShowOptions(opts);
		setShowOptions(setOptionsByGroup(opts));
		setSearchText(str);
	}

	const hideHandle = (e) => {
		if (thisRef.current && !thisRef.current.contains(e.target))
			setPopupShow(false);

	};

	useEffect(() => {

			// if (name == 'city') {
			// 	console.log('smart select EFFECT',
			// 		name,
			// 		'selected: ' + selected.size,
			// 		'selectedInit: ' + selectedInit.size
			// 	);
			// 	console.dir(selected);
			// }

			window.addEventListener('mousedown', hideHandle);

			if (refresh)
				setSelected(new Map());

			// if (sum([...selected.values()]) !== sum([...selectedInit.values()]))
			// 	setSelected(selectedInit);
			if (redraw)
				setSelected(selectedInit);

			// setShowOptions(options);

			setShowOptions(
				setOptionsByGroup(options)
			);

			return () => {
				window.removeEventListener('mousedown', hideHandle);
			}
		},
		[options, optionsHash, refresh]
		// []
	);


	return (
		<div
			className={cn("smart-select noselect", cls, {
				"-one": !multi,
				"-arrowed": (arrow && edit),
				"disabled": !edit
			})}
			ref={thisRef}
			// onMouseLeave={e => setPopupShow(false)}
		>

			{
				label ? <div className="label">
					{label}
					{/*/ {showOptions.length}*/}
				</div> : null
			}

			<ul
				className="ss-selected"
				onClick={e => {
					if (edit)
						if (!multi || (e.target.nodeName !== 'LI' && e.target.nodeName !== 'I'))
							setPopupShow(true);
				}}
			>
				{
					(placeholder && !selected.size) ? <span className="-ph">{placeholder}</span> : null
				}

				{
					// selected.size
					[...selected.values()].map(itm => selectedItem(itm))
				}
			</ul>
			<div className={cn("ss-pop", {show: popupShow})}>
				{
					withSearch ? <div className="ss-srch">
							<input type="text"
								   placeholder={searchPlaceholder}
								   value={searchText}
								   onChange={e => {
									   onSearch(e.target.value)
								   }}
							/>
						</div>
						: null
				}
				<Scrollbars
					autoHide={true}
					autoHeight={true}
					autoHeightMax={maxHeight ? maxHeight : "60vh"}
					// className={"scroll-wrap"}
				>
					<ul className="ss-list">

						{
							showOptions.map((item, i) => {
								return <li
									key={item.value + '-' + i}
									onClick={e => {
										if (item.isGroup === undefined)
											selectOption(item)
									}}
									className={cn({
										selected: selected.has(item.value),
										isGroup: item.isGroup !== undefined
									})}
								>
									{item.image
										? <div
											className="im"
											style={{
												backgroundImage: `url(${item.image})`
											}}
										></div>
										: null}
									{/*{item.image ? <img src={item.image} /> : null}*/}
									{item.name}
								</li>;
							})
						}
					</ul>
				</Scrollbars>

			</div>
		</div>
	)

};
export default SmartSelect;
// export default memo(SmartSelect);
