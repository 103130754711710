import React, {useEffect, useState} from 'react';
import {ContentHeader, Button} from "components/_common";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {apiSend, in_array, sortByAction} from "utils/func";
import {MENU_TITLE, STORAGE_ORDERS_FILTER, STORAGE_ORDERS_SORT, rolesManager,
	DEBUG, DEBUG_TEST, rolesModerator,
	TIME_CLOSED_DEFAULT
} from "utils/config";
import Storage from "utils/Storage";
import FilterWin from "components/orders/FilterWin";
import {divideOrders, orderDateTime} from "components/orders/utils";
import OrdersList from "components/orders/OrdersList";
import {orderFullTime} from "components/orders/utils";
import * as strtotime from "strtotime";



const API_URL = 'orders.php';

const getOrders = async (filter, calDates = null, search = '', props) => {
	const {user, GUI, actions,
		setOrderStatusList,
		setCouriers,
		setClients,
		setManagers,
		setOrders,
		sortField,
		sortAsc
	} = props;
	// console.log('getOrders API fetch');
	// console.log(calDates);
	// console.dir(GUI.calendarDates);

	let dates = {
		start: new Date(GUI.calendarDates.start).getTime() / 1000,
		end: new Date(GUI.calendarDates.end).getTime() / 1000
	};
	if (calDates) {
		// console.log('by calDates');
		dates = {
			start: new Date(calDates.start).getTime() / 1000,
			end: new Date(calDates.end).getTime() / 1000
		};
	}

	// console.log(dates, orderDateTime(dates.start));

	apiSend(
		API_URL,
		{
			action: 'getOrders',
			userId: user.id,
			filter: filter,
			dates: dates,
			search: search,
		},
		(res) => {
			if (res.orderStatusList)
				setOrderStatusList(res.orderStatusList);
			if (res.couriers)
				setCouriers(res.couriers);
			if (res.clients)
				setClients(res.clients);
			if (res.managers)
				setManagers(res.managers);

			let arr = [];

			res.data.map(order => {
				order.id = parseInt(order.id);
				arr.push(order);

				return null;
			});

			// console.dir(arr);
			// console.log(sortInit, sortField, sortAsc);
			const {sortedData, newAsc} = sortByAction(arr, sortField, '', sortAsc);
			const dividedData = divideOrders(sortedData);
			// console.dir(sortedData);
			setOrders(dividedData);

		},
		actions
	)
};

const OrdersListContainer = props => {

	const {user, GUI, actions} = props;

	const [orders, setOrders] = useState(null);
	const [orderStatusList, setOrderStatusList] = useState(null);
	const [managers, setManagers] = useState(null);
	const [couriers, setCouriers] = useState(null);
	const [clients, setClients] = useState(null);



	const filterInit = Storage.get(STORAGE_ORDERS_FILTER, {}, true);

	//сортировочки
	// const sortInit = Storage.get(STORAGE_ORDERS_SORT, {field: 'time_cooked', asc: true}, true);
	// const [sortField, setSortField] = useState(sortInit.field);
	// const [sortAsc, setSortAsc] = useState(sortInit.asc);
	const [sortField, setSortField] = useState('time_cooked');
	const [sortAsc, setSortAsc] = useState(true);



	const getOrdersParams = {
		...props,
		setOrderStatusList: setOrderStatusList,
		setCouriers: setCouriers,
		setClients: setClients,
		setManagers: setManagers,
		setOrders: setOrders,
		sortField: sortField,
		sortAsc: sortAsc
	}




	const updateListener = res => {
		console.log('SOCKET - updateOrdersList', res);
		// console.log(GUI.calendarDates);
		if (parseInt(res.userId) !== parseInt(user.id))
			getOrders(
				Storage.get(STORAGE_ORDERS_FILTER, {}, true),
				null,
				'',
				getOrdersParams
			).then(r => {});
	}


	useEffect(() => {

			let isSubscribe = true;

			if (isSubscribe)
				getOrders(filterInit, null, '', getOrdersParams).then(r => {});

			if (GUI.socket)
				GUI.socket.on('updateOrdersList', updateListener);


			return () => {
				isSubscribe = false;
				if (GUI.socket)
					GUI.socket.off('updateOrdersList', updateListener);
			}

		},
		[GUI.socket]
	);

	const applyFilter = (data) => {
		console.log('applyFilter', data);
		Storage.set(STORAGE_ORDERS_FILTER, data);

		getOrders(data, null, '', getOrdersParams).then(r => {});
	}

	const sortBy = (field) => {

		// let newOrders = [...orders];
		//
		// newOrders.sort((a, b) => {
		// 	if (a[field] > b[field])
		// 		return !sortAsc ? 1 : -1;
		// 	if (a[field] < b[field])
		// 		return !sortAsc ? -1 : 1;
		//
		// 	return 0;
		// });
		// // console.dir(newOrders);


		// setSortField(field);
		// setSortAsc(!sortAsc);
		// // setOrders(newOrders);
		// setOrders(
		// 	sortOrders(
		// 		orders,
		// 		field,
		// 		!sortAsc,
		// 	)
		// );


		const {sortedData, newAsc} = sortByAction(orders, field, sortField, sortAsc);
		const dividedData = divideOrders(sortedData);


		Storage.set(STORAGE_ORDERS_SORT, {field: field, asc: newAsc});

		setSortField(field);
		setSortAsc(newAsc);
		setOrders(dividedData);
	};


	const timeClosed = (GUI.settings != undefined && GUI.settings.time_closed != undefined)
		? GUI.settings.time_closed
		: TIME_CLOSED_DEFAULT;
	const isClosed = Date.now() > (strtotime(orderFullTime(timeClosed)) * 1000);

	let chPanelContent = null;
	if (user.role === 'client'
		||
		((DEBUG || DEBUG_TEST) && in_array(user.role, rolesModerator))
	) {
		if (isClosed)
			chPanelContent = <div className="btn -red" key={"weareclosed"}>
				Заказы не принимаются после {timeClosed}
			</div>;
		else
			chPanelContent = (
				<div className="orders-btn-create-order" key={"ordes-btn-c"}>
					<Button
						href={"/orders/edit/0"}
						title={"Создать заказ"}
						type={"save"}
						ico={"plus"}
					/>
				</div>
			);
	}







	return (
		<>
			<ContentHeader
				title={MENU_TITLE.orders.many}
				showDates={true}
				datesAction={e => getOrders(null, null, '', getOrdersParams)}
				panelContent={chPanelContent}
			/>

			{
				(orderStatusList && couriers && clients)
					? <FilterWin
						user={user}
						filterInit={filterInit}
						statusList={orderStatusList}
						couriersList={couriers}
						clientsList={clients}

						applyFilterData={applyFilter}
					/>
					: null
			}

			{
				orders
					? <OrdersList
						user={user}
						statusList={orderStatusList}
						couriersList={couriers}
						clientsList={clients}
						managersList={managers}
						orders={orders}

						sortField={sortField}
						sortAsc={sortAsc}
						onSort={sortBy}

						settings={GUI.settings}
					/> : null
			}


		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		hideLoading, showLoading,
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersListContainer);
