import React, {useEffect, useState} from "react";
import {Switch, Route, NavLink, useLocation} from 'react-router-dom';
import Page404 from "components/Page404";
import TopbarContainer from "containers/TopbarContainer";
// import CustomScroll from 'react-custom-scroll';
// import Scrollbars from 'react-custom-scrollbars';
import SideMenu from "components/menu/SideMenu";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as Routers from "routers/index";
import {userAuth} from "actions/UserActions";
import {setCalendarSelectDateAction, setSettings, sideBarToggle, setSettingsOne, showStartDay} from "actions/GUIActions";
import ScrollTopButton from "components/_common/ScrollTopButton";
import {DEBUG, DEBUG_TEST, APP_VERSION, rolesAdmin} from "utils/config";
import cn from "classnames";
import TopbarUserinfo from "components/user/TopbarUserinfo";
import MapContainer from "containers/map/MapContainer";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {NotifyUsersWin} from "containers/user";
// import date from "php-date";
import {isRole} from "utils/func";
import Storage from "utils/Storage";



const ContentContainer = (props) => {

	const {GUI, user} = props;

	const sideOpen = GUI.sideBarOpen ? 'open' : '';

	const location = useLocation();

	const [mobileMenuOpen, setMobileMenuOpen] = useState('');

	const isAdmin = isRole(user, rolesAdmin);
	const isManager = isRole(user, ['manager']);
	let startDayShowToday = false;
	if (isAdmin)
		startDayShowToday = Storage.get(`startDayShowToday_${user.id}`, false);

	setTimeout(() => {
		let hash = location.hash.substr(1);
		if (hash) {
			const element = document.getElementById(location.hash.substr(1));
			if (element)
				window.scrollTo({
					behavior: "smooth",
					top: element.offsetTop
				});
		}
	}, 100);

	let contentStyle = {};

	useEffect(() => {

		// console.log(GUI.showStartDayWin);

		setMobileMenuOpen('');
		if (GUI.socket) {
			GUI.socket.emit(
				'changeURL',
				{
					path: location.pathname + location.search + location.hash,
					calendarDates: GUI.calendarDates
				});
		}
		return () => {

			if (GUI.socket)
				GUI.socket.off('changeURL', null);
		}
	},[location]);

	// if (window.innerWidth > 1024) {
	// 	if (user.settings['user_' + user.id + '_style_content_max_width'])
	// 		contentStyle['width'] = user.settings['user_' + user.id + '_style_content_max_width'];
	// }


	let mainClass = null;
	const locPathArr = location.pathname.split('/');

	if (locPathArr[1])
		mainClass = locPathArr[1];

	if (GUI.settings == undefined || GUI.settings === null || Object.keys(GUI.settings).length === 0)
		return 'Нет настроек';



	return(
		<div className={cn("anim-show", {print: GUI.isPrint})}>

			{
				(isAdmin || isManager)
					? <NotifyUsersWin
						{...props}
						show={isAdmin && !startDayShowToday}
					/>
					: null
			}


			<>
				<div className={"ms-top flx -sb no-print " + sideOpen}>
					<div className="logo">
						<NavLink to="/">
							ПУЛЯ
							{DEBUG ? <sup>dev </sup> : (DEBUG_TEST ? <sup>test </sup> : '')}

						</NavLink>

					</div>

					<div className="menu-bars"
						 onClick={e => {
							 setMobileMenuOpen(
							  mobileMenuOpen === '' ? ' mobi-open' : ''
							 );
						 }}
					>
						<i className="fa fa-bars"></i>
					</div>

					<TopbarUserinfo />
				</div>
				<div className={"menu-side no-print " + sideOpen + mobileMenuOpen}>
					{/*<div className="menu calendar-menu">*/}
					{/*	<ul className="_z ">*/}
					{/*		<CalendarContainer />*/}
					{/*		<li>*/}
					{/*			<hr />*/}
					{/*		</li>*/}
					{/*	</ul>*/}
					{/*</div>*/}

					<SideMenu
						// setScrollClassName={setScrollClassName}
						user={props.user}
					/>
					<div className="menu-side-btm">
						ver. {APP_VERSION}
					</div>
					{/*<Scrollbars*/}
					{/*	autoHide={true}*/}
					{/*	// autoHeight={true}*/}
					{/*	className={scrollClassName}*/}
					{/*>*/}
					{/*	*/}
					{/*</Scrollbars>*/}

				</div>
			</>
			<TopbarContainer />




			<main
				className={cn(mainClass,{full: !props.GUI.sideBarOpen})}

			>
				<div className="content" style={contentStyle}>
					<Switch>
						{/*<Route path="/" exact component={Containers.ConsoleContainer} />*/}
						<Route path="/" exact component={Routers.OrdersRouter} />
						<Route path="/settings" component={Routers.SettingsRouter} />
						<Route path="/user" component={Routers.UserRouter} />
						<Route path="/logs" component={Routers.LogsRouter} />

						<Route path="/orders" component={Routers.OrdersRouter} />
						<Route path="/report" component={Routers.ReportRouter} />

						<Route path="/map" component={MapContainer} />

						<Route component={Page404} text={123}/>
					</Switch>
				</div>
			</main>

			<ScrollTopButton />

		</div>
	);
};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		userAuth,
		setCalendarSelectDateAction,
		sideBarToggle,
		hideLoading, showLoading,
		setSettings, setSettingsOne,
		showStartDay
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
