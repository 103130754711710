import React, {useState} from 'react';
import { SketchPicker } from 'react-color';


const ColorPicker = ({color, getColor}) => {

	// console.log('ColorPicker render', color);
	const [stateColor, setColor] = useState(color);
	const [showPicker, setShowPicker] = useState(false);

	// useEffect(() => {
	//
	// }, [color]);

	return (
		<div
			className={"color-picker"}
			style={{backgroundColor: stateColor}}
			onClick={() => {
				if (!showPicker)
					setShowPicker(true);
			}}
		>
			{
				showPicker
					? <div
						// className={cn("cp-picker", {active: showPicker})}
						className={"cp-picker"}
					>
						<div
							className="cp-cover"
							onClick={() => {
								setShowPicker(false);
							}}
						/>
						<SketchPicker
							color={stateColor}
							onChange={(color) => {
								setColor(color.hex);
								getColor(color.hex);
							}}
							// onChangeComplete={(color) => {
							// 	console.log('onChangeComplete', color.hex);
							// 	// setColor(color.hex);
							// }}
						/>
					</div>
					: null
			}


		</div>
	)

};

export default ColorPicker;
