export const APP_TITLE = 'Пуля';
export const APP_ID = 'ru.kbdelivery';
export const APP_VERSION = '220819';
export const LOGO_TITLE = '';

// export const DEBUG = false;
// export const DEBUG_TEST = false;
export const DEBUG = /localhost/.test(window.location.href);
export const DEBUG_TEST = /test.kbdelivery/.test(window.location.href);
let _SITE_URL = '';
let _API_URL = '';
let _SOCKET_URL = '';
if (DEBUG) {
	_SITE_URL = 'http://localhost:3000';
	_API_URL = 'http://localhost/pula/api/';
	// _API_URL = 'http://local.ghoul.ru:3080/pula/api/';
	_SOCKET_URL = 'http://local.ghoul.ru:3009';
	// _API_URL = 'https://kbdelivery.ru/api/';
	// _SOCKET_URL = 'https://kbdelivery.ru';
}
else if (DEBUG_TEST) {
	_SITE_URL = 'https://test.kbdelivery.ru';
	_API_URL = 'https://test.kbdelivery.ru/api/';
	_SOCKET_URL = 'https://test.kbdelivery.ru';
}
else {
	_SITE_URL = 'http://kbdelivery.ru';
	_API_URL = 'https://kbdelivery.ru/api/';
	_SOCKET_URL = 'https://kbdelivery.ru';
}


export const SITE_URL	= _SITE_URL;
export const API_URL	= _API_URL;
export const SOCKET_URL = _SOCKET_URL;



export const PASSWORD_SALT = 'mfdue8sdf';
export const COOKIE_LOGIN = 'login';
export const COOKIE_PASS = 'password';
export const SESSION_SETTINGS_NAME = 'settings';
export const SESSION_LIFETIME = 3600 * 24 * 5;
export const SHOW_SCROLLTOP_OFFSET = 300;




export const rolesAdmin = ['admin'];
export const rolesModerator = ['admin', 'moderator', 'owner'];
export const rolesManager = ['admin', 'manager'];
export const MENU_TITLE = {
	users: {
		one: 'Пользователь',
		many: 'Пользователи'
	},
	settings: {
		one: 'Настройка',
		many: 'Настройки'
	},
	logs: {
		one: 'Log',
		many: 'Logs'
	},
	orders: {
		one: 'Заказ',
		many: 'Заказы'
	},
	cities: {
		one: 'Населенный пункт',
		many: 'Населенные пункты'
	},
	report: {
		one: 'Отчет',
		many: 'Отчеты'
	},
};

export const MENU_URLS = {
	orders: '/',
	user: '/user',
	settings: '/settings',
	logs: '/logs'
}

export const MENU = [
	// 'separator',
	// {
	// 	name: 'Консоль',
	// 	url: '/',
	// 	ico: 'fa-tachometer',
	// 	roles: []
	// },
	{
		name: MENU_TITLE.orders.many,
		url: '/',
		ico: 'fa-tasks',
		roles: []
	},
	{
		name: MENU_TITLE.users.many,
		url: '/user',
		ico: 'fa-users',
		roles: [...rolesModerator],
		menu: [
			{
				name: 'Администраторы',
				url: '/user/admin',
				// ico: 'fa-building',
				roles: ['admin']
			},
			{
				name: 'Менеджеры',
				url: '/user/manager',
				// ico: 'fa-building',
				roles: []
			},
			{
				name: 'Клиенты',
				url: '/user/client',
				// ico: 'fa-building',
				roles: []
			},
			{
				name: 'Курьеры',
				url: '/user/courier',
				// ico: 'fa-building',
				roles: []
			},
		]
	},
	{
		name: MENU_TITLE.settings.many,
		url: '/settings',
		ico: 'fa-cogs',
		roles: [...rolesManager],
		menu: [
			{
				name: MENU_TITLE.cities.many,
				url: '/settings/cities',
				ico: 'fa-building',
				roles: []
			},
		]
	},
	{
		name: MENU_TITLE.report.one,
		url: '/report',
		ico: 'fa-pie-chart',
		roles: [...rolesAdmin]
	},
	{
		name: 'Карта',
		url: '/map',
		ico: 'fa-map-marker',
		roles: [...rolesAdmin]
	},
	{
		name: MENU_TITLE.logs.many,
		url: '/logs',
		ico: 'fa-history',
		roles: [...rolesAdmin]
	},

];

export const MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
];
export const DAYS_SHORT = [
	'пн',
	'вт',
	'ср',
	'чт',
	'пт',
	'сб',
	'вс'
];
export const DAYS_FULL = [
	'понедельник',
	'вторник',
	'среда',
	'четверг',
	'пятница',
	'суббота',
	'воскресенье'
];

export const MESSAGES = {
	confirmAction: 'Подтвердите действие',
	fieldEmpty: 'Поле не заполнено',
	accessDenied: 'Доступ закрыт'
};
export const IMG_EXTENSIONS = [
	'jpg', 'jpeg',
	'bmp', 'png', 'gif',
	'webp'
];

export const STORAGE_ORDERS_FILTER = 'orders_filter';
export const STORAGE_ORDERS_SORT = 'orders_sort';

export const ORDER_STATUS_COLORS_DEFAULT = {
	bgr: '#fff',
	txt: '#212529'
}

export const YANDEX_MAP_API_KEY = '731d6487-5e95-474c-accb-3111f5e8b7d2';

export const TIME_CLOSED_DEFAULT = '23:00';

export const NBSP = '\u00A0';
