import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Win, Button} from "components/_common";
import {MENU_TITLE, MESSAGES} from "utils/config";
import {apiSend, in_array, sortByAction, pdate, tblSortByArrow} from "utils/func";
import cn from "classnames";

const API_URL = 'orders.php';

const ReportContainer = props => {

	const {user, GUI, actions} = props;
	const [orders, setOrders] = useState([]);

	const [sortField, setSortField] = useState('id');
	const [sortAsc, setSortAsc] = useState(false);

	const getData = async () => {
		console.log(GUI.calendarDates);
		let dates = {
			start: new Date(GUI.calendarDates.start).getTime() / 1000,
			end: new Date(GUI.calendarDates.end).getTime() / 1000
		};
		console.log(dates);

		apiSend(
			API_URL,
			{
				action: 'reportOrders',
				userId: user.id,
				dates: dates,
			},
			(res) => {
				console.dir(res);

				let arr = [];

				if (res.orders)
					res.orders.map(order => {
						order.id = parseInt(order.id);
						order.createdTime = parseInt(order.createdTime);
						order.time_accept = parseInt(order.time_accept);

						arr.push(order);
					});

				setOrders(arr);
			},
			actions
		)
	}

	useEffect(() => {
			let isSubscribe = true;
			if (isSubscribe)
				getData().then(r => {});

			return () => {
				isSubscribe = false;
			}

		},
		[]
	);
	// console.log(sortField, sortAsc);
	const sortBy = (field) => {

		const {sortedData, newAsc} = sortByAction(orders, field, sortField, sortAsc);
		setSortField(field);
		setSortAsc(newAsc);
		setOrders(sortedData);
		// let newAsc = field === sortField ? !sortAsc : false;
		//
		// let newOrders = [...orders];
		//
		// newOrders.sort((a, b) => {
		//
		// 	let aFiled = a[field];
		// 	let bField = b[field];
		// 	// console.log(aFiled, bField, Number.isFinite(aFiled));
		//
		// 	if (aFiled > bField)
		// 		return newAsc ? 1 : -1;
		// 	if (aFiled < bField)
		// 		return newAsc ? -1 : 1;
		//
		// 	return 0;
		// });
		// // console.dir(newOrders);
		//
		//
		// console.log(field, sortAsc, newAsc);
		// setSortField(field);
		// setSortAsc(newAsc);
		// setOrders(newOrders);

	};

	if (user.role !== 'admin')
		return MESSAGES.accessDenied;

	const columnTitle = {
		client: 'Контрагент',
		id: '№ заказа',
		created: 'Дата',
		time_accept: 'Время принятия заказа',
		courier: 'Курьер',
		address: 'Адрес',
		price_courier: 'Оплата курьеру',
		profit: 'Доход',
		order_cost: 'Оплата от контрагента',
		time_courier_arrive: 'Подача курьера',
		time_courier_wait: 'Ожидание курьера',
		time_delivery: 'Время доставки',
		time_delivery_full: 'Общее время доставки'
	}


	return (
		<>
			<ContentHeader
				title={MENU_TITLE.report.one}
				showDates={true}
				datesAction={e => getData()}

			/>

			<div className={"report-tbl tbl -bordered -win -zebra"}>
				<div className="thead">
					<div className="tr">
						<div className="td col-id"
							 onClick={() => sortBy('id')}
						>
							{columnTitle.id}
							{tblSortByArrow('id', sortField, sortAsc)}
						</div>
						<div className="td col-client"
							 onClick={() => sortBy('clientName')}
						>
							{columnTitle.client}
							{tblSortByArrow('clientName', sortField, sortAsc)}
						</div>

						<div className="td col-created"
							 onClick={() => sortBy('createdTime')}
						>
							{columnTitle.created}
							{tblSortByArrow('createdTime', sortField, sortAsc)}
						</div>
						<div className="td col-time_accept"
							 onClick={() => sortBy('time_accept')}
						>
							{columnTitle.time_accept}
							{/*{tblSortArrow(sortField === 'time_accept', sortAsc)}*/}
							{tblSortByArrow('time_accept', sortField, sortAsc)}
						</div>
						<div className="td col-courier"
							 onClick={() => sortBy('courierName')}
						>
							{columnTitle.courier}
							{/*{tblSortArrow(sortField === 'courierName', sortAsc)}*/}
							{tblSortByArrow('courierName', sortField, sortAsc)}
						</div>
						<div className="td col-address"
							 // onClick={() => sortBy('id')}
						>
							{columnTitle.address}
							{/*{tblSortArrow(sortField === 'id', sortAsc)}*/}
						</div>
						<div className="td col-price_courier"
							 onClick={() => sortBy('price_courier')}
						>
							{columnTitle.price_courier}
							{/*{tblSortArrow(sortField === 'price_courier', sortAsc)}*/}
							{tblSortByArrow('price_courier', sortField, sortAsc)}
						</div>
						<div className="td col-profit"
							 onClick={() => sortBy('profit')}
						>
							{columnTitle.profit}
							{/*{tblSortArrow(sortField === 'profit', sortAsc)}*/}
							{tblSortByArrow('profit', sortField, sortAsc)}
						</div>
						<div className="td col-order_cost"
							 onClick={() => sortBy('order_cost')}
						>
							{columnTitle.order_cost}
							{/*{tblSortArrow(sortField === 'order_cost', sortAsc)}*/}
							{tblSortByArrow('order_cost', sortField, sortAsc)}
						</div>
						<div className="td col-time_courier_arrive"
							 onClick={() => sortBy('time_courier_arrive')}
						>
							{columnTitle.time_courier_arrive}
							{/*{tblSortArrow(sortField === 'time_courier_arrive', sortAsc)}*/}
							{tblSortByArrow('time_courier_arrive', sortField, sortAsc)}
						</div>
						<div className="td col-time_courier_wait"
							 onClick={() => sortBy('time_courier_wait')}
						>
							{columnTitle.time_courier_wait}
							{tblSortByArrow('time_courier_wait', sortField, sortAsc)}
						</div>

						<div className="td col-time_delivery"
							 onClick={() => sortBy('time_delivery')}
						>
							{columnTitle.time_delivery}
							{/*{tblSortArrow(sortField === 'time_delivery', sortAsc)}*/}
							{tblSortByArrow('time_delivery', sortField, sortAsc)}
						</div>
						<div className="td col-time_delivery_full"
							 onClick={() => sortBy('time_delivery_full')}
						>
							{columnTitle.time_delivery_full}
							{/*{tblSortArrow(sortField === 'time_delivery_full', sortAsc)}*/}
							{tblSortByArrow('time_delivery_full', sortField, sortAsc)}
						</div>
					</div>
					<div className="tbody">
						{
							// Object.keys(orders).map(oid => {
							orders ? orders.map(order => {
								// let order = orders[ oid ];

								return (
									<div
										className={cn("tr", {special: order.special})}
										key={"rep-tbl-" + order.id}>

										<div className="td col-id">
											{order.id}
										</div>

										<div className="td col-client">
											{order.clientName}
										</div>

										<div className="td col-created">
											{
												pdate("d.m.Y",
												order.createdTime * 1000)
											}
										</div>
										<div className="td col-time_accept">
											{pdate("H:i", order.time_accept * 1000)}
										</div>
										<div className="td col-courier">
											{order.courierName}
										</div>
										<div className="td col-address">
											{order.address.city ? order.address.city.trim() + ', ' : null}
											{order.address.house ? order.address.house : null}
										</div>
										<div className="td col-price_courier">
											{order.price_courier} &#8381;
										</div>
										<div className="td col-profit">
											{order.profit} &#8381;
										</div>
										<div className="td col-order_cost">
											{order.order_cost} &#8381;
										</div>
										<div className="td col-time_courier_arrive">
											{order.time_courier_arrive}
										</div>
										<div className="td col-time_courier_wait">
											{order.time_courier_wait}
										</div>
										<div className="td col-time_delivery">
											{order.time_delivery}
										</div>
										<div className="td col-time_delivery_full">
											{order.time_delivery_full}
										</div>
									</div>
								);
							})
								: null
						}
					</div>
				</div>
			</div>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		hideLoading, showLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
