import React, {createRef} from 'react';
import sha256 from "sha256";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {SESSION_USER, USER_LOGIN, userAuth} from "actions/UserActions";
import {setSettings} from "actions/GUIActions";
import {DEBUG, PASSWORD_SALT, SESSION_LIFETIME, SESSION_SETTINGS_NAME} from "utils/config";
import Api, {apiAction} from "utils/Api";
import {Win, Button, FormItem} from "components/_common";
import {NavLink} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import Storage from "utils/Storage";



const LoginContainer = props => {

	const {actions} = props;

	// const cookies = new Cookies();

	// const [content, setContent] = useState(null);
	// console.log('LoginContainer');

	let apiActionProcess = false;

	const onLogin = async (login, password, isRemember, passFromCookie = false) => {

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

		}
		actions.showLoading();

		const passHash = passFromCookie ? password : sha256(password + PASSWORD_SALT);

		const res = await Api.post('users.php',  {
			action: 'login',
			userId: -1,
			login: login,
			password: passHash
		}).catch(er => {
			toastr.error('Ошибка запроса (js)', er.toString(), {timeOut: 0});
			actions.hideLoading();
		});

		apiAction(res, (data) => {
			// console.dir(data);

			if (data.user) {
				Storage.set(SESSION_USER, data.user, SESSION_LIFETIME);
				actions.userAuth(USER_LOGIN, data.user);
			}
			if (data.settings) {
				Storage.set(SESSION_SETTINGS_NAME, data.settings, SESSION_LIFETIME);
				actions.setSettings(data.settings);
			}
			if (data) {
				//
				//
				//
				//
			}
		}, () => {
			apiActionProcess = false;
			actions.hideLoading();
		});
		actions.hideLoading();
	};
	const footer = (
		<div className={"_tar"}>
			<NavLink to="/forgetpassword">Забыли пароль?</NavLink>
			{/*<NavLink to="/registration">Регистрация</NavLink>*/}
		</div>
	);

	const formRef = createRef();
	const onSubmit = (e) => {
		e.preventDefault();
		const form = formRef.current;

		if (form.login.value !== '' && form.password.value !== '') {
			onLogin(
				form.login.value.trim(),
				form.password.value.trim(),
				true//form.remember.checked
			).then(null);
		}
		else
			toastr.warning('Внимание', 'Введите логин/пароль');
	};


	const logWin = (
		<div className="win-wide">
			<Win
				header={"Вход"}
				footer={footer}
				winClass={"login-win anim-win"}
			>
				<form
					ref={formRef}
					  onSubmit={(e) => {
						  e.preventDefault();
						  onSubmit(e);
					  }}
				>
					<FormItem
						key={"login"}
						name={"login"}
						defVal={DEBUG ? "admin" : ""}
						isRequire={true}
						ico={"user"}
						placeholder={"Логин"}
					/>
					<FormItem
						key={"password"}
						fieldType={"password"}
						name={"password"}
						defVal={DEBUG ? "FWYkZ3JcfSx" : ""}
						isRequire={true}
						ico={"key"}
						placeholder={"Пароль"}
						autoComplete={"current-password"}
					/>

					{/*<div className="form-item">*/}
					{/*	<label className={"check"}>*/}
					{/*		<input*/}
					{/*			type={"checkbox"}*/}
					{/*			name={"remember"}*/}
					{/*			defaultValue={"1"}*/}
					{/*		/>*/}
					{/*		Запомнить меня*/}
					{/*	</label>*/}
					{/*</div>*/}
					<div className="form-item _tac">
						<Button
							type={"apply"}
							title={"Войти"}
							buttonType={"button"}
						/>
					</div>
				</form>
			</Win>
		</div>
	);

	// useEffect(() => {
	// 	let isSubscribe = true;
	//
	// 	// if (isSubscribe && !user) {
	// 	// 	const cLogin = cookies.get(COOKIE_LOGIN);
	// 	// 	const cPass = cookies.get(COOKIE_PASS);
	// 	// 	// console.log(cLogin + cPass);
	// 	// 	if (cLogin && cPass)
	// 	// 		onLogin(cLogin, cPass, true, true).then(null);
	// 	// 	else
	// 	// 		setContent(logWin);
	// 	// }
	// 	// setContent(logWin);
	//
	// 	return () => isSubscribe = false
	// }, []);


	return logWin;
	// return content;

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	// userLogin : (login, password) => dispatch(userLogin(login, password)),
	actions: bindActionCreators({
		showLoading, hideLoading,
		userAuth, setSettings
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
