import React, {useState} from 'react';
import {connect} from 'react-redux';
import {MONTHS, DAYS_SHORT} from "utils/config";
import date from "php-date";
import {bindActionCreators} from "redux";
import {setCalendarDates} from "actions/GUIActions";
import {in_array} from "utils/func";
import {NavLink} from "react-router-dom";
import { useHistory } from "react-router-dom";


const CalendarContainer = (props) => {

	const {GUI, user, actions} = props;

	let history = useHistory();
	// console.log(history);
	//
	// console.log(GUI.calendarDates);

	const todayYear = parseInt(date('Y', new Date()));
	const todayMonth = parseInt(date('n', new Date()));
	const todayDay = parseInt(date('j', new Date()));

	const curYear = parseInt(GUI.calendarDates.year);
	const curMonth = parseInt(GUI.calendarDates.month);
	const curDay = parseInt(GUI.calendarDates.day);
	// const [curDay, setCurDay] = useState(curDayInit);

	const [calendarFixed, setCalendarFixed] = useState('');//!GUI.sideBarOpen ? 'fixed' : '');

	// const [selectedDay, setSelectedDay] = useState(curYear);
	const [selectedYear, setSelectedYear] = useState(curYear);
	const [selectedMonth, setSelectedMonth] = useState(curMonth);

	const nowYear = (new Date()).getFullYear();//это палюбому текущий год, от которого идет отсчет
	let yearsInit = [];
	for (let i = nowYear; i >= user.yearMin; i--) {
		yearsInit.push(i);
	}

	const [years, setYears] = useState(yearsInit);


	const onSelectYear = value => {
		setSelectedYear(parseInt(value));
	};
	const onSelectMonth = value => {
		setSelectedMonth(parseInt(value) + 1);
	};
	const handleArrows = isNext => {
		let nextYear = selectedYear,
			nextMonth = selectedMonth;
		// console.log(selectedMonth);
		// console.log(isNext);
		if (isNext) {
			nextMonth += 1;
			if (nextMonth > 12) {
				nextMonth = 1;
				nextYear += 1;
			}
		}
		else {
			nextMonth -= 1;
			if (nextMonth < 1) {
				nextMonth = 12;
				nextYear -= 1;


			}
		}

		if (!in_array(nextYear, years)) {
			let ys = years;
			if (isNext)
				ys.unshift(nextYear);
			else
				ys.push(nextYear);
			setYears(ys);
		}

		setSelectedMonth(nextMonth);
		if (selectedYear !== nextYear)
			setSelectedYear(nextYear);

	};

	const onSelectDay = (e, url = null) => {
		// console.log('onSelect:');
		// console.log('selectedYear: ' + selectedYear);
		// console.log('selectedMonth: ' + selectedMonth);
		// console.log('real selectedMonth: ' + e.target.data.month);
		// console.log('selectedYear: ' + e.target.dataset.year);
		// console.log('selectedMonth: ' + e.target.dataset.month);
		// console.log('selectedDay: ' + parseInt(e.target.innerHTML));
		// console.log(url);
		// return;


		const se = new Date(parseInt(e.target.dataset.year), parseInt(e.target.dataset.month - 1), parseInt(e.target.innerHTML));

		// const calDatesStartWas = GUI.calendarDates
		const calDates = {
			start: se,
			end: se,
			year: e.target.dataset.year,
			month: e.target.dataset.month,
			day: parseInt(e.target.innerHTML)
		};

		actions.setCalendarDates(calDates);

		if (GUI.calendarSelectDateAction) {
			GUI.calendarSelectDateAction(calDates);
		}
		else if (url) {
			history.push(url);
		}
	};





	/**
	 * генерируем календарь
	 * @type {number}
	 */




	// console.log('INIT selectedMonth: ' + selectedMonth);
	const currMothDays =  new Date(selectedYear, selectedMonth, 0).getDate();
	const currFirstDayNum = date("N", new Date(selectedYear, selectedMonth - 1, 1));
	const currLastDayNum = date("N", new Date(selectedYear, selectedMonth - 1, currMothDays));

	let prevMonth = selectedMonth - 1;
	let prevYear = selectedYear;
	//предусмотреть когда декабрь предыдущего
	if (prevMonth <= 0) {
		prevMonth = 11;
		prevYear = prevYear - 1;
	}
	let prevMonthDays = new Date(prevYear, prevMonth, 0).getDate();


	//PREV
	const prevDays = currFirstDayNum - 1;
	let prevMonthDaysArr = [];
	for(let i = 1; i <= prevDays; i++){
		prevMonthDaysArr.push(prevMonthDays--);
	}
	prevMonthDaysArr = prevMonthDaysArr.reverse();


	let nextDays = (7 - currLastDayNum);
	let totalShowDays = prevMonthDaysArr.length + currMothDays + nextDays;


	//NEXT
	let nextMonth = selectedMonth + 1;
	let nextYear = selectedYear;
	if( nextMonth > 12 ){
		nextMonth = 1;
		nextYear++;
	}

	const showWeeks = totalShowDays / 7;

	let catTable = [];
	let dayIterator = 1;
	let nextDayIterator = 1;

	// console.log(user.settings.holidays);
	// console.log(todayYear +'/'+ todayMonth + '/'+todayDay);

	for (let i = 1; i <= showWeeks; i++) {
		let row = [];
		let dataYear = selectedYear;
		let dataMonth = selectedMonth;

		for (let j = 0; j < 7; j++) {
			let day = '-';


			let tdClass = '';
			if (i === 1 && j < prevMonthDaysArr.length) {
				day			= parseInt(prevMonthDaysArr[j]);
				tdClass		= 'other-month';
				dataMonth	= prevMonth;
				dataYear	= prevYear;
			}
			else if (dayIterator <= currMothDays) {
				if (dayIterator === curDay)
					tdClass = 'selected';

				day 		= dayIterator++;
				dataMonth	= selectedMonth;
				dataYear	= selectedYear;

			}
			else if (i === showWeeks) {
				day = nextDayIterator++;
				tdClass = 'other-month';
				dataMonth	= nextMonth;
				dataYear	= nextYear;
			}

			//СЕГОДНЯ
			if (dataYear === todayYear && dataMonth === todayMonth && day === todayDay) {
				tdClass += ' today';
			}


			if (j === 5)
				tdClass += ' st';
			if (j === 6)
				tdClass += ' su';

			if (user.settings.holidays.length && in_array(dataYear + '-' + dataMonth + '-' + day, user.settings.holidays))
				tdClass += ' su';

			// let dayContent = day;
			// if (GUI.calendarSelectDateAction === null) {
			// 	dayContent = <NavLink
			// 		to={`/tasks/date/${dataYear}-${dataMonth}-${day}`}
			// 	>
			// 		{day}
			// 	</NavLink>;
			// }

			row.push(
				<td
					key={"cal-tbl-row-td" + i + j}
					className={tdClass}
					onClick={e => onSelectDay(
						e,
						GUI.calendarSelectDateAction === null ? `/tasks/date/${dataYear}-${dataMonth}-${day}` : ''
					)}
					data-year={dataYear}
					data-month={dataMonth}
				>
					{day}
				</td>
			);
		}
		catTable.push(
			<tr key={"cal-tbl-row" + i}>
				{row}
			</tr>
		);
	}
	// console.log(user.settings.mkad_url);

	//(!GUI.calendarSelectDateAction ? ' Xdisabled' : '')

	return(
		<>
			<li className={"calendar-wrap as-title"}>
				<a href="#" onClick={(e) => {
					e.preventDefault();
					if (!GUI.sideBarOpen)
						setCalendarFixed( calendarFixed ? '' : 'fixed');
				}}>
					<i className="fa fa-calendar"></i>
					<span>Календарь</span>
				</a>
				<div className={"calendar " + calendarFixed}>

					<div className="-top flx -sb -alc">
						<div className="arr">
							<i className={"fa fa-arrow-left"} onClick={e => handleArrows(false)} />
						</div>
						<div className="month">
							<select
								name={'calendar-month'}
								// defaultValue={selectedMonth - 1}
								value={selectedMonth - 1}
								onChange={(e) => {
									onSelectMonth(e.target.value);
								}}
							>
								{MONTHS.map((month, i) => {
									return (
										<option value={i}
												key={"cal-m-" + i} >
											{month}
										</option>
									)
								})}
							</select>
						</div>
						<div className="year">
							<select
								name={'calendar-year'}
								value={selectedYear}
								onChange={(e) => {
									onSelectYear(e.target.value)
								}}
							>
								{years.map((year) => {
									return (
										<option value={year} key={"cal-y-" + year}>
											{year}
										</option>
									)
								})}
							</select>
						</div>
						<div className="arr">
							<i className={"fa fa-arrow-right"} onClick={e => handleArrows(true)} />
						</div>
					</div>

					<table className="tbl">
						<thead>
						<tr>
							<td>
								{DAYS_SHORT[0]}
							</td>
							<td>
								{DAYS_SHORT[1]}
							</td>
							<td>
								{DAYS_SHORT[2]}
							</td>
							<td>
								{DAYS_SHORT[3]}
							</td>
							<td>
								{DAYS_SHORT[4]}
							</td>
							<td>
								{DAYS_SHORT[5]}
							</td>
							<td>
								{DAYS_SHORT[6]}
							</td>
						</tr>
						</thead>
						<tbody>
						{/*<catTable />*/}
						{catTable}

						</tbody>
					</table>

				</div>
			</li>
			{/*<li>*/}
			{/*	{*/}
			{/*		(user.settings && user.settings.mkad_url && user.settings.mkad_url !== '') ?*/}
			{/*			<a href={user.settings.mkad_url} target={"_blank"}>*/}
			{/*				<i className={"fa fa-map-pin"}></i>*/}
			{/*				<span>*/}
			{/*					Расчет расстояния от МКАД*/}
			{/*				</span>*/}
			{/*			</a> : null*/}
			{/*	}*/}
			{/*</li>*/}
		</>
	)
};
//
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});
//
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarDates
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
// export default CalendarContainer;