import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import {in_array, string2num} from "utils/func";
import InputMask from 'react-input-mask';
import {DEBUG} from "utils/config";


const FormItem = props => {

	const {
		edit = true,//редактируемый влияет на disabled
		label,
		name,
		isRequire = false,
		defVal = "",
		cls = "",
		fieldType = 'text', hidden = false,
		ico = null, descr = '',
		numericRound = null,//int, округлять числовые значения
		reff = null,
		/*
		* Опции, объект
		* {
		* 	key1: {value: 'string', [selectStyle: 'css class']},
		*   key2: {value: 'string 2', [selectStyle: 'css class']}
		* }
		*/
		options = {},
		optionsComponent = null,
		changeAction = (e, val) => {
			// console.log('formItem', name, 'changeAction value: ', val);
		},
		blurAction = () => {
		},
		hideFields = [],
		//предустановленные значения поля, можно выбрать
		predefined = [],
		//дописываются в конце
		predefinedAdd = false,
		predefinedAddSeparator = '. ',
		//маска, для даты например
		mask = null, alwaysShowMask = false,
		placeholder = null,
		isChecked = false,
		radioInNewLine = true,
		icoRight = null,//это объект {ico: string, onClick: func}
		maxLength = null,
		// errorText=null,
		errorMsg = '',
		setErrorMsg = null,
		notFocusOnTab = false,
		autoComplete='off'
	} = props;

	const _ref = reff ? reff : createRef();


	// console.log('%cFormItem Render ' + name, 'background: #222; color: #bada55');
	// console.log(isChecked);

	// if (name == 'city')
	// 	console.dir(props);


	const [fieldValue, setFieldValue] = useState(defVal);
	// const [errorMsg, setErrorMsg] = useState('');


	useEffect(() => {
			// console.log(name, errorMsg);
			setFieldValue(defVal);
			// setErrorMsg(errorText);

			return () => {
				// setErrorMsg(null);
			}
		},
		[
			defVal,
			// errorMsg,
			errorMsg,
		]
	);

	if (hidden || fieldValue === null || in_array(name, hideFields)) {
		if (DEBUG) {
			return <div className="">
				поле {name} скрыто
			</div>;
		} else
			return null;
	}

	// if (fieldType == 'select') {
		// if (!in_array(defVal, Object.keys(options)))
		// 	return <div className={"clr-red"}>
		// 		Для поля {name} c типом fieldType=select, задано значение по умолчанию {defVal}, которое не существует в options: {Object.keys(options).join(', ')}
		// 	</div>;
	// }


	const changeAct = (e, val) => {

		let result = changeAction(e, val, name);

		if (result !== undefined)
			setFieldValue(result);
	};
	const onBlur = e => {
		let thisVal = e.target.value;
		if (numericRound !== null) {
			thisVal = string2num(thisVal, numericRound);
			setFieldValue(thisVal);
		}

		blurAction(e, thisVal, setFieldValue);
	};
	const onFocus = e => {

	};

	let _descr = descr,
		_label = label;
	if (predefined.length && descr == '')
		_descr = (
			<div className="predefined">
				{
					predefined.map((v, i) => {
						return (
							<span
								key={"fi-prdef-" + i}
								onClick={e => {
									// console.log('click');
									let fieldValueNew = fieldValue;
									if (predefinedAdd) {
										fieldValueNew += (fieldValueNew !== '' ? predefinedAddSeparator : '') + v;
									} else
										fieldValueNew = v;

									setFieldValue(fieldValueNew);
									changeAct(
										{
											target: {
												name: name,
												value: fieldValueNew
											}
										},
										fieldValueNew
									);
									blurAction(e, fieldValueNew, setFieldValue);
								}}
							>
								{v}
							</span>
						)
					})
				}
			</div>
		);

	// if (!edit)
	// 	_descr = null;


	let field = (
		<p className={"input"}>
			undefined fieldType "{fieldType}"
		</p>
	);

//fieldType == 'password' ? 'current-password' : 'off'
	if (in_array(fieldType, ['text', 'email', 'color', 'tel', 'number', 'password', 'file', 'files', 'date', 'datetime-local', 'time'])) {

		field = <input
			type={fieldType === 'files' ? 'file' : fieldType}
			name={name}
			// defaultValue={defVal}
			value={fieldValue}
			// value={defVal}
			onChange={e => {
				setFieldValue(e.target.value);
				changeAct(e, e.target.value, e.target.name);
			}}
			onBlur={e => onBlur(e)}
			ref={_ref}
			placeholder={placeholder}
			disabled={!edit}
			multiple={fieldType === 'files'}
			autoComplete={autoComplete}
			maxLength={maxLength}
			onFocus={e => onFocus(e)}

		/>;
		if (mask) {
			field = <InputMask
				mask={mask}
				maskplaceholder={placeholder}
				// placeholder={placeholder}
				alwaysShowMask={alwaysShowMask}
				type={fieldType}
				name={name}
				value={fieldValue}
				onChange={e => {
					setFieldValue(e.target.value);
					changeAct(e, e.target.value);
				}}
				onBlur={e => onBlur(e)}
				ref={_ref}
				disabled={!edit}
				// maxLength={maxLength}
				onFocus={e => onFocus(e)}
				autoComplete={autoComplete}
			/>;
		}
	} else if (in_array(fieldType, ['bool'])) {
		_label = null;
		field = (
			<label>
				<input
					type={"checkbox"}
					name={name}
					defaultValue={defVal}
					// value={fieldValue}
					// value={defVal}
					onChange={e => {
						// setFieldValue(e.target.value);
						// changeAct(e, e.target.value);
						changeAct(e, e.target.checked, e.target.name);
					}}
					ref={_ref}
					disabled={!edit}
					// checked={isChecked}
					defaultChecked={isChecked}
					onBlur={e => onBlur(e)}
					maxLength={maxLength}
					tabIndex={notFocusOnTab ? "-1" : null}
				/>
				<b>{label}</b>
			</label>
		);
	} else if (in_array(fieldType, ['textarea', 'area', 'txt']))
		field = (
			<textarea name={name}
				// defaultValue={fieldValue}
					  value={fieldValue}
					  ref={_ref}
					  onChange={e => {
						  setFieldValue(e.target.value);
						  changeAct(e, e.target.value, name);
					  }}
					  onBlur={e => onBlur(e)}
					  disabled={!edit}
					  maxLength={maxLength}
					  onFocus={e => onFocus(e)}
			></textarea>
		);
	else if (fieldType === 'select') {

		let optionsHtml = [];
		if (optionsComponent)
			optionsHtml = optionsComponent;
		else {
			Object.keys(options).map(key => {
				optionsHtml.push(
					<option
						key={name + "-opt-" + key}
						value={key}
						style={options[key].selectStyle ? options[key].selectStyle : null}
					>
						{options[key].value}
					</option>
				)
			})
		}

		field = (
			<select
				name={name}
				// defaultValue={fieldValue}
				value={fieldValue}
				onChange={e => {
					setFieldValue(e.target.value);
					changeAct(e, e.target.value);
				}}
				ref={_ref}
				disabled={!edit}
			>
				{optionsHtml}
			</select>
		);
	} else if (fieldType === 'radio') {
		let radioValues = [];

		if (options.length || Object.keys(options).length) {
			Object.keys(options).map(key => {
				radioValues.push(
					<div className={cn("fi-field-radio", {inline: !radioInNewLine})} key={name + key}>
						<label>
							<input
								type={"radio"}
								name={name}
								value={key}
								onChange={e => {
									setFieldValue(e.target.value);
									changeAct(e);
								}}
								ref={_ref}
								placeholder={placeholder}
								disabled={!edit}
								defaultChecked={key === defVal}
							/>
							{options[key].ico ? <div className="radio-ico">
								<i className={"fa fa-" + options[key].ico}></i>
							</div> : null}
							{options[key].value}
						</label>
					</div>
				);
			});

			field = radioValues;
		} else
			field = <div className="clr-red">
				!!! Для типа поля RADIO не задан массив опций options
			</div>

	} else if (fieldType === 'checkSelect') {
		let radioValues = [];

		if (options.length || Object.keys(options).length) {
			Object.keys(options).map((key) => {

				let optValue = options[key].value;

				if (Number.isFinite(optValue))
					optValue = parseInt(options[key].value);

				// console.log(options[key].value, typeof key, in_array(key, defVal));
				// console.log(key, defVal.includes(parseInt(key)));
				radioValues.push(
					<div className={cn("fi-field-radio", {inline: !radioInNewLine})} key={name + key}>
						<label>
							<input
								type={"checkbox"}
								name={name}
								value={optValue}
								onChange={e => {
									setFieldValue(e.target.value);
									changeAct(e);
								}}
								ref={_ref}
								placeholder={placeholder}
								disabled={!edit}
								defaultChecked={
									(Array.isArray(defVal) && in_array(optValue, defVal))
									||
									(optValue === defVal)
								}
							/>
							{options[key].ico ? <div className="radio-ico">
								<i className={"fa fa-" + options[key].ico}></i>
							</div> : null}
							{options[key].name}
						</label>
					</div>
				);
			});

			field = radioValues;
		} else
			field = <div className="clr-red">
				!!! Для типа поля {fieldType} не задан массив опций options
			</div>

	} else// if (typeof fieldType === 'object')
		field = fieldType;

	// console.log(name + '/' + typeof fieldType );

	const fieldWrap = (
		<div className={cn("fi-field", {withIco: ico, withIcoRight: icoRight !== null})}>
			{
				errorMsg ?
					<div
						className="popup-msg"
						// className={cn("popup-msg", {active: true})}
					>
						<div>
							{errorMsg}
						</div>
					</div>
					: null
			}

			{ico ? (
				<div className="-ico">
					<i className={"fa fa-" + ico}></i>
				</div>
			) : ''}
			{field}
			{icoRight ? <div
				className="-icor"
				onClick={e => {
					if (icoRight.onClick)
						icoRight.onClick(e, _ref.current);
				}}
				title={icoRight.tip && icoRight.tip}
			>
				<i className={"fa fa-" + icoRight.ico}></i>
			</div> : null}
		</div>
	)

	return (
		<div
			className={cn("form-item", cls, {disabled: !edit})}
			onClick={e => {
				if (errorMsg && typeof setErrorMsg === 'function')
					setErrorMsg(prev => ({
						...prev,
						[name]: ''
					}));

			}}
		>
			{
				_label ? (
					<label>
						{label}
						{(edit && isRequire) ? '*' : null}
					</label>
				) : null
			}

			{fieldWrap}

			{
				_descr ? (
					<div className="descr">
						{_descr}
					</div>
				) : ''
			}
		</div>
	)
};
FormItem.propTypes = {
	edit: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	isRequire: PropTypes.bool,
	// disabled: PropTypes.bool,
	defVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
	fieldType: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),

};
export default FormItem;
