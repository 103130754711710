import React, {useEffect, useState} from 'react';
import {UsersListItem} from "components/user";
// import {in_array} from "utils/func";
import cn from "classnames";
// import {rolesModerator, MENU_TITLE} from "utils/config";

const UsersList = props => {

	const {users, user, onDelete, roleInit, divisions = []} = props;

	const [usersShow, setUsersShow] = useState(users);
	const [showRole, setShowRole] = useState(roleInit);

	useEffect(() => {
			setUsersShow(users);
		},
		[users]
	);

	/**
	 * скрываем сортировки
	 * @param e
	 */
	const handleClickOutside = e => {
		if (e.target.className.indexOf('tbl-sorter-select') === -1) {
			setRoleIsHide(true);
		}
	};

	// useEffect(() => {
	// 	document.addEventListener('mousedown', handleClickOutside);
	// 	setUsersShow(users);
	// 	setShowRole(roleInit);
	// 	// console.log(roleInit);
	//
	// 	return () => {
	// 		// document.removeEventListener("mousedown", handleClickOutside);
	// 		// setUsersShow([]);
	// 		// setShowRole('');
	// 	}
	// },
	// 	[]
	// );

	const [sorter, setSorter] = useState({});
	const usersSort = (field, value) => {
		let sorter_new = sorter;
		sorter_new[ field ] = value;
		setSorter(sorter_new);


		let stepUsers = users;
		console.log(stepUsers);
		const sortStep = (selUsers, fld, val) => {
			let res = [];
			selUsers.map((user) => {
				if (val == '' || user[fld] == val)
					res.push(user);

			});
			return res;
		};
		for (const fld in sorter) {
			stepUsers = sortStep(stepUsers, fld, sorter[fld]);
		}
		setUsersShow(stepUsers);
	};

	/*
	 * Выбор роли
	 */

	const [roleIsHide, setRoleIsHide] = useState(true);
	const roleOptions = [];

	let usedRolesOptions = [];

	// users.map((_user) => {
	// 	_user.divId = parseInt(_user.divId);
	// 	if (!in_array(_user.role, usedRolesOptions)) {
	// 		usedRolesOptions.push(_user.role);
	// 		roleOptions.push({
	// 			label: _user.roleName,
	// 			value: _user.role
	// 		})
	// 	}
	// 	if (!in_array(_user.divId, usedDivisionOptions)) {
	// 		usedDivisionOptions.push(_user.divId);
	// 		divisionOptions.push(
	// 			_user.divId  ? _user.division : {id: _user.divId}
	// 		);
	// 	}
	// });
	let roleHeaderSelect = 'Роль';
	if (roleInit === '')
		roleHeaderSelect = (
		<select
			className={"tbl-sorter-select " + (roleIsHide ? 'hidden' : '')}
			onMouseEnter={() => setRoleIsHide(false)}
			onChange={(e) => {
				setRoleIsHide(true);
				usersSort('role', e.target.value);
				setShowRole(e.target.value)
			}}
		>
			<option key={"role-"} value={""} className={"tbl-sorter-select-option"}>Роль</option>
			{roleOptions.map((itm) => {
				return (
					<option
						value={itm.value}
						key={"role-" + itm.value}
						className={"tbl-sorter-select-option"}
					>
						{itm.label ? itm.label : 'без роли'}
					</option>);
			})}
		</select>
	);

	const [sortField, setSortField] = useState('id');
	const [sortAsc, setSortAsc] = useState(true);
	const sortBy = (field) => {
		usersShow.sort((a, b) => {
			if (a[field] > b[field])
				return !sortAsc ? 1 : -1;
			if (a[field] < b[field])
				return !sortAsc ? -1 : 1;

			return 0;
		});
		setSortField(field);
		setSortAsc(!sortAsc);
	};


	return (
		<div className="mt20">
			<div className="users-list tbl Xdark -zebra -mobi-xs-one-line">
				<div className="thead">
					<div className="tr">
						<div className="td col-act">
							&nbsp;
						</div>
						<div
							className="td col-name sortable"
							onClick={() => sortBy('name')}
						>
							Пользователь
							<div className={cn("tbl-sort", {active: sortField === 'name'})}>
								<i className={cn("fa",{"fa-caret-down": sortAsc,"fa-caret-up": !sortAsc})}></i>
							</div>
						</div>
						<div className="td col-login">
							Логин
						</div>
						<div className="td col-role">
							{/*{roleHeaderSelect}*/}
							Роль
						</div>
						<div className="td col-tel">
							Телефон
						</div>
						<div className="td col-email">
							Email
						</div>
						<div className="td col-tg">
							Telegram ID
						</div>
					</div>
				</div>
				<div className="tbody">
					{usersShow.map((item, i) =>
						<UsersListItem
							key={i}
							sysUser={user}
							user={item}
							onDelete={onDelete}
							showRole={showRole}
						/>
					)}
				</div>
			</div>
			{/*<table className="tbl users-list">*/}
			{/*	<thead>*/}
			{/*	<tr>*/}
			{/*		<td width="3%" className="_tac">*/}
			{/*			&nbsp;&nbsp;*/}
			{/*		</td>*/}
			{/*		<td width="27%" className={"sortable"} onClick={() => sortBy('name')}>*/}
			{/*			Пользователь*/}
			{/*			<div className={cn("tbl-sort", {active: sortField === 'name'})}>*/}
			{/*				<i className={cn("fa",{"fa-caret-down": sortAsc,"fa-caret-up": !sortAsc})}></i>*/}
			{/*			</div>*/}
			{/*		</td>*/}
			{/*		<td width="15%">*/}
			{/*			{roleHeaderSelect}*/}
			{/*		</td>*/}
			{/*		<td width="30%">*/}
			{/*			Телефон*/}
			{/*		</td>*/}
			{/*		<td width="20%">*/}
			{/*			Email*/}
			{/*		</td>*/}
			{/*		<td width="20%">*/}
			{/*			Telegram ID*/}
			{/*		</td>*/}
			{/*	</tr>*/}
			{/*	</thead>*/}
			{/*	<tbody>*/}

			{/*	{usersShow.map((item, i) =>*/}
			{/*		<UsersListItem*/}
			{/*			key={i}*/}
			{/*			sysUser={user}*/}
			{/*			user={item}*/}
			{/*			onDelete={onDelete}*/}
			{/*			showRole={showRole}*/}
			{/*		/>*/}
			{/*	)*/}
			{/*	}*/}
			{/*	</tbody>*/}
			{/*</table>*/}

		</div>
	)

};

export default UsersList;