import React, {Fragment} from "react";
import {in_array, format_phone} from "utils/func";
import {rolesAdmin, rolesModerator} from "utils/config";
import {Button} from "components/_common";
import {NavLink} from "react-router-dom";
import cn from "classnames";

const UsersListItem = (props) => {

	const {sysUser, user, onDelete, showRole = '', divisions} = props;

	//if (in_array(sysUser.role, ['admin'])))
	// console.dir(user);
	// console.log(divisions);

	let editButton = null, deleteButton = null;

	let link = `/user/profile/${user.id}`;

	if (in_array(sysUser.role, rolesAdmin) || (user.divId == sysUser.divId && in_array(sysUser.role, rolesModerator))) {
		link = `/user/edit/${user.id}`;
		editButton = (
			<NavLink to={"/user/edit/" + user.id} title={"Редактировать"}>
				<i className="fa fa-edit"></i> Редактировать
			</NavLink>
		);

		if (user.id > 1)
			deleteButton = (
				<a href={"#"} onClick={(e) => onDelete(e, user)}>
					<i className="fa fa-close"></i> Удалить
				</a>
			);
	}

	const actionsMenu = (
			<div className="actions -left">
				<i className="fa fa-bars"></i>
				<div>
					<NavLink to={`/user/profile/${user.id}`} title={"Информация"}>
						<i className="fa fa-info"></i> Информация
					</NavLink>
					{editButton}
					{deleteButton}

				</div>
			</div>
		);

	let tblColumnRole = [];
	switch(showRole) {
		case 'driver':
			if (user.car !== '')
				tblColumnRole.push(user.car);
			break;
		case 'installer':
			if (user.passport !== '')
				tblColumnRole.push(user.passport);
			break;
		default:
			tblColumnRole.push(
				<Fragment key={"user-col"}>
					{user.email}
					{
						user.phone !== '' ? <div className={"mt20"}>{user.phone}</div> : null
					}
				</Fragment>
			);

			break;
	}

	// if (in_array(sysUser.role, [...rolesModerator, 'manager']))
	// 	tblColumnRole.push(
	// 		<div className={tblColumnRole.length ? "mt20" : ""} key={'btn-user-files'}>
	// 			<Button
	// 				href={"/docs/user/" + user.id}
	// 				title={"Файлы"}
	// 				ico={"files-o"}
	// 				cls={"-lgrey -small"}
	// 			/>
	// 		</div>
	// 	);

	return (
		<div className={cn("tr", {disabled: !user.confirmed})}>
			<div className="td col-act">
				{actionsMenu}
			</div>
			<div className="td col-name flx -alc">
				{
					user.avatar ?
						<div className={"im"} style={{backgroundImage:"url("+user.avatar +")"}}></div>
						: null
				}
				<NavLink to={link}>
					{user.name}
					{
						!user.confirmed ? ' (неакт.)' : null
					}
				</NavLink>
			</div>
			<div className="td col-login">

				<div className="td-title">
					Логин
				</div>
				{user.login ? user.login : ''}
			</div>
			<div className="td col-role">
				<div className="td-title">
					Роль
				</div>
				{user.roleName.replace(/\//, ' / ')}
			</div>
			<div className="td col-tel">
				<div className="td-title">
					Телефон
				</div>
				{user.phone ? format_phone(user.phone) : ''}
			</div>
			<div className="td col-email">
				<div className="td-title">
					Email
				</div>
				{user.email ? user.email : ''}
			</div>
			<div className="td col-tg">
				<div className="td-title">
					Telegram ID
				</div>
				{user.telegram_id ? user.telegram_id : ''}
			</div>
		</div>
	);

	// return (
	// 	<tr key={user.id}>
	// 		<td valign={"top"}>
	// 			{actionsMenu}
	// 		</td>
	// 		<td className={"-name -href-clr"} valign={"top"}>
	// 			<div className="flx -alc">
	// 				{
	// 					user.avatar ?
	// 						<div className={"im"} style={{backgroundImage:"url("+user.avatar +")"}}></div>
	// 						: null
	// 				}
	// 				<NavLink to={"/user/profile/" + user.id}>
	// 					{user.name}
	// 				</NavLink>
	// 			</div>
	// 		</td>
	// 		<td valign={"top"}>
	// 			{user.roleName.replace(/\//, ' / ')}
	// 		</td>
	// 		<td valign={"top"}>
	// 			{user.phone ? user.phone : ''}
	// 		</td>
	// 		<td valign={"top"}>
	// 			{user.email ? user.email : ''}
	// 		</td>
	// 	</tr>
	// );
};
export default UsersListItem;
