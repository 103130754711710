import React, {useEffect, useState} from 'react';
import { YMaps, Map, Placemark, TrafficControl, ZoomControl } from 'react-yandex-maps';
import date from "php-date";
import * as strtotime from "strtotime";
import {YANDEX_MAP_API_KEY} from "utils/config";
import {ORDER_STATUS_COURIER_ARRIVE, ORDER_STATUS_DELIVERED} from "components/orders/utils";

// const PLACEMARK_COLOR_OFFLINE = '#b3b4bd';
// const PLACEMARK_COLOR_FREE = '#41c124';
// const PLACEMARK_COLOR_HAS_ORDERS = '#276abd';

const PLACEMARK_ICON_OFFLINE = 'islands#grayStretchyIcon';
const PLACEMARK_ICON_FREE = 'islands#darkGreenStretchyIcon';
const PLACEMARK_ICON_HAS_ORDERS = 'islands#redStretchyIcon';


const MapContent = props => {

	const {
		GUI,
		data
	} = props;

	const {clients = [], orderStatusList, couriers: allCouriers = []} = data;
	// const [onHoverId, setOnHoverId] = useState(0);

	const [couriers, setCouriers] = useState(allCouriers);
	// console.log('MapContent', couriers.length);

	const updateListener = data => {
		// console.clear();
		// console.log('MapContent socket data couriers total', couriers.length);

		// console.log(data);

		// if (data.latitude && data.longitude && data.id) {
		if (data.id) {
			let newCouriers = [];

			allCouriers.map(user => {

				user.offline = data.offline ?? false;

				var showOnMap = true;
				// var nowMsecs = Date.now()/1000;

				if (user.id == data.id) {
					// if (data.live_period) {
					// 	user.lat = data.latitude;
					// 	user.lng = data.longitude;
					// 	user.lastseen = nowMsecs;
					// }
					// else if (user.lastseen && user.lastseen < (nowMsecs - 10 * 60)) {
					// 	user.offline = true;
					// }
					// else {
					// 	user.lat = 0;
					// 	user.lng = 0;
					// }
					user.lat = data.latitude ?? 0;
					user.lng = data.longitude ?? 0;
					user.lastseen = data.lastseen ?? 0;

					let lessThenSeconds = Math.round(Date.now()/1000 - 60 * 60 );
					// //если он офлайн час и больше
					if (user.lastseen < lessThenSeconds)
						showOnMap = false;

					user.orders = data.orders ?? [];
					if (user.id == 7) {
						console.clear();
						// console.log(user.id, showOnMap, user.lastseen, lessThenSeconds);
						console.dir(user);
						console.log(showOnMap);
						console.log(
							date('H:i:s', new Date(user.lastseen * 1000)),
							' <LS | NOW> ',
							date('H:i:s', new Date(lessThenSeconds * 1000)));
					}

				}

				if (showOnMap)
					newCouriers.push(user);
			});
			setCouriers(newCouriers);
		}
	}
	useEffect(() => {

			if (GUI.socket)
				GUI.socket.on('updateCourierLocation', updateListener);

			return () => {
				if (GUI.socket)
					GUI.socket.off('updateCourierLocation', updateListener);
			}

		},
		[GUI.socket, couriers]
	);



	/*
	если он еще не прибыл за заказом, то время забора,
	когда прибыл можно просто написать ожидает заказ №111,
	а когда в пути – время доставки
	*/
	const setHint = (user) => {

		let c = user.name;
		if (user.lastseen)
			c += ' (' + date('H:i', new Date(user.lastseen * 1000)) + ')';
		if (user.offline === true)
			c += ' - отсутствует';

		if (user.orders && user.orders.length) {
			c += '<br><b>Заказы:</b>';
			user.orders.map(order => {

				c += '<br>№ ' + order.id + '';
				if (clients[ order.client_id ])
					c += ', ' + clients[ order.client_id ].name;
				if (orderStatusList[ order.status ] && order.status != ORDER_STATUS_COURIER_ARRIVE)
					c += ', ' + orderStatusList[ order.status ].name;

				if (order.status < ORDER_STATUS_COURIER_ARRIVE)
					c += ', время забора '
						//+ order.time_cooked;
						+ date('H:i', new Date(order.time_cooked));
				else if (order.status == ORDER_STATUS_COURIER_ARRIVE)
					c += ', прибыл за заказом';
				else if (order.status > ORDER_STATUS_COURIER_ARRIVE && order.status < ORDER_STATUS_DELIVERED) {
					c += ', время доставки ';
					if (order.time_delivery == order.time_delivery_max || order.time_delivery_exact) {
						c += date('H:i', new Date(order.time_delivery));
					}
					else
						c += ' с '
							+ date('H:i', new Date(order.time_delivery))
							+ ' по '
							+ date('H:i', new Date(order.time_delivery_max));

				}

			});
		}
		// console.log(c);
		return c;
	}



	// return null;

	return (
		<div className={"map-container"}>
			<YMaps
				query={{ apikey: YANDEX_MAP_API_KEY }}
			>
				<Map
					defaultState={{ center: [55.979773, 37.171916], zoom: 12 }}
					width={"100%"}
					height={"100%"}
					modules = {['geoObject.addon.balloon', 'geoObject.addon.hint']}
				>
					{
						couriers.map(user => {
							// if (user.id == 7)
							// 	console.dir(user);
							if (user.lat && user.lng && user.lastseen) {
								let hint = setHint(user);


								let icon = PLACEMARK_ICON_FREE;
								if (user.offline && user.offline === true)
									icon = PLACEMARK_ICON_OFFLINE;
								else if (user.orders && user.orders.length)
									icon = PLACEMARK_ICON_HAS_ORDERS;


								return (
									<Placemark
										key={`ymap-pm-${user.id}`}
										geometry={[user.lat, user.lng]}
										options={{
											preset: icon,
											// iconColor: iconColor,
											// iconContent: 'Че-то везет'
										}}
										properties={{
											hintContent: hint,
											iconContent: user.icon + ', ' + date('H:i', new Date(user.lastseen * 1000)),//в центре
											balloonContent: hint
										}}
										modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
										// onMouseEnter={() => setOnHoverId(user.id)}
									/>
								)
							}
						})
					}
					{/*<Placemark*/}
					{/*	geometry={[55.983982, 37.148232]}*/}
					{/*	options={{*/}
					{/*		preset: 'islands#circleDotIcon',*/}
					{/*		// preset: 'islands#blueStretchyIcon',*/}
					{/*		iconColor: PLACEMARK_COLOR_FREE,*/}
					{/*		iconContent: ''*/}
					{/*	}}*/}
					{/*	properties={{*/}
					{/*		hintContent: 'Зверева Екатерина<br>' + date('H:i', new Date(Date.now())),*/}
					{/*		iconContent: 'Зверева (18:02)',//в центре*/}
					{/*		iconCaption: '',*/}
					{/*		balloonContent: '123123'*/}
					{/*	}}*/}
					{/*	// onMouseEnter={() => setOnHoverId(1)}*/}
					{/*	// onMouseLeave={() => setOnHoverId(0)}*/}
					{/*	// modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}*/}
					{/*/>*/}

					<TrafficControl options={{ float: 'right' }} />
					{/* ZoomControl 12/09/22 */}
					<ZoomControl />
				</Map>
			</YMaps>
		</div>
	)

};

export default MapContent;
