import React from 'react';
import {Switch, Route} from "react-router-dom";
import Page404 from "components/Page404";
import OrdersListContainer from "containers/orders/OrdersListContainer";
import OrdersEditContainer from "containers/orders/OrdersEditContainer";

const OrdersRouter = props => {

	return (
		<Switch>

			<Route path="/" exact key={"orders-all"}>
				<OrdersListContainer {...props} />
			</Route>
			<Route path="/orders/edit/:id" exact key={"orders-edit"}>
				<OrdersEditContainer {...props} />
			</Route>
			{/*<Route path="/user/profile_edit" exact>*/}
			{/*	<UserEditContainer {...props} editSelf={true} />*/}
			{/*</Route>*/}
			{/*<Route path="/user/profile/:id" exact>*/}
			{/*	<UserEditContainer {...props} edit={false} />*/}
			{/*</Route>*/}

			<Route component={Page404} />
		</Switch>
	)

};

export default OrdersRouter;