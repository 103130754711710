import React, {useState} from 'react';
import {apiSend} from "utils/func";
import {format_phone} from "utils/func";
import {FormItem} from "components/_common";
import {addressString, clearPhone} from "components/orders/utils";
import sum from "hash-sum";

const API_URL = 'orders.php';
const PHONE_LENGTH = 11;

const OrderPhone = props => {
	const {user,
		//settings,
		isEdit,
		//isNew,
		data, setData,
		formErrors, setFormErrors,
		isCourier,
		setAddressChanged
	} = props;

	const [addressList, setAddressList] = useState({
		prev: [],
		cur: []
	});


	const searchAddressByPhone = (e, phone) => {
		if (isCourier)
			return;
		console.clear();
		let tel = clearPhone(phone);
		// console.log('searchAddressByPhone', phone, tel);
		if (tel.length == PHONE_LENGTH)
			apiSend(
				API_URL,
				{
					action: 'orderSearchAddress',
					userId: user.id,
					phone: tel
				},
				(res) => {
					// console.dir(res);
					setAddressList({
						prev: res.addressList,
						cur: res.addressList
					});
				}
			);
	}

	const onAddressSelect = adr => {
		// console.log('on sel', adr);

		setData(prev => ({
			...prev,
			address: {
				// ...prev.address,
				city: adr.city ? adr.city : '123',
				street: adr.street ? adr.street : '',
				house: adr.house ? adr.house : '',
				podezd: adr.podezd ? adr.podezd : '',
				floor: adr.floor ? adr.floor : '',
				flat: adr.flat ? adr.flat : '',
				domofon: adr.domofon ? adr.domofon : ''
			},
			changed: true
		}));
		setAddressChanged(true);

	};

	return (
		<div
			className={"order-phone-wrp"}
			onClick={() => {
				// console.log('click on inpit', data.phone);
				if (data.phone) {
					let tel = clearPhone(data.phone);
					if (tel.length == PHONE_LENGTH) {
						if (addressList.prev.length)
							setAddressList({
								prev: addressList.prev,
								cur: addressList.prev
							});
					}
				}
			}}
		>
			<FormItem
				edit={isEdit}
				name={"phone"}
				label={"Телефон конечного клиента"}
				ico={"mobile"}
				isRequire={true}
				maxLength={100}
				defVal={data.phone ? data.phone : ''}
				blurAction={(e, val) => {

					setData(prev => ({
						...prev,
						phone: val,
						changed: true
					}));

					setTimeout(() => {
						setAddressList(prev => ({
							...prev,
							cur: []
						}));
					}, 250);
				}}
				changeAction={searchAddressByPhone}
				errorMsg={ (formErrors && formErrors.phone) ? formErrors.phone : null }
				setErrorMsg={setFormErrors}

				mask={isEdit ? "+7 (999) 999-99-99" : null}


				fieldType={
					isCourier || !isEdit
						? <div className="flx -alc" style={{height: '2.25rem'}}>
							<a href={"tel:+" + data.phone}>
								{format_phone(data.phone)}
							</a>
						</div>
						: 'text'
				}



			/>
			{
				addressList.cur.length
					? <OrderPhoneAddressTip
						addressList={addressList.cur}
						onAddressSelect={onAddressSelect}
					/>
					: null
			}


		</div>
	)

};

const hashAddress = adr => {
	return sum(adr.trim().toLowerCase());
}
const OrderPhoneAddressTip = ({addressList, onAddressSelect}) => {

	let showed = [];

	let listView = addressList.map((adr, i) => {
		let itm = addressString(adr);
		let hash = hashAddress(itm);
		if (!showed.includes(hash)) {
			showed.push(hash);
			return (
				<div
					key={"opal-" + i}
					onClick={() => {
						onAddressSelect(adr)
					}}
				>
					{itm}
				</div>
			);
		}
	})

	return (
		<div className="order-phone-address-list">
			{
				listView
			}
		</div>
	);
}

export default OrderPhone;
