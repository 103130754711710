import React from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import {ContentHeader, Win, Button, FormItem} from "components/_common";
import Api, {apiAction} from "utils/Api";
import {toastr} from "react-redux-toastr";

const ForgetPassword = props => {

	const {actions} = props;

	let history = useHistory();
	// console.dir(history);

	const passwordRemind = async (email) => {
		if (email === '') {
			toastr.error('Ошибка', 'Введите Email');
			return false;
		}

		actions.showLoading();
		const res = await Api.post('users.php',  {
			action: 'passwordRemind',
			email: email
		});

		apiAction(res);
		actions.hideLoading();
	};

	return (
		<div className="win-wide">
			<Win
				header={"Восстановление пароля"}
				winClass={"login-win"}
				onClose={() => history.push('/')}
			>
				<form onSubmit={(e) => {
					e.preventDefault();
					passwordRemind(e.target.email.value).then(r=>{})
				}}>
					<div className="form-item">
						<label>Введите ваш E-mail*</label>
						<input
							type="email"
							name={"email"}
							autoComplete={"email"}
							defaultValue={""}
						/>
					</div>
					<div className="form-item _tac">
						<button className="btn">Отправить</button>
					</div>
				</form>
			</Win>
		</div>
	)

};
const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);